import { DialogService } from '../shared/dialog.service';
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(private authService: AuthService,
      private dialogService: DialogService, private zone: NgZone) { }

    handleError(error: any) {
        if(!error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
            console.log(error);
        }

        if (error instanceof HttpErrorResponse) {
            console.error('Backend Status code: ', error.status);
            console.error('Response body:', error.message);
            this.zone.run(() => {
                this.showError(error.error.message)
            })
            if(error.status == 401){
              this.authService.clearSession();
            }
        } else {
            if(!error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
                console.error('An error occurred:', error.message);
            }
        }
    }

    showError(error) {
        //this.dialogService.showWarnSnack('error.' + error);
    }
}
