import { CustomHeaderComponent } from './../../../../components/custom-header/custom-header.component';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { COMPANY_API, DEPARTMENT_API } from 'src/app/api-urls';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Position } from 'src/app/models/position';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import {  cpfValidator, customEmailValidator } from 'src/app/shared/input.validators';
import { PositionService } from 'src/app/shared/position.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import {ResourceRegisterHelpService} from '../../../../shared/resource-register-help.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
export class GeneralInfoComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private resourceService: ResourceApiService,
    private positionService: PositionService,
    private apiService: TalentApiService<any>,
    private dialogService: DialogService,
    private resourceRegisterHelpService: ResourceRegisterHelpService,
    private router: Router) {
     }


  editMode = false;
  formGroup = this.fb.group({
    name: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
    document: ['',  Validators.compose([cpfValidator,Validators.required])],
    registry: ['', Validators.required],
    admissionDate: ['', Validators.required],
    resignationDate: [],
    email: ['@kuehne-nagel.com',customEmailValidator],
    phone: [''],
    positionId: ['', Validators.required],
    modality: ['', Validators.required],
    companyUnitId: ['', Validators.required],
    departmentId: ['', Validators.required],
  });

  companyUnits: any[] = [];
  filteredCompanyUnit: CompanyUnit[] = [];
  companyUnit: FormControl = new FormControl(null);

	departments: any[] = [];

	positionSites: any[] = [];
  position: FormControl = new FormControl(null);
  filteredPositions: Position[] = [];
  positionIdActual: any;
  positionHasChange: boolean = false;

  admissionDate: FormControl = new FormControl(null, Validators.required);
  resignationDate: FormControl = new FormControl('');
  id = 0;

  @ViewChild(MatAutocompleteTrigger) trigger;

  customHeader = CustomHeaderComponent;
  ngOnDestroy() {
    this.resourceRegisterHelpService.disable();
  }

  ngOnInit() {
    this.resourceRegisterHelpService.enable('GENERAL_INFO');
    this.formGroup.controls.resignationDate.clearValidators();
    this.route.parent.params.subscribe( params => {
      this.id = params.id;
      if (this.id != 0) {
        this.editMode = true;
        this.formGroup.controls.email.setValue('')
      }
      combineLatest(
        this.formGroup.get('companyUnitId').valueChanges,
        this.formGroup.get('departmentId').valueChanges
      ).subscribe(([companyUnitId, departmentId]) => {
        this.formGroup.get('positionId').reset();
        this.position.reset();
        this.positionService.findAllByCompanyUnitAndDepartmentId(companyUnitId, departmentId).subscribe( positions => {
          this.positionSites = positions;
          this.filteredPositions = this.positionSites;
        });
      });
      if (this.editMode) {
        this.resourceService.findResourceById(this.id).subscribe(resource => {
          forkJoin(
            this.apiService.listAllObjects(DEPARTMENT_API),
            this.apiService.listAllObjects(COMPANY_API),
            this.positionService.findAllByCompanyUnitAndDepartmentId(resource.companyUnitId, resource.departmentId)
            ).subscribe(([departments, companyUnits, positions]) => {
              this.departments = departments;
              this.companyUnits = companyUnits;
              this.filteredCompanyUnit = this.companyUnits;
              this.positionSites = positions;
              this.filteredPositions = this.positionSites;
              this.updateForm(resource);

              const companyUnit = this.companyUnits.find(companyUnit => companyUnit.id == resource.companyUnitId);
              console.log(companyUnits);
              const position = this.positionSites.find(position => position.id == resource.positionId);
              this.companyUnit.setValue(companyUnit);
              this.position.setValue(position);
              this.positionIdActual = position.id;
            });

        });

      } else {
        forkJoin(
          this.apiService.listAllObjects(DEPARTMENT_API),
          this.apiService.listAllObjects(COMPANY_API)).subscribe( ([departments, companyUnits]) => {
            this.departments = departments;
            this.companyUnits = companyUnits;
            this.filteredCompanyUnit = this.companyUnits;
          });
      }
    });


    this.companyUnit.valueChanges.subscribe( value => {
      if (value && value.id == undefined) {
        this.filteredCompanyUnit = this.filterCompanyUnit(value);
      } else if (value.id != undefined) {
        this.formGroup.get('companyUnitId').setValue(value.id);
      }
    });

    this.position.valueChanges.subscribe(value => {
      if (value && value.id == undefined) {
        this.filteredPositions = this.filterPosition(value);
      } else if (value && value.id != undefined) {
        this.formGroup.get('positionId').setValue(value.id);
        if(value.id != this.positionIdActual && !!this.positionIdActual){
          this.positionHasChange = true;
        }
      }
    });

    this.admissionDate.valueChanges.subscribe(value => {
      if (typeof(value) != 'string') {
        const date = this.setHours(value);
        this.formGroup.controls.admissionDate.setValue(date);
      }
    });

    this.resignationDate.valueChanges.subscribe({next: value => {
        if (typeof(value) != 'string') {
          const date = this.setHours(value);
          this.formGroup.controls.resignationDate.setValue(date);
        }
      }});

    this.formGroup.get('modality').valueChanges.subscribe(value => {
      if (value == 'Terceiro') {
        if(!this.editMode){
          this.resourceService.getRegistry().subscribe( response => {
            this.formGroup.controls.registry.setValue(response);
          });
        }
        this.formGroup.controls.document.clearValidators();
      } else {
        this.formGroup.controls.registry.setValue('');
        this.formGroup.controls.document.setValidators(cpfValidator);
      }
      this.formGroup.controls.document.updateValueAndValidity();
    });
  }

  createResource() {
    this.resourceService.createResource(this.formGroup.value).subscribe(response => {
      this.dialogService.openInformationDialog('message.savedResource',this.formGroup.get('name').value);
      this.router.navigateByUrl(`/main/registration/recurso/create/${response}/general-info`);
    }, error => {
      const errorJSON = error.error;
      JSON.parse(errorJSON).message === 'email' ?
        this.dialogService.openAlertDialog('error.wrongEmail')
      :
      this.dialogService.openAlertDialog('error.registryAlreadyExist');
    });
  }

  updateResource() {
    const name = this.formGroup.get('name').value;
    this.resourceService.updateResource(this.id, this.formGroup.value).subscribe(response => {
      if(this.positionHasChange){
        this.dialogService.openInformationDialog('message.savedResource',name,'message.savedResourceComplementary');
      } else {
        this.dialogService.openInformationDialog('message.savedResource',name);
      }

    }, error => {
      if (error.error.message === 'email') {
        this.dialogService.openAlertDialog('error.wrongEmail');
      }
    });
  }

  getMask() {
		if (this.formGroup.get('phone').value != null && this.formGroup.get('phone').value.length > 10) {
			return '(00)00000-0000';
		}
		return '(00)0000-00009';
	}

  compareFun(f1, f2) {
		return f1 && f2 && f1 === f2;
  }

	compareByValue(f1: any, f2: any) {
		return f1 && f2 && f1.id === f2.id || f1 === f2;
	}

	compareById = (a, b) => a && b && a === b;

  updateForm(resource) {
    this.formGroup.patchValue(resource);
    this.formGroup.get('registry').setValue(resource.registry);
    const dateAdmission = this.setHours(resource.admissionDate);
    const dateResignation = this.setHours(resource.resignationDate);
    this.admissionDate.setValue(dateAdmission);
    resource.resignationDate != undefined ? this.resignationDate.setValue(dateResignation): null;
  }

	clickAuto() {
		this.trigger._onChange('');
		this.trigger.openPanel();
	}

  filterCompanyUnit(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
		  return this.companyUnits.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
	}

  filterPosition(value: string): Position[] {
    const filterValue = value ? value.toLowerCase() : '';
		  return this.positionSites.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
	}
  displayCompanyUnit(value: any) {
		return value ? value.name : '';
	}

  displayPosition(value: any) {
		return value ? value.name : '';
	}

  setHours(date: any) {
    if (date != null) {
      const dateChanged = new Date(date);
      dateChanged.setHours(dateChanged.getHours() + 3);
      return dateChanged;
    }
  }

  clear(){
    this.formGroup.reset();
    this.admissionDate.reset();
    this.resignationDate.reset();
  }
}
