import { BaseRegistrationComponent } from './../../../components/base-registration/base-registration.component';
import { ResourceDialogComponent } from './../../../dialogs/resource-dialog/resource-dialog.component';
import { DialogService } from './../../../shared/dialog.service';
import {Component, OnDestroy, ViewChild} from '@angular/core';
import { QR_CODE_RESOURCE_API, RESOURCE_API } from 'src/app/api-urls';
import { TalentApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TableAction } from 'src/app/components/table/table-action';
import { Resource } from 'src/app/models/resource';
import { MatDialog } from '@angular/material';
import { QrCode } from 'src/app/models/qr-code';
import jsPDF from 'jspdf';
import { ThrowStmt } from '@angular/compiler';
import { TableComponent } from 'src/app/components/table/table.component';
import { Router } from '@angular/router';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import {ResourceRegisterHelpService} from '../../../shared/resource-register-help.service';

@Component({
  selector: 'app-recurso',
  templateUrl: './recurso.component.html',
  styleUrls: ['./recurso.component.css']
})
export class RecursoComponent extends BaseRegistrationComponent<Resource> implements OnDestroy{

  columns: string[] = ['registry', 'resourceName', 'departmentName', 'companyUnitName', 'positionName', 'modality', 'actions'];
  resourceService;
  resourceActions:  TableAction[] = [
		{
			name: 'label.edit',
			icon: 'create',
      style: 'step1',
			color: '',
			do: (row) => {
				this.edit(row)
			}
		},
		{
			name: 'label.qrCodeGenerate',
			icon: 'qr_code_2',
      style: 'step2',
			color: '',
			do: (row) => {
				this.generateQrCode(row)
			}
		}
	];
  paths: string[] = ['title.mainRegistration','/','title.resource'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      style: 'generic-button',
      router: '..',
    },
    {
      label: 'label.add',
      style: 'generic-button step3',
      router: 'create/0/general-info'
    }
  ]

  constructor(
    dialogService: DialogService,
		apiService: TalentApiService<Resource>,
		authService: AuthService,
    private resourceRegisterHelpService: ResourceRegisterHelpService,
		router: Router
	) {
    super(dialogService, apiService,true);
    this.editComponent = ResourceDialogComponent;
    this.objectApi = RESOURCE_API;
    this.objectType = 'Resource';
	  this.user = authService.getUser();
	  this.resourceService = apiService;
    this.router = router;
  }

  ngOnDestroy() {
    this.resourceRegisterHelpService.disable();
  }

  ngOnInit() {
    super.ngOnInit();
    this.resourceRegisterHelpService.enable('TABLE');
  }

  edit(row) {
	  this.router.navigate(['main/registration/recurso/create',row.id,'general-info'])
  }

  generateQrCode({id}){
	this.resourceService.getObjectById(QR_CODE_RESOURCE_API,id).subscribe((value: QrCode) => {
		const doc = new jsPDF({unit: 'px'});

    	//A4 size  - 3508 x 2480

		doc.setFontSize(8);

		let image = new Image();
		image.src = 'data:image/png;base64,'+ value.base64QRCodeImage;
		doc.addImage(image,'png', 20, 20,75,75);
		doc.text(value.registry, 50, 85);
		doc.text(value.name.substr(0,14), 35, 95);

		doc.save(`qr-code-resource-${new Date().toLocaleDateString()}.pdf`);
	})
  }

}
