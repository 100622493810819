import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbAction} from 'src/app/components/breadcrumb/breadcrumb-action';
import {BreadcrumbComponent} from 'src/app/components/breadcrumb/breadcrumb.component';
import {TalentApiService} from 'src/app/shared/api.service';
import {DialogService} from 'src/app/shared/dialog.service';

import {GeneralInfoCourseComponent} from './general-info/general-info.component';
import {PositionCourseComponent} from './position/position.component';
import {SkillCourseComponent} from './skill/skill.component';
import {COURSE_API} from 'src/app/api-urls';
import {TrainingHelpService} from '../../../../shared/training-help.service';

@Component({
  selector: 'app-curso-form',
  templateUrl: './curso-form.component.html',
  styleUrls: ['./curso-form.component.css']
})
export class CursoFormComponent implements OnInit, OnDestroy {
  paths: string[] = ['title.mainRegistration', '/', 'title.course'];
  formGroup: FormGroup;
  selectedTab = new FormControl(0);
  lastTab = 0;
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.cancel',
      router: '/main/registration/curso',
      style: 'cancel-button'
    },
    {
      label: 'label.linkProceeding',
      style: 'training-button',
      disabled: true,
      do: (row) => this.next()
    }
  ];

  existingData: any;
  disableProceedingsTab = true;
  disablePositionsTab = true;
  isEdit = false;
  validGeneralInfo = false;
  validSkillInfo = false;
  selectedPositions: any;
  companyUnit: any = 0;
  companyUnitActual: any;
  department: any = 0;
  departmentActual: any;
  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent | undefined;
  @ViewChild(SkillCourseComponent) skillCourse: SkillCourseComponent;
  @ViewChild(PositionCourseComponent) positionCourse: PositionCourseComponent;
  @ViewChild(GeneralInfoCourseComponent) generalInfoCourse: GeneralInfoCourseComponent;
  changeCompanyOrDepartment = false;
  count = 0;

  generalInfoToEdit!: any;
  skillsToEdit!: any;
  positionsToEdit!: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private courseApiService: TalentApiService<any>,
              private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private trainingHelpService: TrainingHelpService
  ) {
    const courseId = this.activatedRoute.snapshot.params.id;
    if (courseId != undefined) {
      this.isEdit = true;
      this.courseApiService.getObjectById(COURSE_API, courseId).subscribe(response => {
        this.existingData = {
          course: response
        };
        if (this.existingData) {
          this.disablePositionsTab = false;
          this.disableProceedingsTab = false;
          this.fillComponents();
        }
      });
    }
  }

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('COURSE_REGISTER');
    this.formGroup = this.fb.group({
      id: null,
      reference: '',
      name: '',
      type: '',
      department: '',
      companyUnit: '',
      frequency: '',
      duration: '',
      active: '',
      description: '',
      // mandatory: this.mandatory,
      skills: [],
      coursePositions: []
    });

    this.selectedTab.valueChanges.subscribe(value => {
      this.changeTitleButton(value);
      if (this.isEdit) {
        this.edit(this.lastTab, false);
      }
      this.lastTab = value;
    });
  }

  fillGeneralInfo(value: any) {
    if (value != null) {
      this.formGroup.patchValue(value);
      this.departmentActual = this.formGroup.controls.department.value;
      this.companyUnitActual = this.formGroup.controls.companyUnit.value;
      this.sendCompanyAndDepartment();
      this.validGeneralInfo = true;
      this.changeDisableButton(false);
      if (this.isEdit) {
        this.changeButtonToEditMode();
        this.generalInfoToEdit = value;
      }
    } else {
      this.validGeneralInfo = false;
      this.changeDisableButton(true);
    }
  }

  fillSkillInfo(value: any) {
    if (value != null) {
      this.formGroup.controls.skills.patchValue(value.skills);
      this.changeDisableButton(false);
      this.validSkillInfo = true;
      if (this.isEdit) {
        this.changeButtonToEditMode();
        this.skillsToEdit = value;
      }
    } else {
      this.validSkillInfo = false;
      this.changeDisableButton(true);
    }
  }

  fillPositionInfo(value: any) {
    if (value != null) {
      this.formGroup.controls.coursePositions.patchValue(value.positions);
      if (this.isEdit) {
        this.changeButtonToEditMode();
        this.positionsToEdit = value;
      }
    } else {
      this.formGroup.controls.coursePositions.patchValue(null);
    }
    if (this.selectedTab.value == 2) {
      this.isEdit ? this.validSaveButtonToEditMode() : this.validSaveButton();
    }
  }

  next() {
    switch (this.selectedTab.value) {
      case 0:
        this.disableProceedingsTab = false;
        if (this.changeCompanyOrDepartment) {
          this.breadcrumb.changeButton(1, false, 'label.linkProceeding', 'training-button');
          this.selectedTab.setValue(1);
          break;
        }
        this.selectedTab.setValue(1);
        if (this.isEdit) {
          this.edit(this.selectedTab.value, false);
        }
        break;
      case 1:
        this.disablePositionsTab = false;
        if (this.changeCompanyOrDepartment) {
          this.selectedTab.setValue(2);
          break;
        }
        this.selectedTab.setValue(2);
        if (this.isEdit) {
          this.edit(this.selectedTab.value, false);
        }
        break;
      case 2:
        this.save();
        break;
    }
  }

  changeTitleButton(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.isEdit ? this.changeButtonToEditMode() : this.changeButton('label.linkProceeding', 'training-button');
        if (!this.validGeneralInfo) {
          this.changeDisableButton(true);
        }
        break;
      case 1:
        this.isEdit ? this.changeButtonToEditMode() : this.changeButton('label.linkPosition', 'generic-button');
        if (!this.validSkillInfo) {
          this.changeDisableButton(true);
        }
        break;
      case 2:
        this.isEdit ? this.changeButtonToEditMode() : this.changeButton('label.save', 'generic-button');
        this.validSaveButton();
        break;
    }
  }

  changeButton(label: string, style: string) {
    this.breadcrumb.changeButton(1, false, label, style);
  }

  changeButtonToEditMode() {
    (this.changeCompanyOrDepartment && this.selectedTab.value == 0) ? this.breadcrumb.changeButton(1, false, 'label.linkProceeding', 'training-button') :
      (this.changeCompanyOrDepartment && this.selectedTab.value == 1) ? this.breadcrumb.changeButton(1, false, 'label.linkPosition', 'training-button') :
        this.breadcrumb.changeButton(1, false, 'label.update', 'generic-button');
    this.breadcrumb.changeButton(0, false, 'label.close', 'cancel-button');
  }

  changeDisableButton(disabled: boolean) {
    this.breadcrumb.changeDisabledButton({
      indice: 1,
      disable: disabled
    });
  }

  validSaveButton() {
    (this.validGeneralInfo && this.validSkillInfo && this.formGroup.controls.coursePositions.value != null) ?
      this.changeDisableButton(false) :
      this.changeDisableButton(true);
  }

  validSaveButtonToEditMode() {
    (this.validGeneralInfo && this.formGroup.controls.coursePositions.value != null) ?
      this.changeDisableButton(false) :
      this.changeDisableButton(true);
  }

  save() {
    if (!this.isEdit) {
      const course = this.formGroup.value;
      this.courseApiService.saveObject(COURSE_API, course).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedCourse', course.reference);
        this.router.navigate(['/main/registration/curso']);
      }, error => {
        const msg = error.error.message;
        if (msg == 'coursePosition') {
          this.dialogService.openAlertDialog('message.missingCoursePositions', '', 'message.informCoursePositions');
        } else if (msg == 'skill') {
          this.dialogService.openAlertDialog('message.missingSkills', '', 'message.informSkills');
        }
      });
    } else {
      this.edit(this.selectedTab.value, true);
    }
  }

  edit(tab: number, showDialog: boolean) {
    const courseId = this.activatedRoute.snapshot.params.id;
    switch (tab) {
      case 0:
        this.courseApiService.saveObject(COURSE_API + `/${courseId}/info`, this.generalInfoToEdit).subscribe({
          next: (response) => {
            if (showDialog) {
              this.dialogService.openInformationDialog('message.savedCourse', this.generalInfoToEdit.reference);
            }
          }, error: (err) => {
            if (err.status !== 304) {
              this.dialogService.openAlertDialog('message.generalInfoEditError', '');
            }
          }
        });
        break;
      case 1:
        const skillDto: { idCourse: number, idSkill: number[] } = {
          idCourse: courseId,
          idSkill: this.skillsToEdit.skills.map(skill => skill.id)
        };
        this.courseApiService.saveObject(COURSE_API + `/${courseId}/skills`, skillDto).subscribe({
          next: () => {
            if (showDialog) {
              this.dialogService.openInformationDialog('message.savedCourse', this.generalInfoToEdit.reference);
            }
          }, error: (err) => {
            if (err.status !== 304) {
              if (err.error.message === 'skill') {
                this.dialogService.openAlertDialog('message.missingSkills', '', 'message.informSkills');
              } else {
                this.dialogService.openAlertDialog('message.informSkills', '');
              }
            }
          }
        });
        break;
      case 2:

        if (this.generalInfoToEdit.type === 'Geral') {
          this.breadcrumb.changeButton(1, true, 'label.update', 'generic-button');
          break;
        }

        const positionSiteForms: {
          idPosition: number,
          idCompanyUnit: number,
          mandatory: boolean
        }[] = this.positionsToEdit.positions ? this.positionsToEdit.positions.map(p => {
          return {
            idPosition: p.position.position.position.id,
            idCompanyUnit: p.position.position.companyUnit.id,
            mandatory: p.mandatory
          };
        }) : [];
        const coursePositionDto: { idCourse: number, positionSiteForms: any[] } = {
          idCourse: courseId,
          positionSiteForms
        };
        this.courseApiService.saveObject(COURSE_API + `/${courseId}/positions`, coursePositionDto).subscribe({
          next: () => {
            if (showDialog) {
              this.dialogService.openInformationDialog('message.savedCourse', this.generalInfoToEdit.reference);
            }
          }, error: (err) => {
            if (err.status !== 304) {
              if (err.error.message === 'coursePosition') {
                this.dialogService.openAlertDialog('message.missingCoursePositions', '', 'message.informCoursePositions');
              } else {
                this.dialogService.openAlertDialog('message.informCoursePositions', '');
              }
            }
          }
        });
        break;
    }
  }

  fillComponents() {
    this.generalInfoCourse.fillForm(this.existingData);
    this.positionCourse.fillForm(this.existingData);
    this.skillCourse.fillForm(this.existingData);
  }

  sendCompanyAndDepartment() {
    if (
      this.companyUnit != this.companyUnitActual ||
      this.department != this.departmentActual
    ) {
      if (this.count > 0) {
        this.changeCompanyOrDepartment = true;
      }
      this.companyUnit = this.companyUnitActual;
      this.department = this.departmentActual;
      this.skillCourse.setCompanyUnit(this.companyUnit);
      this.positionCourse.loadingPositions(this.companyUnit, this.department);
      if (this.formGroup.controls.type.value == 'Geral') {
        this.changeCompanyOrDepartment = false;
      }
      this.count++;
    }
  }
}
