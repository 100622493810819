import { ChartDataSets, RadialChartOptions, ChartType, ChartOptions } from 'chart.js';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { resolveComponentResources } from '@angular/core/src/metadata/resource_loading';
import { DialogService } from 'src/app/shared/dialog.service';
import { Label } from 'ng2-charts';
import { FormControl } from '@angular/forms';
import { Resource } from 'src/app/models/resource';
import { PositionSite } from 'src/app/models/position-site';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';
// import * as ChartAnnotation from 'chartjs-plugin-annotation';
// import * as Chart from 'chart.js';

@Component({
  selector: 'compare-skills',
  templateUrl: './compare-skills.component.html',
  styleUrls: ['./compare-skills.component.css']
})
export class CompareSkillsComponent implements OnInit, OnChanges {

  @Input() resource: Resource;
  @Input() targetPosition: any;

  type: FormControl = new FormControl('all');
  skillsPosition;
  constructor(private dialogService: DialogService) { }

  ngOnChanges() {
    this.refreshData('all');
  }

  ngOnInit() {
    // let caPlugin = ChartAnnotation;
    // caPlugin["id"]="annotation";
    // Chart.pluginService.register(caPlugin);

    this.type.valueChanges.subscribe(value => {
      this.refreshData(value);
    });

    this.refreshData('all');
  }

  // getIcon(resource) {
  //   let icon = {
  //     'fa fa-frown': (resource.performance < 45),
  //     'fa fa-smile': (resource.performance > 85),
  //     'fa fa-meh': (resource.performance > 45 && resource.performance < 85),
  //   };
  //   return icon;
  // }

  getColor(resource) {
    if(resource.performance < 45) {
      return '#d9534f';
    } else if(resource.performance > 85) {
      return '#5cb85c';
    } else {
      return '#f0ad4e';
    }
  }

  refreshData(skillType: string) {
    if(this.resource) {
      this.skillsPosition = this.targetPosition.positionSite.skillCompanyUnits ? this.targetPosition.positionSite.skillCompanyUnits : [];
      let skillsResource = this.resource.skillProficiencies ? this.resource.skillProficiencies : [];
      if(skillType !== 'all') {
        skillsResource = skillsResource.filter(s => s.skill.type === skillType.toUpperCase());
        this.skillsPosition = this.skillsPosition.filter(s => s['skilltype'] === skillType.toUpperCase());
      }

      this.chartLabels = this.skillsPosition.map(s => s['skillAcronym']);

      let positionData: SkillCompanyUnit[] = this.skillsPosition;
      //let resourceData = Array(positionData.length).fill(0);
      let resourceSkills = skillsResource.map(skillProficiency => skillProficiency.proficiency && skillProficiency.skill ? {skillId: skillProficiency.skill.id, skillLevel: skillProficiency.proficiency.level} : {});
      /*positionData.forEach( (val, index) => {
        skillsResource.forEach(resVal => {
          if(resVal.skill.id === val.skill.id) {
            resourceData[index] = resVal.proficiency.level != 0 ? resVal.proficiency.level : 1;
          }
        });
      });*/

      this.chartData = [];

      this.chartData.push({
        data: positionData.map(positionSkill => {
          let matchedSkill = resourceSkills.find(resourceSkill => resourceSkill.skillId == positionSkill['skillId']);
          if(matchedSkill && matchedSkill != undefined) {
            return matchedSkill.skillLevel;
          } else {
            return 0;
          }
        }),
        label: 'Perfil Atual'
      });

      this.chartData.push({
        data: positionData.map(s => s.proficiency.level),
        label: 'Perfil Pretendido'
      });

      this.stackedChartLabels = ['Performance'];

      this.stackedChartData = [
        {
          data: [this.resource.performance],
          type: 'line',
          hoverBackgroundColor: 'black',
          backgroundColor: 'black',
          pointRadius: 5
        },
        {
          data: [45],
          hoverBackgroundColor: 'red',
          backgroundColor: 'red'
        },
        {
          data: [40],
          hoverBackgroundColor: 'yellow',
          backgroundColor: 'yellow'
        },
        {
          data: [15],
          hoverBackgroundColor: 'lime',
          backgroundColor: 'lime'
        }
      ];

      this.pointer = {
        position: (this.resource.performance - 0.75) + "%"
      };
    }
  }

  chartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: true,
    },
    tooltips: {
      mode: 'point',
      intersect: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = 'Nível ';
          if (label) {
              label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
          return label;
        }
      }
    },
    scale: {
      pointLabels: {
        fontSize: 16
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        max: 5
      }
    }
  };

  chartLabels: Label[] = [];
  chartData: ChartDataSets[] = [];
  chartType: ChartType = 'radar';

  stackedChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }],
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        max: 100
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    // plugins: {
    //   annotation: {
    //     annotations: [
    //       {
    //         type: 'line',
    //         mode:  'horizontal',
    //         scaleID: 'y-axis-0',
    //         value: 44,
    //         borderColor: 'black',
    //         borderWidth: 5,
    //         label: {
    //           backgroundColor: 'rgba(0,0,0,0.8)',
    //           fontFamily: "sans-serif",
    //           fontSize: 12,
    //           fontStyle: "bold",
    //           fontColor: "#fff",
    //           xPadding: 6,
    //           yPadding: 6,
    //           cornerRadius: 6,
    //           position: "center",
    //           xAdjust: 0,
    //           yAdjust: 0,
    //           enabled: false,
    //           content: "Test label"
    //         }
    //       }
    //     ]
      // }
    // }
  };

  stackedChartLabels: Label[] = [];
  stackedChartData: ChartDataSets[] = [];
  stackedChartType: ChartType = 'bar';

  bars: any[] = [{
    backgroundColor: "#d9534f",
    fill: "50%",
  }, {
    backgroundColor: "#f0ad4e",
    fill: "35%"
  }, {
    backgroundColor: "#5cb85c",
    fill: "15%"
  }];

  bottomLabels: any[] = [{
    text: "0",
    color: "grey",
    position: "0%"
  }, {
    text: "49",
    color: "grey",
    position: "50%"
  }, {
    text: "85",
    color: "grey",
    position: "85%"
  }, {
    text: "100",
    color: "grey",
    position: "100%"
  }];
  pointer: any;
}
