import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent  implements OnInit{
  subscription: Subscription;
  isCreated = false;

  selectedTab = new FormControl(0);
  paths: string[] = ['title.mainRegistration','/','title.resource'];
  @Input() resourceId: number | string;
  @Input() route: string;
  @Input() isUpdating = false;
  disabled:boolean = true;
  @Output() click = new EventEmitter<void>();
  abaSelected:FormControl= new FormControl(0);
  data: any;
  urls = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(params => {
      if(params['id'] !== '0'){
        this.isCreated = true;
        this.changetoUpdate(params['id']);
      } else {
      }
    })
  }
  ngOnInit(): void {
    this.selectedTab.valueChanges.subscribe(value => {
      this.next(value);
    });
  }



  next(selectedAba: number){
    this.router.navigateByUrl(this.urls[selectedAba]);
  }

  setGeneralInfoAba(){
    if(this.router.url.includes('general-info')){
      this.abaSelected.patchValue(0);
    }
    console.log(this.abaSelected);
  }

  setPositionsAba(){
    if(this.router.url.includes('position')){
      this.abaSelected.patchValue(1);
    }
    console.log(this.abaSelected);
  }

  setCompetenceAba(){
    if (this.router.url.includes('competence')) {
      this.abaSelected.patchValue(2);
    }
    console.log(this.abaSelected);
  }

  setGradeAba(){
    if (this.router.url.includes('proficiencies')){
      this.abaSelected.patchValue(3);
    }
    console.log(this.abaSelected);
  }

  changetoUpdate(idResource:any){
    this.isUpdating = true;
    this.resourceId = idResource;
    this.disabled = false;
    this.urls = [
      '/main/registration/recurso/create/'+this.resourceId+'/general-info',
      '/main/registration/recurso/create/'+this.resourceId+'/position-history',
      '/main/registration/recurso/create/'+this.resourceId+'/competence',
      '/main/registration/recurso/create/'+this.resourceId+'/proficiencies',
    ];
  }
}
