import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    LOGIN = '[AUTH] Login',
    LOGIN_SUCCESS = '[AUTH] Login Success',
    LOGIN_FAILURE = '[AUTH] Login Failure',
    LOGOUT = '[AUTH] Logout'
}

export class LogIn implements Action {
    readonly type = AuthActionTypes.LOGIN;
    constructor(public payload: any) {}
}

export class LogInSuccess implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public payload: any) {}
}

export class LogInFailure implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILURE;
    constructor(public payload: any) {}
}

export class LogOut implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export type All =
     LogIn
    | LogInSuccess
    | LogInFailure
    | LogOut;