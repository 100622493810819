export class ResourcePresence {
  constructor(
    public date: string,
    public registry: string,
    public resourceName: string,
    public positionId: number,
    public positionName: string,
    public present: string,
    public id?: number,
    public canChangePresence?: boolean
  ) {
  }
}
