import { environment } from '../../src/environments/environment';

export const BASE_URL = environment.apiUrl + 'api/';
export const DEPARTMENT_API = BASE_URL + 'departments';
export const RESOURCE_API = BASE_URL + 'resources';
export const USER_REGISTER_API = BASE_URL + 'users';
export const QR_CODE_API = BASE_URL + 'qr-code';
export const PRINT_QR_CODE = BASE_URL + 'qr-code/print'
export const QR_CODE_RESOURCE_API = BASE_URL + 'qr-code/resource'
export const COMPANY_API = BASE_URL + 'companyUnits';
export const POSITION_API = BASE_URL + 'positions';
export const POSITION_BY_COMPANY_API = BASE_URL + 'positions/companyUnit';
export const PROFICIENCY_API = BASE_URL + 'proficiencies';
export const TRAINING_API = BASE_URL + 'trainings';
export const PRESENCE_API = BASE_URL + 'presences';
export const SKILL_API = BASE_URL + 'skills';
export const COURSE_API = BASE_URL + 'courses';
export const COURSE_BY_COMPANY_API = BASE_URL + 'courses/companyUnit';
export const POSITION_SITE_API = BASE_URL + 'positionSites';
export const POSITION_SKILL_API = BASE_URL + 'positionSkillProficiencies';
export const POSITION_SKILL_SITE_API = BASE_URL + 'positionSkillSites';
export const RESOURCE_SKILL_API = BASE_URL + 'resourceSkillProficiencies';
export const POSITION_HIST_API = BASE_URL + 'positionHistories';
export const SKILL_COMPANY_UNIT_API = BASE_URL + 'skillCompanyPositions';
export const PRINT_PRESENCE_LIST = BASE_URL + 'reports/listaPresenca/';
export const BASE_ATTACHMENTS = BASE_URL + 'attachments/';
export const ATTACHMENTS_API = BASE_URL + 'attachments';
export const UPLOAD_FILE = BASE_ATTACHMENTS + 'upload';
export const DOWNLOAD_FILE = BASE_ATTACHMENTS + 'download/';
export const DELETE_FILE = BASE_ATTACHMENTS + 'delete/';

export const BASE_EXCEL_URL = BASE_URL + 'reports/excel/';
export const PRINT_SKILL_MATRIX = BASE_EXCEL_URL + 'skillMatrix';
export const PRINT_FULFILLED_COURSE_MATRIX = BASE_EXCEL_URL + 'fulfilledCourseMatrix';
export const PRINT_COURSE_MASTER_LIST = BASE_EXCEL_URL + 'courseList';
export const PRINT_PENDING_COURSE_LIST = BASE_EXCEL_URL + 'pendingCourseList';
export const PRINT_EXPIRED_COURSE_LIST = BASE_EXCEL_URL + 'expiredCourseList';
export const PRINT_COMPLETED_COURSE_LIST = BASE_EXCEL_URL + 'completedCourseList';
export const PRINT_TRAINING_PLAN = BASE_EXCEL_URL + 'trainingPlan';
export const PRINT_COURSE_MATRIX = BASE_EXCEL_URL + 'courseMatrix';
export const PRINT_TRAINING_MATRIX = BASE_EXCEL_URL + 'trainingMatrix';

export const BASE_REPORT_URL = BASE_URL + 'reports/';
export const SKILL_MATRIX_REPORT = BASE_REPORT_URL + 'skillMatrix';
export const COURSE_LIST_REPORT = BASE_REPORT_URL + 'courseList';
export const PENDING_COURSE_REPORT = BASE_REPORT_URL + 'pendingCourseList';
export const EXPIRED_COURSE_REPORT = BASE_REPORT_URL + 'expiredCourseList';
export const COMPLETED_COURSE_REPORT = BASE_REPORT_URL + 'completedCourseList';
export const COURSE_MATRIX_REPORT = BASE_REPORT_URL + 'courseMatrix';
export const TRAINING_MATRIX_REPORT = BASE_REPORT_URL + 'trainingMatrix';
export const FULFILLED_COURSE_MATRIX_REPORT = BASE_REPORT_URL + 'fulfilledCourseMatrix';
export const TRAINING_PLAN_REPORT = BASE_REPORT_URL + 'trainingPlan';
export const GENERAL_RADAR_REPORT = BASE_REPORT_URL + 'resourcesGeneralRadar';
export const ACCESS_HISTORY_REPORT = BASE_REPORT_URL + 'accessHistory';
export const TRAINING_DASHBOARD_REPORT = BASE_REPORT_URL + 'trainingDashboard';
