import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { PRESENCE_API, PRINT_PRESENCE_LIST, TRAINING_API } from '../api-urls';

@Injectable({
  providedIn: 'root'
})
export class TrainingPresenceService {

  triggerReload = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) { }

  triggerEnoughPresencesVerify(){
    this.triggerReload.next(true);
  }

  get reloadObservable (){
    return this.triggerReload.asObservable();
  }

  getTrainingByToken(token: string){
    return this.http.get(PRESENCE_API + `/training-token/${token}`)
  }

  getMaxDaysOfTraining(trainingId){
    return this.http.get<number>(PRESENCE_API + `/max-days/${trainingId}`)
  }

  getPresencesByDay(trainingId: string,nthDay: string){
    return this.http.get(PRESENCE_API + `/${trainingId}/nth-day/${nthDay}?async=true`);
  }

  getPresencesByDate(trainingId: string, date: any){
    const params = new HttpParams()
    .append('date', date);
    return this.http.get(PRESENCE_API + `/${trainingId}/date`, {params});
  }

  getPresencesByDayAndToken(token: string,nthDay: string){
    return this.http.get(PRESENCE_API + `/token/${token}/nth-day/${nthDay}?async=true`);
  }

  countDays(trainingId: string){
    return this.http.get(PRESENCE_API + `/${trainingId}/count-days?async=true`);
  }

  getTrainingDates(trainingId: string){
    return this.http.get(PRESENCE_API + `/${trainingId}/all-dates?async=true`);
  }

  createTrainingDays(quantity: number,trainingId: string){
    return this.http.post(TRAINING_API + `/${trainingId}/days-quantity/${quantity}?async=true`,{});
  }

  markPresence(resourceId,trainingId,daySelected){
    this.triggerEnoughPresencesVerify();
    return this.http.post(PRESENCE_API + `/resource/${resourceId}/training/${trainingId}/order/${daySelected}?async=true`,{});
  }

  markAbsence(resourceId,trainingId,daySelected){
    this.triggerEnoughPresencesVerify();
    return this.http.delete(PRESENCE_API + `/resource/${resourceId}/training/${trainingId}/order/${daySelected}?async=true`);
  }

  changePresence(resourceId,trainingId, date, isPresent){
    const params = new HttpParams()
    .append('date', date)
    .append('isPresent', isPresent)
    this.triggerEnoughPresencesVerify();
    return this.http.put(PRESENCE_API + `/resource/${resourceId}/training/${trainingId}/date?async=true`,null,{params});
  }

  markPresenceForAll(trainingId, date){
    const params = new HttpParams()
      .append('date', date)
      .append('async', 'true');

    return this.http.put(PRESENCE_API + `/training/${trainingId}/markPresenceForAll`, null, {params})
  }

  haveEnoughPresences(resourceId, trainingId){
    return this.http.get(TRAINING_API + `/resource/${resourceId}/training/${trainingId}/haveEnoughPresences?async=true`);
  }

  sendAccessToTrainingInstructor(trainingId){
    return this.http.post(TRAINING_API + `/send-access/${trainingId}/${this.translateService.currentLang}`,{});
  }

  findNameByRegistryAndCPF(cpf: string, registry: string){
    return this.http.get(PRESENCE_API + `/resource/cpf/${cpf}/registry/${registry}`)
  }

  printPresenceList(trainingId:any, data:any){
    let url = PRINT_PRESENCE_LIST + trainingId + '/' + data;
    return this.http.get<any>(url,{responseType: 'blob' as 'json'});
  }
}
