import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-presence-confirm',
  templateUrl: './presence-confirm.component.html',
  styleUrls: ['./presence-confirm.component.css']
})
export class PresenceConfirmComponent implements OnInit {

  name;
  token;
  day;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.name = this.route.snapshot.queryParamMap.get('name');
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.day = this.route.snapshot.queryParamMap.get('day');
  }

}
