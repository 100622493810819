import { Component } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { SKILL_API } from './../../api-urls';
import { BaseRegistrationComponent } from './../../components/base-registration/base-registration.component';
import { DialogService } from './../../shared/dialog.service';
import { SkillDialogComponent } from './../../dialogs/skill-dialog/skill-dialog.component';
import { Skill } from '../../models/skill';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css']
})
export class SkillComponent extends BaseRegistrationComponent<Skill> {

  columns: string[] = ['type', 'skillName', 'acronym', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Skill>) {
    super(dialogService, apiService);
    this.editComponent = SkillDialogComponent;
    this.objectApi = SKILL_API/* + "?page=0&size=5"*/;
    this.objectType = 'Skill';
  }

}
