import { Component } from '@angular/core';
import { SKILL_API } from '../../../api-urls';
import { TalentApiService } from '../../../shared/api.service';
import { DialogService } from '../../../shared/dialog.service';
import { SkillDialogComponent } from '../../../dialogs/skill-dialog/skill-dialog.component';
import { BaseRegistrationComponent } from '../../../components/base-registration/base-registration.component';
import { AuthService } from '../../../shared/auth.service';
import { Skill } from '../../../models/skill';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-procedimento',
  templateUrl: './procedimento.component.html',
  styleUrls: ['./procedimento.component.css']
})
export class ProcedimentoComponent extends BaseRegistrationComponent<Skill> {

  columns: string[] = [ 'acronym','skillName','fileDocument', 'actions'];
  analystBasedColumns: string[] = ['acronym','skillName','fileDocument'];
  paths: string[] = ['title.mainRegistration','/','title.proceeding'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]
  constructor(dialogService: DialogService, apiService: TalentApiService<Skill>, authService: AuthService) {
    super(dialogService, apiService,true);
    this.editComponent = null;
    this.objectApi = SKILL_API/* + "?page=0&size=5"*/;
    this.objectType = 'Skill';
    this.user = authService.getUser();
  }

}
