import { DialogService } from './../../shared/dialog.service';
import { Component, OnInit, TemplateRef, Input } from '@angular/core';

@Component({
  selector: 'base-view',
  templateUrl: './base-view.component.html',
  styleUrls: ['./base-view.component.css']
})
export class BaseViewComponent implements OnInit {

  @Input() noTitle: boolean = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() baseTemplate: TemplateRef<any>;
  @Input() helpTemplate: TemplateRef<any>;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
  }

  hideHelp() {
    return (this.helpTemplate === undefined);
  }

  openHelp() {
    this.dialogService.openCustomDialog(this.helpTemplate, false)
  }

}
