import { debounceTime, switchMap } from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { COMPANY_API, DEPARTMENT_API } from 'src/app/api-urls';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { CustomHeaderComponent } from 'src/app/components/custom-header/custom-header.component';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Course } from 'src/app/models/course';
import { Department } from 'src/app/models/department';
import { Resource } from 'src/app/models/resource';
import { TalentApiService } from 'src/app/shared/api.service';
import { CourseApiService } from 'src/app/shared/course-api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import { TrainingApiService } from 'src/app/shared/training-api.service';
import { statusTypes } from 'src/app/util-json.data';

import { NavigationHeaderComponent } from './../navigation-header/navigation-header.component';
import {TrainingHelpService} from '../../../shared/training-help.service';
import {driver} from 'driver.js';

@Component({
  selector: 'app-training-info',
  templateUrl: './training-info.component.html',
  styleUrls: ['./training-info.component.css']
})
export class TrainingInfoComponent implements OnInit, OnDestroy {

  companyUnits: CompanyUnit[] = [];
  resources: Resource[];
  trainingInfoForm: FormGroup;
  trainingInfoUpdate: FormGroup;
  courses: Course[];
  departments: any;
  minDate: Date;
  startDate: Date;
  endDate: Date;
  existing: any;
  types = [] = [{ name: 'Padrão'},{name: 'Reciclagem'}];
  status = statusTypes.filter(item => item.name !== 'Realizado');
  isUpdating = false;
  trainingId = 0;
  companyUnitId = 0;
  departmentId = 0;
  selectedCourse: FormControl = new FormControl(null, Validators.required);
  filteredCoursesOptions: Course[]=[];

  courseDescription = new FormControl('');
  skillsDescription = new FormControl('');
  instructor = new FormControl('');

  companyUnit: FormControl = new FormControl(null);
  selectedCompanyUnit: any;
  filteredCompanyUnit: CompanyUnit[] = [];

  loadingInstructors: boolean = false;

  instructorLocked = false;

  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.cancel',
      style: 'cancel-button',
      router: '/main/calendar'
    },
    {
			label: 'label.addResources',
      style: 'training-button',
      disabled: true,
			do: () => {
				this.saveAndNext();
      }
    }
  ]
  customHeader = CustomHeaderComponent;
  resourcesData:any;
  constructor(
    private fb: FormBuilder,
    private unitApi: TalentApiService<CompanyUnit>,
    private departmentApi: TalentApiService<Department>,
    private courseApi: CourseApiService,
    private resourceApi: ResourceApiService,
    private trainingApi: TrainingApiService,
    private dialogService: DialogService,
    private routeSnapshot: ActivatedRoute,
    private trainingHelpService: TrainingHelpService,
    private router: Router
  ) {
    this.trainingInfoForm = fb.group({
      companyUnitId: ['',Validators.required],
      departmentId: ['', Validators.required],
      selectedCourseId: ['', Validators.required],
      instructor: ['',Validators.required],
      type: ['',Validators.required],
      status:['',Validators.required],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required],
      expireDate: ['',Validators.required]
    })
    if(this.routeSnapshot.snapshot.params.id){
      this.trainingId = this.routeSnapshot.snapshot.params.id;
      this.isUpdating = true;
    }

    try{
      if(this.router.getCurrentNavigation().extras.state){
        const trainingInfo = this.router.getCurrentNavigation().extras.state.data.trainingInfo;
        const companyUnit = this.router.getCurrentNavigation().extras.state.data.companyUnit;
        const selectedCourse= this.router.getCurrentNavigation().extras.state.data.selectedCourse;
        const courseDescription = this.router.getCurrentNavigation().extras.state.data.courseDescription;
        const skillDescription = this.router.getCurrentNavigation().extras.state.data.skillsDescription;
        this.resourcesData = this.router.getCurrentNavigation().extras.state.data.resources;
        this.trainingInfoForm.patchValue(trainingInfo);
        this.companyUnit.patchValue(companyUnit);
        this.selectedCompanyUnit = companyUnit;
        this.selectedCourse.patchValue(selectedCourse);
        this.courseDescription.patchValue(courseDescription);
        this.skillsDescription.patchValue(skillDescription);
        this.companyUnitId = trainingInfo.companyUnitId;
        this.departmentId = trainingInfo.departmentId;
      }
    }catch{
    }
  }

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('TRAINING_INFO');
    this.courseDescription.disable();
    this.skillsDescription.disable();
    if(this.isUpdating){
      this.companyUnit.disable();
      this.selectedCourse.disable();
      this.trainingInfoForm.controls['departmentId'].disable();
    } else {
      this.trainingInfoForm.controls['status'].setValue("Programado")
      if (this.selectedCourse.value == null) this.selectedCourse.disable();
      if (this.trainingInfoForm.invalid) this.disableFormFields(true)
    }

    this.routeSnapshot.data.subscribe( (value: {instructor: Resource}) => {
      if(value.instructor){
        this.instructorLocked = true;
        this.trainingInfoForm.get('instructor').setValue(value.instructor);
      }
    });

    this.fillCourses();

    forkJoin(
      this.unitApi.listAllObjects(COMPANY_API),
      this.departmentApi.listAllObjects(DEPARTMENT_API)
    ).subscribe(([companyUnits, departments]) => {
      this.companyUnits = companyUnits;
      this.filteredCompanyUnit = this.companyUnits;
      this.departments = departments;
      this.verifyForm();
      this.trainingInfoForm.get('companyUnitId').valueChanges.subscribe(companyUnitId => {
        if(!this.isUpdating){
          this.companyUnitId = companyUnitId;
          this.trainingInfoForm.get('selectedCourseId').setValue('');
          this.selectedCourse.setValue('');
          this.selectedCourse.disable();
          if(this.departmentId == 0){
            this.dialogService.openAlertDialog('Insira o Departamento');
          } else {
            this.fillCourses();
          }
          }
      });

      this.trainingInfoForm.get('departmentId').valueChanges.subscribe(departmentId => {
        if(!this.isUpdating){
          if(departmentId != undefined ){
            this.departmentId = departmentId;
            this.trainingInfoForm.get('companyUnitId').setValue('');
            this.companyUnit.setValue('');
            this.companyUnitId = 0;
            this.trainingInfoForm.get('selectedCourseId').setValue('');
            this.selectedCourse.setValue('');
            this.courseDescription.setValue('');
            this.skillsDescription.setValue('');
          }
        }
      });


      this.companyUnit.valueChanges.subscribe( value => {
        if(value && value.id == undefined){
          this.filteredCompanyUnit = this.filterCompanyUnit(value);
        } else if(value && value.id != undefined){
          this.selectedCompanyUnit = value;
          this.trainingInfoForm.get('companyUnitId').setValue(value.id);
        }
      });

      this.selectedCourse.valueChanges.subscribe( course => {
        if(!this.isUpdating){
          if(course && course.id == undefined){
            this.trainingInfoForm.get('selectedCourseId').setValue('');
            this.courseDescription.setValue('');
            this.skillsDescription.setValue('');
            this.filteredCoursesOptions = this.filterCourse(course);
        } else if(course && course.id != undefined){
          this.generateCourseDescription(course);
        }
      }
      })

      if(this.trainingId){
        var selectedCourse = 0;
        this.trainingApi.getTrainingInfo(this.trainingId).subscribe((response: any) => {
          const isCancelled = response.status === 'Cancelado';

          if(response.status==='Realizado'){
            this.status = statusTypes;
          }

          selectedCourse = response.selectedCourseId;
          this.courseApi.getCoursesByUnitIdAndDepartment(response.companyUnitId,response.departmentId).subscribe(coursesResponse => {
            const status = response.status.toLowerCase();
            this.courses = coursesResponse;
            this.filteredCoursesOptions = this.courses;
            this.fillCourse(selectedCourse);
            if (status === "realizado" || status === "cancelado") this.disableFormFields(true)
            this.trainingInfoForm.patchValue({
              companyUnitId: response.companyUnitId || '',
              departmentId: response.departmentId || '',
              selectedCourseId:  selectedCourse || '' ,
              instructor: response.instructor || '',
              type: response.type || '',
              status: response.status || '',
              startDate: this.toUTC(response.startDate) || '',
              endDate: this.toUTC(response.endDate) || '',
              expireDate: this.toUTC(response.expireDate) || ''
              },{emitEvent: true});
            this.updateNavigateHeader(this.trainingId, isCancelled);
            this.changeButtonInformations(1, 'label.update', 'generic-button');
            this.changeButtonInformations(0, 'label.close', 'cancel-button');
              this.departmentId = response.departmentId;
              this.companyUnitId = response.companyUnitId;
              this.fillCompanyUnit(response.companyUnitId);
            this.startDate = this.toUTC(response.startDate);
            this.endDate = this.toUTC(response.endDate);
          });
        });
      }
      this.routeSnapshot.params.subscribe(params => {
        if(params.selectedDate){
          this.trainingInfoForm.get('startDate').setValue(new Date(params.selectedDate), {emitEvent:false});
        }
      });
    })

    this.trainingInfoForm.valueChanges.subscribe( value => {
      if(this.trainingInfoForm.valid && (value.instructor && value.instructor.id != undefined)){
        this.changeDisableButton(false);
      }else{
        this.changeDisableButton(true);
      }
    })

    this.trainingInfoForm.get('endDate').valueChanges.subscribe((endDate)=>{
      const courseId =  this.trainingInfoForm.get('selectedCourseId').value
      if (courseId != '' && endDate != null) {
        const course = this.courses.find(course => course.id === courseId);
        const expireDate = this.generateExpireDate(course,new Date(endDate));
        this.trainingInfoForm.get('expireDate').setValue(expireDate);
      } else if (courseId == '') {
        this.dialogService.openAlertDialog('Informe um curso válido.');
      } else if (endDate == null) {
        this.trainingInfoForm.get('expireDate').setValue('');
      } else {
        this.dialogService.openAlertDialog('Erro ao atribuir a data de vencimento.');
      }
    })

    this.trainingInfoForm.get('startDate').valueChanges.subscribe((startDate)=>{
      if (startDate == null) {
        this.trainingInfoForm.get('expireDate').setValue('');
        this.trainingInfoForm.get('endDate').setValue('');
      }
      this.minDate = startDate
    })
  }

  fillCourses() {
    if (this.departmentId != 0 && this.companyUnitId != 0) {
      this.courseApi.getCoursesByUnitIdAndDepartment(this.companyUnitId, this.departmentId).subscribe({
        next: (coursesResponse) => {
          if (coursesResponse.length !== 0) {
            this.filteredCoursesOptions = [];
            this.selectedCourse.enable();
            this.courses = coursesResponse;
            this.filteredCoursesOptions = this.courses;
          }
        }, error: () => {
          this.dialogService.openAlertDialog('Erro ao buscar os cursos');
        }
      });
    }
  }

  fillCourse(id:number){
    const course = this.courses.find(course => course.id === id);
    this.selectedCourse.setValue(course);
    this.generateCourseDescription(course);
  }

  fillCompanyUnit(id:number){
    const companyUnit = this.companyUnits.find(companyUnit => companyUnit.id === id);
    this.companyUnit.setValue(companyUnit);
  }

  generateExpireDate(course: Course,expireDate: Date) {
    switch(course.frequency){
      case 'Semestral':
        expireDate.setMonth(expireDate.getMonth() + 6);
        break;
      case 'Anual':
        expireDate.setMonth(expireDate.getMonth() + 12);
        break;
      case 'Bienal':
        expireDate.setMonth(expireDate.getMonth() + 24);
        break;
      case 'Trienal':
        expireDate.setMonth(expireDate.getMonth() + 36);
        break;
      case 'Único':
        expireDate.setFullYear(expireDate.getFullYear() + 999);
        break;
    }
    return expireDate;
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareById = (a,b) =>{
    return a && b && a === b;
  }

  getResourceName(resource){
    return resource.resourceName;
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  generateCourseDescription(selectedCourse) {
    this.trainingInfoForm.get("selectedCourseId").setValue(selectedCourse.id);
    this.courseApi
      .getCoursesByIdWithSkillsFetched(selectedCourse.id)
      .subscribe((coursesResponse) => {
        const course = coursesResponse;
        let skillsDescription: string = "";
        let courseDescription: string = "";
        if (course) {
          courseDescription = course.description;
          if (course.skills) {
            for (let skill of course.skills) {
              skillsDescription += `\u2022 ${skill.name}\n`;
            }
          }
        }
        this.courseDescription.setValue(courseDescription);
        this.skillsDescription.setValue(skillsDescription);
        if (!this.isUpdating) {
          this.disableFormFields(false);
          this.trainingInfoForm.controls['status'].disable();
        }
      });
  }

  handleResourceAutocomplete() {
    this.loadingInstructors = true;

    this.trainingInfoForm.controls["instructor"].setErrors(null);

    this.trainingInfoForm.controls["instructor"].valueChanges
      .pipe(
        debounceTime(400),
        switchMap((instructor) => {
          if (instructor && instructor.id == undefined) {
            this.trainingInfoForm.controls["instructor"].setErrors({
              notSelect: true,
            });
            return this.resourceApi.findInstructorsCandidatesBySimilarity(
              instructor
            );
          } else {
            return [];
          }
        })
      )
      .subscribe((response) => {
        if (response.length === 0) {
          this.trainingInfoForm.controls["instructor"].setErrors({
            notFound: true,
          });
        }
        this.resources = response;
        this.loadingInstructors = false;
      });
  }

  saveAndNext() {
    if (this.isUpdating) {
      if (this.trainingInfoForm.value.status === "Cancelado") {
        this.showConfirmDialog("message.cancelTrainingMessage", () => {
          this.updateTrainingInfo();
        });
      } else {
        const startDateChanged = this.startDate.toString() !== this.trainingInfoForm.value.startDate.toString();
        const endDateChanged = this.endDate.toString() !== this.trainingInfoForm.value.endDate.toString();

        if (startDateChanged || endDateChanged) {
          this.showConfirmDialog("message.changeInTrainingPeriod", () => {
            this.updateTrainingInfo();
          });
        } else {
          this.updateTrainingInfo();
        }
      }
    } else {
      this.disableFormFields(false);
      const navigationExtras: NavigationExtras = {
        state: {
          trainingInfo: this.trainingInfoForm.value,
          selectedCourse: this.selectedCourse.value,
          companyUnit: this.selectedCompanyUnit,
          courseDescription: this.courseDescription.value,
          skillsDescription: this.skillsDescription.value,
          resources: this.resourcesData,
        },
      };
      this.router.navigate(
        ["/main", "registration", "training-info", "add-resources"],
        navigationExtras
      );
    }
  }

  showConfirmDialog(messageKey: string, callback: () => void) {
    this.dialogService
      .openConfirmInnerHtmlDialog({ message: messageKey })
      .beforeClosed()
      .subscribe((persist) => {
        if (persist) {
          callback();
        }
      });
  }

  updateTrainingInfo() {
    const routeTrainingId = this.routeSnapshot.snapshot.params.id;
    this.fillUpdateForm();

    this.trainingApi
      .updateTrainingInfo(this.trainingInfoUpdate.value, routeTrainingId)
      .subscribe({
        next: (value) => {
          this.dialogService.openInformationDialog("message.savedTraining").afterClosed().subscribe(()=>{
            this.router.navigate(["/main", "calendar"]);
          });
        },
        error: (err) => {
          this.handleSaveError(err);
        },
      });
  }

  disableFormFields(disable: boolean) {
    const controls = [
      "instructor",
      "type",
      "status",
      "startDate",
      "endDate",
      "expireDate",
    ];
    controls.forEach((control) => {
      this.trainingInfoForm.controls[control][disable ? 'disable' : 'enable']();
    });
  }

  fillUpdateForm(){
    this.trainingInfoUpdate = this.fb.group({
      companyUnitId: ['',Validators.required],
      departmentId: ['', Validators.required],
      selectedCourseId: ['', Validators.required],
      instructor: ['',Validators.required],
      type: ['',Validators.required],
      status:['',Validators.required],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required],
      expireDate: ['',Validators.required]
    });
    this.trainingInfoUpdate.patchValue(this.trainingInfoForm.value);
    this.trainingInfoUpdate.get('departmentId').patchValue(this.departmentId);
  }

  @ViewChild(MatAutocompleteTrigger) trigger;

	clickAuto() {
		this.trigger._onChange('');
		this.trigger.openPanel();
	}

  filterCourse(value: string): Course[] {
    const filterValue = value ? value.toLowerCase() : '';
    const courses = this.courses.filter(course => `${course.reference} - ${course.name}`.toLowerCase().includes(filterValue));
    this.selectedCourse.setErrors(courses.length === 0 ? {notFound: true} : {notSelect: true})
    return courses;
	}

  filterCompanyUnit(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
		return this.companyUnits.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
	}

  displayCourse(course: any){
		return course? course.reference+' - '+course.name : '';
	}

  displayCompanyUnit(value: any){
		return value? value.name : '';
	}

  @ViewChild(NavigationHeaderComponent) navHeader: NavigationHeaderComponent
  updateNavigateHeader(idTraining, canceledDisabled) {
    this.navHeader.changetoUpdate(idTraining, canceledDisabled);
  }

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent
  changeDisableButton(disable: boolean){
    this.breadcrumb.changeDisabledButton({
      indice: 1,
      disable: disable
    });
  }
  changeButtonInformations(index: number, label: string, style: string) {
    this.breadcrumb.changeButton(index, false, label, style);
  }
  //Adiciona 3 horas pra corrigir conversão automática para GMT-3
  private toUTC(unfixedDate){
    const date = new Date(unfixedDate);
    date.setHours(date.getHours() + 3);
    return date;
  }

  verifyForm(){
    if(this.trainingInfoForm.valid){
      this.changeDisableButton(false);
    }
  }

  handleSaveError(error) {
    switch (error.error.message) {
      case 'dateRange':
        this.dialogService.openAlertDialog('error.dateRange');
        break;
      case 'expireDateRange':
        this.dialogService.openAlertDialog('error.expireDateRange');
        break;
    }
  }
}
