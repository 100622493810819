import { AuthService } from './../../../shared/auth.service';
import { HttpClient } from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { DOWNLOAD_FILE } from 'src/app/api-urls';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { ColumnType, TableAction } from 'src/app/components/table/table-action';
import { Attachment } from 'src/app/models/attachment';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { TrainingApiService } from 'src/app/shared/training-api.service';
import { NavigationHeaderComponent } from '../navigation-header/navigation-header.component';
import { User } from '../../../models/user';
import {TrainingHelpService} from '../../../shared/training-help.service';

@Component({
  selector: 'app-send-list',
  templateUrl: './send-list.component.html',
  styleUrls: ['./send-list.component.css']
})
export class SendListComponent implements OnInit, OnDestroy {
  pageSize = 5;
  pageIndex = 0;
  uploadForm: FormGroup;
  trainingId: number;
  presenceList = new FormControl(undefined, [Validators.required, FileValidator.maxContentSize(35913941)]);
  description = new FormControl('', [Validators.required]);
  filter = new FormControl('');
  documents: Attachment[] = [];
  originalDocuments: Attachment[] = [];
  user: User;

  @ViewChild('form') formViewChild: any;
  @ViewChild('fileInput') fileViewChild : any;

  displayedColumns: string[] = [];
  documentsColumns: ColumnType[] = [
    {name: 'label.uploadDate', property: 'uploadDate', isDate: true},
    {name: 'label.user', property: 'uploadUser'},
    {name: 'label.description', property: 'description'},
    {name: 'label.file', property: 'fileName'}
  ];
  documentTableAction: TableAction[] = [
    {
      name: 'label.download',
      icon: 'cloud_download',
      color: '',
      do: (row) => {
        this.downloadList(row)
      }
    },
    {
      name: 'label.delete',
      icon: 'delete',
      color: 'warn',
      style: 'second-step-help',
      do: (row) => {
        this.removeList(row)
      }
    }
  ];
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.close',
      style: 'cancel-button',
      router: '/main/calendar'
    }
  ]
  constructor(
    private authService: AuthService,
    private trainingApi: TrainingApiService,
    private dialogService: DialogService,
    private attachmentApiService: TalentApiService<Attachment>,
    private routeSnapshot: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private trainingHelpService: TrainingHelpService
  ) {
    }

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('SEND_LIST')
    this.uploadForm = this.formBuilder.group({
      presenceList: this.presenceList,
      description: this.description
    });
    this.trainingId = this.routeSnapshot.snapshot.params.id;

    this.trainingApi.getTrainingInfo(this.trainingId).subscribe((res) => {
      const isCancelled = res.status === 'Cancelado';
      this.updateNavigateHeader(this.trainingId, isCancelled);
    });

    this.user = this.authService.getUser();
    this.trainingApi.getAttachments(this.trainingId).subscribe(response => {
      this.originalDocuments = response;
      this.updateDocuments();
    });
    this.filter.valueChanges.subscribe({
      next: (filterValue: string) => {
        this.filterColumns(filterValue);
      }
    });
    this.pageChange({pageSize: this.pageSize, pageIndex: this.pageIndex});
    this.mapColumns(this.documentsColumns);
  }

  mapColumns(columns: any) {
    this.displayedColumns = columns.map((column: any) => column.property);
    this.displayedColumns.push('action');
  }

  uploadDocument() {
    const file = this.presenceList.value.files[0];
    const description = this.description.value;
    const data: FormData = new FormData();
    data.append('training', this.trainingId.toString());
    data.append('file', file, file.name);
    data.append('username', this.user.username);
    data.append('description', description);
    const ref = this.dialogService.showDialogLoader('label.sendUpload');

    this.attachmentApiService.uploadFile(data).subscribe(response => {
      this.originalDocuments.push(response);
      this.originalDocuments = [...this.originalDocuments];
      this.documents = this.originalDocuments;
      ref.afterClosed().subscribe(() => {
        this.dialogService.openInformationDialog('message.fileUploaded');
      });
      ref.close();
    });
    this.resetForm()
    this.presenceList.markAsUntouched();
    this.updateDocuments();
  }

  removeList(list) {
    this.dialogService.openConfirmDialog('message.removeFile', list.fileName)
      .afterClosed().subscribe(confirm => {
        if (confirm) {
          this.attachmentApiService.deleteFile(list).subscribe(() => {
            this.dialogService.openInformationDialog('message.removedFile');
            this.originalDocuments.splice(this.originalDocuments.map(l => l.id).indexOf(list.id), 1);
            this.originalDocuments = [...this.originalDocuments];
            this.documents = this.originalDocuments;
          });
        }
      });
    this.updateDocuments();
  }

  downloadList(list) {
    let url = DOWNLOAD_FILE + list.id + '/' + this.user.username;
    this.http.get<any>(url,{responseType: 'blob' as 'json'}).subscribe( data => {
      const blob = new Blob([data], {type: data.type})
      var downloadURL = window.URL.createObjectURL(data);
       var link = document.createElement('a');
      link.href = downloadURL;
      link.download = list.fileName;
      link.click();
    });
  }

  filterColumns(filterValue: string) {
    if (filterValue !== '') {
      this.documents = this.originalDocuments.filter(document =>
        document.uploadUser.includes(filterValue) ||
        document.description.includes(filterValue) ||
        document.fileName.includes(filterValue)
      );
    } else {
      this.pageChange({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }
  }

  pageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateDocuments();
  }

  updateDocuments() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.documents = this.originalDocuments.slice(startIndex, endIndex);
  }

  @ViewChild(NavigationHeaderComponent) navHeader: NavigationHeaderComponent;
  updateNavigateHeader(idTraining, canceledDisabled) {
    this.navHeader.changetoUpdate(idTraining, canceledDisabled);
  }

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent
  changeDisableButton(disable:boolean){
    this.breadcrumb.changeDisabledButton({
      indice: 1,
      disable: disable
    });
  }

  getTableCell(e: any, c: any) {
    return e[c.property]
  }

  resetForm() {
    this.uploadForm.reset();
    this.fileViewChild.clear();
  }
}
