import { BreadcrumbAction } from './../../../components/breadcrumb/breadcrumb-action';
import { Component, OnInit } from '@angular/core';
import { RESOURCE_API, USER_REGISTER_API } from 'src/app/api-urls';
import { BaseRegistrationComponent } from 'src/app/components/base-registration/base-registration.component';
import { TableAction } from 'src/app/components/table/table-action';
import { ResourceDialogComponent } from 'src/app/dialogs/resource-dialog/resource-dialog.component';
import { UserRegisterDialogComponent } from 'src/app/dialogs/user-register-dialog/user-register-dialog.component';
import { UserRegister } from 'src/app/models/user-register';
import { TalentApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { UsersService } from 'src/app/shared/users.service';
import { style } from '@angular/animations';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends BaseRegistrationComponent<UserRegister> {

  columns: string[] = ['registry','username','resource','email','translatedRole','actions'];
  paths: string[] = ['title.users'];
	resourceService;
  resourceActions:  TableAction[] = [
		{
			name: 'label.edit',
			icon: 'create',
			color: '',
			do: (row) => {
				this.edit(row)
			}
		},
		{
			name: 'label.firstAccessEmail',
			icon: 'email',
			color: '',
			do: (row) => {
				this.resendFirstAccesEmail(row);
			}
		},
		{
			name: 'label.delete',
			icon: 'delete',
			color: 'warn',
			do: (row) => {
				this.delete(row)
			}
		},
	];

  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
    {
      label: 'label.add',
      style: 'generic-button',
      do: (row: any) => {
        this.create();
      }
    }
  ];

  constructor(
	  	dialogService: DialogService,
		apiService: TalentApiService<UserRegister>,
		private authService: AuthService,
		private userService: UsersService
	) {
    super(dialogService, apiService);
    this.editComponent = UserRegisterDialogComponent;
	const lang = localStorage.getItem('lang');
    this.objectApi = USER_REGISTER_API;
    this.objectType = 'UserRegister';
	this.user = authService.getUser();
	this.resourceService = apiService;
  }

  	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.userService.getAllUsers().subscribe( response => {
			this.data = response;
		})
	}

  resendFirstAccesEmail(row){
	const lang = localStorage.getItem('lang');
	this.authService.resendFirstAccessEmail(row.email,lang).subscribe(success =>{
		this.dialogService.openInformationDialog('message.userFirstAccessResendMail');
	},error => {
		if(error.status === 428){
			this.dialogService.openAlertDialog('error.firstAccessAlreadyBeenDone');
		}
	});
  }

}
