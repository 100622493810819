import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user: User;

  constructor(private authService: AuthService) {
    this.user = authService.getUser();
   }

  ngOnInit() {
  }

  @Input() isExpanded = false;

  element: HTMLElement;
  backgroundDefault = 'transparent';
  backgroundActive = 'white';

  toggleActive(event:any){
    event.preventDefault();

    if(this.element !== undefined){
      this.element.style.backgroundColor = this.backgroundDefault;
      this.element.style.color = 'rgb(220, 220, 220)';
    }

    var target = event.currentTarget;
    target.style.backgroundColor = this.backgroundActive;
    target.style.color = '#47739c';
    this.element = target;
  }

}
