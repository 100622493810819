import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableAction } from 'src/app/components/table/table-action';
import { ResourcePresence } from 'src/app/models/resource-presence';
import { Training } from 'src/app/models/training';
import { DialogService } from 'src/app/shared/dialog.service';
import { TrainingPresenceService } from 'src/app/shared/training-presence.service';
import { trainingPresence } from 'src/app/util-json.data';
import { environment } from 'src/environments/environment';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.css']
})
export class AttendanceListComponent implements OnInit {

  trainingPresence = trainingPresence;

  hasTrainingDays = false;

  dateSelected = new FormControl(null, [Validators.required]);
  trainingDatesCount: Date[] = [];

  presenceResourceAction: TableAction[] = [
    {
      name: 'label.markPresence',
      icon: 'done',
      color: '',
      do: (row) => {
        this.markPresence(row.id)
      }
    },
    {
      name: 'label.markAbsence',
      icon: 'clear',
      color: '',
      do: (row) => {
        this.markAbsence(row.id)
      }
    },
  ]

  site = '';
  trainingName = '';
  instructor = '';

  id;
  presenceResources: ResourcePresence[] = [];

  presenceResourcesColumns;
  constructor(
    public trainingPresenceService: TrainingPresenceService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) { }

  token: string;
  ngOnInit() {
    this.presenceResourcesColumns = [
      'label.registry',
      'label.resourceName',
      'style.present',
      'actions'
    ];
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.trainingPresenceService.getTrainingByToken(this.token).subscribe((resp: any )=> {
      this.id = resp.id;
      this.site = resp.companyUnitName;
      this.trainingName = resp.courseName;
      this.instructor = resp.instructorName;
      this.loadPresences(true);
    })
  }

  loadPresences(isFirstLoad?: boolean){
    this.trainingPresenceService.countDays(`${this.id}`).subscribe(
      (resp: any) => {
        this.hasTrainingDays = true;
        this.trainingPresenceService.getTrainingDates(`${this.id}`).pipe(
          switchMap((resp: any[]) => {
            if (isFirstLoad) {
              console.log('resp',resp)
              this.dateSelected.setValue(resp[0]);
            }
            this.hasTrainingDays = true;
            this.trainingDatesCount = resp;
            return this.trainingPresenceService.getPresencesByDayAndToken(`${this.token}`,this.dateSelected.value);
          })
        ).subscribe(
          (response: any) => {
            this.presenceResources = response.presences.map(({resource, isPresent, date, canChangePresence}: any) => {
              return new ResourcePresence(
                date,
                resource.registry,
                resource.name,
                resource.positionId,
                resource.positionName,
                isPresent === true ? 'Presente' : isPresent === false ?'Ausente':'-',
                resource.id,
                canChangePresence
              );
            }).sort( (a: ResourcePresence,b: ResourcePresence) => {
              if(a.resourceName < b.resourceName) return -1;
              if(b.resourceName < a.resourceName) return 1;
              return 0;
            })
          }
        );
      },
      error => {
        this.hasTrainingDays = false;
      }
    );
  }

  handleChangesOnPresences(event){
    if(event.isUserInput && event.source.value){
      this.trainingPresenceService.getPresencesByDayAndToken(`${this.token}`,event.source.value).subscribe(
        (response: any) => {
          this.presenceResources = response.presences.map(({resource, isPresent, date, canChangePresence}: any) => {
            return new ResourcePresence(
              date,
              resource.registry,
              resource.name,
              resource.positionId,
              resource.positionName,
              isPresent === true ? 'Presente' : isPresent === false ?'Ausente':'-',
              resource.id,
              canChangePresence
            );
          }).sort( (a: ResourcePresence,b: ResourcePresence) => {
            if(a.resourceName < b.resourceName) return -1;
            if(b.resourceName < a.resourceName) return 1;
            return 0;
          })
        }
      );
    }

  }

  markPresence(resourceId){
    this.trainingPresenceService.changePresence(resourceId,this.id,this.dateSelected.value, true).subscribe(response => {
      this.loadPresences();
    },error => {
      this.dialogService.openAlertDialog("error.presenceFail");
    });
  }

  markAbsence(resourceId){
    this.trainingPresenceService.changePresence(resourceId,this.id,this.dateSelected.value, false).subscribe(response => {
      this.loadPresences();
    },error => {
      this.dialogService.openAlertDialog("error.presenceFail");
    });
  }

}
