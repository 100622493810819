import { Component, OnInit } from '@angular/core';
import { TRAINING_PLAN_REPORT } from 'src/app/api-urls';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { traningPlan, traningPlanPattern, traningPlanRecycling } from 'src/app/util-json.data';

@Component({
  selector: 'app-training-plan',
  templateUrl: './training-plan.component.html',
  styleUrls: ['./training-plan.component.css']
})
export class TrainingPlanComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.trainingPlan']
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.exportExcel',
      style: 'generic-button',
			do: () => {
				this.generateExcel();
			}
		},
    {
			label: 'label.exportPdf',
      style: 'generic-button',
			do: () => {
				this.generatePdf();
			}
		},
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
  ]
  searchUrl = TRAINING_PLAN_REPORT;

  trainings: any = [];
  columns: any[] = [];
  filter: SearchField[];
  types = traningPlan;
  typesPattern = traningPlanPattern;
  typesRecycling = traningPlanRecycling;
  filterFields = [
    'companyUnitRequired',
    'department',
    'referenceYears'
  ];
  referenceYearsName="label.referenceYear";

  showTable = false;
  constructor(private apiService: TalentApiService<void>,
    private dialogService: DialogService) { }

  results(response) {
    if(response.result.length>0){
      let result = response.result.sort(this.compare);
      this.filter = response.filter;
      this.trainings = [];

      if(this.filter[0].name == "departmentId"){
        this.dialogService.openAlertDialog(`Please select Site`);
      }else{
        if((this.filter[1] ? this.filter[1].name != "departmentId" : true) ){
          this.dialogService.openAlertDialog(`Please select Department`);
        }else if(this.filter[0].name != "companyUnitId"){
          this.dialogService.openAlertDialog(`Please select Site`);
        }else {
          if(Array.isArray(result) && result.length) {
            this.showTable = true;
            this.trainings = result;
            for(let training of result) {
              if(training.monthData) {
                for(let monthData of Object.keys(training.monthData)) {
                  training[monthData] = training['monthData'][monthData];
                }
              }
            }
          }
        }
      }
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generateExcel',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(TRAINING_PLAN_REPORT + "/xlsx/" + fileName, this.filter,fileName,'xlsx');
    });
  }

  generatePdf(){
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generatePdf',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(TRAINING_PLAN_REPORT + "/pdf/" + fileName, this.filter,fileName,'pdf');
    });
  }

  ngOnInit() {
    this.columns = [
    'label.reference',
    'label.courseName',
    'label.department',
    'label.duration',
    'style.JAN',
    'style.FEV',
    'style.MAR',
    'style.ABR',
    'style.MAI',
    'style.JUN',
    'style.JUL',
    'style.AGO',
    'style.SET',
    'style.OUT',
    'style.NOV',
    'style.DEZ']
  }

  compare(a,b) {
    if (a.reference.toLowerCase() < b.reference.toLowerCase())
       return -1;
    if (a.reference.toLowerCase() > b.reference.toLowerCase())
      return 1;
    return 0;
  }

  clear(){
    this.showTable = false;
    this.trainings = [];
  }
}
