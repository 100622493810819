import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { ColumnType, TableAction } from 'src/app/components/table/table-action';
import { Resource } from 'src/app/models/resource';
import { DialogService } from 'src/app/shared/dialog.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import { TrainingApiService } from 'src/app/shared/training-api.service';
import { NavigationHeaderComponent } from '../navigation-header/navigation-header.component';
import {forkJoin} from 'rxjs';
import {TrainingHelpService} from '../../../shared/training-help.service';

@Component({
  selector: 'app-add-resources-training',
  templateUrl: './add-resources-training.component.html',
  styleUrls: ['./add-resources-training.component.css']
})
export class AddResourcesTrainingComponent implements OnInit, OnDestroy {

  generalInfo: FormGroup;
  resourceForm: FormGroup;
  loadingInstructors = false;
  resourceControl = new FormControl(null);
  filter = new FormControl('');
  resColumns: ColumnType[] = [
    {name: 'label.registry', property: 'registry'},
    {name: 'label.resourceName', property: 'name'},
    {name: 'label.positionId', property: 'positionId'},
    {name: 'label.positionName', property: 'positionName'},
  ];
  displayedColumns: string[] = [];
  untouched = true;
  resTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'delete',
      color: '#B41E2A',
      style: '',
      do: (row) => {
        this.removeResource(row);
      }
    },
  ];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.cancel',
      style: 'cancel-button',
      router: '/main/calendar'
    },
    {
      label: 'label.save',
      style: 'generic-button',
      disabled: true,
      do: () => {
        this.saveTrainingResourcesAndNext();
      }
    }
  ];
  data: any;
  newRegistry = false;
  trainingStatus: string;

  pageSize = 5;
  pageIndex = 0;

  constructor(
    private fb: FormBuilder,
    private resourcesApi: ResourceApiService,
    private trainingApi: TrainingApiService,
    private routeSnapshot: ActivatedRoute,
    private dialogService: DialogService,
    private trainingHelpService: TrainingHelpService,
    private router: Router
  ) {
    this.resourceControl = this.fb.control('');
    this.trainingId = this.routeSnapshot.snapshot.params.id;
    if (!this.trainingId) {
      this.generalInfo = this.fb.group({
        companyUnitId: [''],
        departmentId: [''],
        selectedCourseId: [''],
        instructor: [''],
        type: [''],
        status: [''],
        startDate: [''],
        endDate: [''],
        expireDate: ['']
      });
      this.data = this.router.getCurrentNavigation().extras.state;
    }
  }

  resources: Resource[] = [];
  resourcesMap = new Map<number, Resource>();
  selectedResources: Resource[] = [];
  originalSelectedResources: Resource[] = [];
  trainingId: number;
  resourcesList: Resource[];
  originalResourcesList: Resource[];
  filteredResources: Resource[] = [];
  loading: boolean = false;

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('ADD_RESOURCES');
    this.mapColumns(this.resColumns);
    if (this.trainingId) {
      this.showProgressbar(true);
      forkJoin([
        this.trainingApi.getAllResourcesOfCompanyByTraining(this.trainingId),
        this.trainingApi.getResourcesOfTraining(this.trainingId),
        this.trainingApi.getTrainingInfo(this.trainingId)])
        .subscribe({
          next: (value) => {
            const [resourcesOfCompanyByTraining, resourcesOfTraining, trainingInfo] = value;

            const isCancelled = trainingInfo.status === 'Cancelado';
            this.trainingStatus = trainingInfo.status;
            if (this.trainingStatus !== 'Programado') {
              this.changeDisableButton(true);
              this.resTableAction[0].style = 'disabled';
            }
            this.updateNavigateHeader(this.trainingId, isCancelled);

            this.originalResourcesList = Array.from(resourcesOfCompanyByTraining);
            this.resourcesList = resourcesOfCompanyByTraining;
            this.filteredResources = resourcesOfCompanyByTraining;

            this.resources = resourcesOfTraining;
            this.fillResources();
            this.validSaveButton();
          }
        });

      this.breadcrumbActions[0].label = 'label.close';
      this.breadcrumbActions[1].label = 'label.update';
    } else {
      this.newRegistry = true;
      if (this.data) {
        this.insertData();
        this.generalInfo.patchValue(this.data.trainingInfo);
        const companyUnitId = this.generalInfo.get('companyUnitId').value;
        if(companyUnitId && companyUnitId!=undefined){
          this.resourcesApi.findResourcesByCompanyUnit(companyUnitId).subscribe(response => {
            this.resourcesList = response;
            this.filteredResources = response;
            if(this.data.resources != undefined){
              this.resources = this.data.resources;
              this.fillResources();
              this.validSaveButton();
            }
          });
        }
      }
    }
    this.resourceControl.valueChanges.subscribe(value => {
      if(typeof value == 'string'){
        this.filteredResources = this.filterResource(value);
      }
    })
    this.filter.valueChanges.subscribe({
      next: (filter: string) => {
        const value = filter.toLowerCase();
        if (value !== '') {
          this.selectedResources = this.originalSelectedResources.filter(presenceResource =>
            presenceResource.name.toLowerCase().includes(value) ||
            presenceResource.registry.toString().includes(value) ||
            presenceResource.positionName.toLowerCase().includes(value) ||
            presenceResource.positionId.toString().includes(value)
          );
        } else {
          this.pageChange({pageSize: this.pageSize, pageIndex: this.pageIndex});
        }
      }
    });
  }

  fillResources() {
    this.resources.forEach(resource => {
      this.resourcesMap.set(resource.id, resource);
      this.resourcesList.splice(this.resourcesList.findIndex(
        resourcesList => resourcesList.id == resource.id), 1);
    });
    this.originalSelectedResources = Array.from(this.resourcesMap.values());
    this.selectedResources = this.originalSelectedResources;
    this.updateSelectedResources();
    this.showProgressbar(false);
  }

  showProgressbar(wait: boolean) {
    this.loading = wait;
    document.body.style.cursor = wait? 'wait':'inherit';
  }

  mapColumns(columns: any) {
    this.displayedColumns = columns.map((column: any) => column.property);
    this.displayedColumns.push('action');
  }

  getTableCell(e: any, c: any) {
    return e[c.property];
  }

  addResources(){
    const resource: Resource = this.resourceControl.value;
    if(resource){
      this.resourcesMap.set(resource.id,resource);
      this.originalSelectedResources = Array.from(this.resourcesMap.values());
      this.selectedResources = this.originalSelectedResources;
      this.resourceControl.patchValue('');
      this.untouched = false;
      this.resourcesList.splice(this.resourcesList.findIndex(
        resourcesList => resourcesList.id == resource.id), 1);
    }
    this.validSaveButton();
    this.updateSelectedResources();
  }

  removeResource(resource){
    this.resourcesMap.delete(resource.id);
    this.originalSelectedResources = Array.from(this.resourcesMap.values());
    this.selectedResources = this.originalSelectedResources;
    this.resourcesList.push(resource);
    this.resourcesList.sort(this.compare);
    this.untouched=false;
    this.validSaveButton();
    this.updateSelectedResources();
  }

  validSaveButton(){
    if(this.newRegistry){
      if(this.generalInfo.valid && this.selectedResources.length>0){
        this.changeDisableButton(false);
        this.data.resources = this.selectedResources;
        this.insertData();
      } else if(this.generalInfo.invalid || this.selectedResources.length==0){
        this.changeDisableButton(true);
      }
    }else {
      if(this.selectedResources.length>0 && this.trainingStatus === 'Programado'){
        this.changeDisableButton(false);
      } else {
        this.changeDisableButton(true);
      }
    }
  }

  getResourceName(option){
    return option.name;
  }

  saveTrainingResources(){
    if(!this.untouched){
      const routeTrainingId = this.routeSnapshot.snapshot.params.id;
      this.trainingApi.saveResourcesInTraining(routeTrainingId,this.resourcesIdList()).subscribe( () => {
        this.dialogService.openInformationDialog('message.savedResource')
      })
    }
  }

  saveTrainingResourcesAndNext() {
    if (this.newRegistry) {
      this.trainingApi.saveTrainingInfo(this.generalInfo.value).subscribe(response => {
        const trainingId = response.id;
        this.trainingApi.saveResourcesInTraining(trainingId, this.resourcesIdList()).subscribe(() => {
          this.dialogService.openInformationDialog('message.savedTraining');
          this.router.navigate(['/main/calendar']);
        });
      }, handleSaveError(this.dialogService));
    } else if (!this.untouched) {
      this.dialogService.openConfirmInnerHtmlDialog({message:'message.addResourcesReset'})
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            const routeTrainingId = this.routeSnapshot.snapshot.params.id;
            this.trainingApi.saveResourcesInTraining(routeTrainingId, this.resourcesIdList()).subscribe(() => {
              this.dialogService.openInformationDialog('message.savedTraining')
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['../mark-presence'], {relativeTo: this.routeSnapshot})
                });
            });
          } else {
            this.resetResourceTable();
          }
        });
    }
  }

  resetResourceTable() {
    this.selectedResources = Array.from(this.resources);
    this.originalSelectedResources = Array.from(this.resources);
    this.resourcesList = Array.from(this.originalResourcesList);
    this.resourcesMap.clear();
    this.untouched = true;

    this.fillResources();
  }

  filterResource(value: string): Resource[] {
    const filterValue = value ? value.toLowerCase() : '';
    if(this.resourcesList && this.resourcesList.length>0){
      return this.resourcesList.filter(resource => resource.name.toLowerCase().includes(filterValue));
    }
  }
  compare(a,b) {
    if (a.name.toLowerCase() < b.name.toLowerCase())
       return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase())
      return 1;
    return 0;
  }
  resourcesIdList(){
    return this.originalSelectedResources.map(resource => resource.id);
  }

  @ViewChild(NavigationHeaderComponent) navHeader: NavigationHeaderComponent
  updateNavigateHeader(idTraining, canceledDisabled) {
    this.navHeader.changetoUpdate(idTraining, canceledDisabled);
  }

  insertData(){
    this.navHeader.insertData(this.data);
  }

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent
  changeDisableButton(disable:boolean){
    this.breadcrumb.changeDisabledButton({
      indice: 1,
      disable: disable
    });
  }

  @ViewChild(MatAutocompleteTrigger) trigger;
  clickAuto() {
      this.trigger._onChange('');
      this.trigger.openPanel();
  }

  pageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateSelectedResources();
  }

  updateSelectedResources() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.selectedResources = this.originalSelectedResources.slice(startIndex, endIndex);
  }
}

function handleSaveError(dialogService){
  return (error)=> {
    switch(error.error.message){
      case 'dateRange':
        dialogService.openAlertDialog('error.dateRange');
        break;
      case 'expireDateRange':
        dialogService.openAlertDialog('error.expireDateRange');
        break;
    }
  }
}
