import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PROFICIENCY_API } from 'src/app/api-urls';
import { Proficiency } from 'src/app/models/proficiency';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-conhecimento-form',
  templateUrl: './conhecimento-form.component.html',
  styleUrls: ['./conhecimento-form.component.css']
})
export class ConhecimentoFormComponent implements OnInit {
  formGroup: FormGroup;

  name: FormControl = new FormControl('');
  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('');
  level: FormControl = new FormControl('1');

  isEdit: boolean = false;
  @Output() sucessfullySaved: EventEmitter<any> = new EventEmitter();
  lastLevel: number;
  constructor(private fb: FormBuilder,
    private dialogService: DialogService,
    private proficiencyApiService: TalentApiService<Proficiency>) { }

  ngOnInit() {

    this.proficiencyApiService.listObject(`${PROFICIENCY_API}/lastLevel`).subscribe( response => {
      this.lastLevel = response;
      this.level.patchValue(response+1);
    })
    this.formGroup = this.fb.group({
      id: null,
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      active: true,
      description: new FormControl(''),
      level: new FormControl('1', [Validators.required, Validators.pattern("^[0-9]*$")])
    });
    this.name.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['name'].patchValue(value);
      }
    });
    this.level.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['level'].patchValue(value);
      }
    });
  }

  save() {
    this.proficiencyApiService.saveObject(PROFICIENCY_API, this.formGroup.value).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedProficiency',this.formGroup.get('level').value);
      this.clear();
      this.sucessfullySaved.emit(true);
    }, error => {
      this.dialogService.openInformationDialog('error.proficiencyNotSaved',this.formGroup.get('level').value);
      this.clear();
      this.sucessfullySaved.emit(false);
    });
  }

  clear(){
    this.isEdit = false;
    this.formGroup.reset();
    this.formGroup.controls['active'].patchValue(true);
    this.name.patchValue('',{emitEvent:false});
    this.description.patchValue('',{emitEvent:false});
    this.level.patchValue(this.lastLevel+1);
  }

  fillForm(row:any){
    this.isEdit = true;
    this.formGroup.patchValue(row);
    this.name.patchValue(row.name);
    this.description.patchValue(row.description);
    this.level.patchValue(row.level);
  }

}
