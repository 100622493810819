import { ValidatorFn } from '@angular/forms';
import { InputComponent } from './../../components/dialog/input/input.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { SKILL_MATRIX_REPORT, PRINT_SKILL_MATRIX } from './../../api-urls';
import { Component, OnInit } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { skillLevelTypes } from 'src/app/util-json.data';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-skill-matrix',
  templateUrl: './skill-matrix.component.html',
  styleUrls: ['./skill-matrix.component.css']
})
export class SkillMatrixComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.skillMatrix']
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.exportExcel',
      style: 'generic-button',
			do: () => {
				this.generateExcel();
			}
		},
    {
			label: 'label.exportPdf',
      style: 'generic-button',
			do: () => {
				this.generatePdf();
			}
		},
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
  ]
  showTable = false;
  searchUrl = SKILL_MATRIX_REPORT;

  resources: any = [];
  columns: any[] = [];
  filter: SearchField[];
  types = skillLevelTypes;
  filterFields = [
    'companyUnitRequired',
    'department',
    'position',
    'resource'
  ];

  constructor(private apiService: TalentApiService<void>,
    private dialogService: DialogService) { }

  ngOnInit() {
  }

  results(response) {
    if(response.result.length>0){
      let result = response.result;
      this.filter = response.filter;

      this.columns = [];
      this.resources = [];
      if(Array.isArray(result) && result.length) {
        this.columns.push('label.resourceName');
        this.columns.push('label.positionName');

        this.resources = result;
        this.showTable = true;
        for(let resource of result) {
          if(resource.skillsData) {
            for(let skillData of Object.keys(resource.skillsData)) {
              resource[skillData] = resource['skillsData'][skillData];
              if(!this.columns.includes('style.' + skillData)) {
                this.columns.push('style.' + skillData);
              }
            }
          }
        }
      }
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generateExcel',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(SKILL_MATRIX_REPORT + "/xlsx/" + fileName, this.filter,fileName,'xlsx');
    });
  }

  generatePdf(){
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generatePdf',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(SKILL_MATRIX_REPORT + "/pdf/"+fileName, this.filter,fileName,'pdf');
    });
  }
  clear(){
    this.showTable = false;
    this.resources = [];
    this.columns = [];
  }
}
