import { BaseRegistrationComponent } from '../../components/base-registration/base-registration.component';
import { PositionDialogComponent } from '../../dialogs/position-dialog/position-dialog.component';
import { DialogService } from '../../shared/dialog.service';
import { Component } from '@angular/core';
import { TalentApiService } from '../../shared/api.service';
import { POSITION_API } from '../../api-urls';
import { Position } from '../../models/position';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent extends BaseRegistrationComponent<Position> {

  columns: string[] = ['id', 'positionName', 'departmentName', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Position>) {
    super(dialogService, apiService);
    this.editComponent = PositionDialogComponent;
    this.objectApi = POSITION_API;
    this.objectType = 'Position';
  }

}
