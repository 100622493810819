import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import localeEs from "@angular/common/locales/es";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_DATE_FORMATS,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatPaginatorIntl,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ZXingScannerComponent, ZXingScannerModule } from "@zxing/ngx-scanner";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ChartsModule } from "ng2-charts";
import { IConfig, NgxMaskModule } from "ngx-mask";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BaseDialogComponent } from "./components/base-dialog/base-dialog.component";
import { BaseRegistrationComponent } from "./components/base-registration/base-registration.component";
import { BaseReportComponent } from "./components/base-report/base-report.component";
import { BaseViewComponent } from "./components/base-view/base-view.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { CrossTableComponent } from "./components/cross-table/cross-table.component";
import { CustomHeaderComponent } from "./components/custom-header/custom-header.component";
import { DefaultFilterComponent } from "./components/default-filter/default-filter.component";
import { AddSkillComponent } from "./components/dialog/add-skill/add-skill.component";
import { AlertSkillComponent } from "./components/dialog/alert-skill/alert-skill.component";
import { AlertComponent } from "./components/dialog/alert/alert.component";
import { ConfirmComponent } from "./components/dialog/confirm/confirm.component";
import { InformationComponent } from "./components/dialog/information/information.component";
import { InputComponent } from "./components/dialog/input/input.component";
import { LoaderDialogComponent } from "./components/dialog/loader-dialog/loader-dialog.component";
import { LoaderComponent } from "./components/dialog/loader/loader.component";
import { SendDailyControlComponent } from "./components/dialog/send-daily-control/send-daily-control.component";
import { MenuComponent } from "./components/menu/menu.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ProgressBar } from "./components/progress-bar/progress-bar.component";
import { RootComponent } from "./components/root/root.component";
import { TableComponent } from "./components/table/table.component";
import { Tooltip } from "./components/tooltip/tooltip.component";
import { CourseDialogComponent } from "./dialogs/course-dialog/course-dialog.component";
import { DepartmentDialogComponent } from "./dialogs/department-dialog/department-dialog.component";
import { PositionDialogComponent } from "./dialogs/position-dialog/position-dialog.component";
import { PositionHistoryDialogComponent } from "./dialogs/position-history-dialog/position-history-dialog.component";
import { ProficiencyDialogComponent } from "./dialogs/proficiency-dialog/proficiency-dialog.component";
import { ResourceDialogComponent } from "./dialogs/resource-dialog/resource-dialog.component";
import { SkillDialogComponent } from "./dialogs/skill-dialog/skill-dialog.component";
import { TrainingDialogComponent } from "./dialogs/training-dialog/training-dialog.component";
import { UnitDialogComponent } from "./dialogs/unit-dialog/unit-dialog.component";
import { UserRegisterDialogComponent } from "./dialogs/user-register-dialog/user-register-dialog.component";
import { AccessHistoryComponent } from "./reports/access-history/access-history.component";
import { CompletedCourseListComponent } from "./reports/completed-course-list/completed-course-list.component";
import { CourseMasterListComponent } from "./reports/course-master-list/course-master-list.component";
import { CourseMatrixComponent } from "./reports/course-matrix/course-matrix.component";
import { ExpiredCourseListComponent } from "./reports/expired-course-list/expired-course-list.component";
import { FulfilledCourseMatrixComponent } from "./reports/fulfilled-course-matrix/fulfilled-course-matrix.component";
import { GeneralRadarComponent } from "./reports/general-radar/general-radar.component";
import { PendingCourseListComponent } from "./reports/pending-course-list/pending-course-list.component";
import { SkillAnalysisComponent } from "./reports/skill-analysis/skill-analysis.component";
import { SkillMatrixComponent } from "./reports/skill-matrix/skill-matrix.component";
import { SkillMissingMatrixComponent } from "./reports/skill-missing-matrix/skill-missing-matrix.component";
import { TrainingDashboardComponent } from "./reports/training-dashboard/training-dashboard.component";
import { TrainingMatrixComponent } from "./reports/training-matrix/training-matrix.component";
import { TrainingPlanComponent } from "./reports/training-plan/training-plan.component";
import { AuthGuard } from "./shared/auth-guard.service";
import { AuthService } from "./shared/auth.service";
import { CustomHttpRequestInterceptor } from "./shared/custom-http.service";
import { CustomPaginatorIntl } from "./shared/custom-paginator-intl";
import { ErrorHandlerService } from "./shared/error.handler.service";
import { MaterialModule } from "./shared/material.module";
import { reducers } from "./store/app.states";
import { AttendanceListComponent } from "./views/attendance-list/attendance-list.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { CompareGeneralRadarComponent } from "./views/compare-general-radar/compare-general-radar.component";
import { CompareSkillsComponent } from "./views/compare-skills/compare-skills.component";
import { CourseComponent } from "./views/course/course.component";
import { DepartmentComponent } from "./views/department/department.component";
import { ForgotPasswordComponent } from "./views/forgot-password/forgot-password.component";
import { FaqComponent } from "./views/help/faq/faq.component";
import { HelpComponent } from "./views/help/help.component";
import { HomeComponent } from "./views/home/home.component";
import { LoginComponent } from "./views/login/login.component";
import { MainRegistrationComponent } from "./views/main-registration/main-registration.component";
import { PositionSkillSiteComponent } from "./views/position-skill-site/position-skill-site.component";
import { PositionComponent } from "./views/position/position.component";
import { PresenceConfirmComponent } from "./views/presence-confirm/presence-confirm.component";
import { ProficiencyComponent } from "./views/proficiency/proficiency.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { QrCodeAttendanceComponent } from "./views/qr-code-attendance/qr-code-attendance.component";
import { CargoFormComponent } from "./views/registration/cargo/cargo-form/cargo-form.component";
import { CargoComponent } from "./views/registration/cargo/cargo.component";
import { ConhecimentoFormComponent } from "./views/registration/conhecimento/conhecimento-form/conhecimento-form.component";
import { ConhecimentoComponent } from "./views/registration/conhecimento/conhecimento.component";
import { CursoFormComponent } from "./views/registration/curso/curso-form/curso-form.component";
import { GeneralInfoCourseComponent } from "./views/registration/curso/curso-form/general-info/general-info.component";
import { PositionCourseComponent } from "./views/registration/curso/curso-form/position/position.component";
import { SkillCourseComponent } from "./views/registration/curso/curso-form/skill/skill.component";
import { CursoComponent } from "./views/registration/curso/curso.component";
import { DepartamentoFormComponent } from "./views/registration/departamento/departamento-form/departamento-form.component";
import { DepartamentoComponent } from "./views/registration/departamento/departamento.component";
import { ProcedimentoFormComponent } from "./views/registration/procedimento/procedimento-form/procedimento-form.component";
import { ProcedimentoComponent } from "./views/registration/procedimento/procedimento.component";
import { QrCodeComponent } from "./views/registration/qr-code/qr-code.component";
import { CompetenceComponent } from "./views/registration/recurso/competence/competence.component";
import { GeneralInfoComponent } from "./views/registration/recurso/general-info/general-info.component";
import { NavigationBarComponent } from "./views/registration/recurso/navigation-bar/navigation-bar.component";
import { PositionHistoryComponent } from "./views/registration/recurso/position-history/position-history.component";
import { ProficienciesComponent } from "./views/registration/recurso/proficiencies/proficiencies.component";
import { RecursoComponent } from "./views/registration/recurso/recurso.component";
import { RegistrationComponent } from "./views/registration/registration.component";
import { SiteFormComponent } from "./views/registration/site/site-form/site-form.component";
import { SiteComponent } from "./views/registration/site/site.component";
import { UserComponent } from "./views/registration/user/user.component";
import { ReportsPanelComponent } from "./views/reports-panel/reports-panel.component";
import { ResourceCompetenceComponent } from "./views/resource-competence/resource-competence.component";
import { ResourceSkillComponent } from "./views/resource-skill/resource-skill.component";
import { ResourceComponent } from "./views/resource/resource.component";
import { SkillComponent } from "./views/skill/skill.component";
import { ScheduleDialogComponent } from "./views/training-calendar/schedule-dialog/schedule-dialog.component";
import { TrainingCalendarComponent } from "./views/training-calendar/training-calendar.component";
import { AddResourcesTrainingComponent } from "./views/training/add-resources-training/add-resources-training.component";
import { MarkPresenceComponent } from "./views/training/mark-presence/mark-presence.component";
import { NavigationHeaderComponent } from "./views/training/navigation-header/navigation-header.component";
import { SendListComponent } from "./views/training/send-list/send-list.component";
import { TrainingGradesComponent } from "./views/training/training-grades/training-grades.component";
import { TrainingInfoComponent } from "./views/training/training-info/training-info.component";
import { UnitComponent } from "./views/unit/unit.component";
import { ConfirmInnerHTMLComponent } from "./components/dialog/confirm-inner-html/confirm-inner-html.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localePt);
registerLocaleData(localeEs);
const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: { year: "numeric", month: "short" },
  },
};
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    ZXingScannerModule,
    MaterialModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaskModule.forRoot(options),
    StoreModule.forRoot(reducers, {}),
    AngularMultiSelectModule,
    FormsModule,
    // ViewsModule,
    // ReportsModule
  ],

  declarations: [
    FaqComponent,
    AppComponent,
    LoginComponent,
    HomeComponent,
    RootComponent,
    ConfirmComponent,
    ConfirmInnerHTMLComponent,
    AlertComponent,
    InformationComponent,
    LoaderComponent,
    BaseViewComponent,
    ProfileComponent,
    NotFoundComponent,
    MenuComponent,
    CalendarComponent,
    TableComponent,
    ProgressBar,
    CrossTableComponent,
    MainRegistrationComponent,
    DepartmentComponent,
    UnitComponent,
    ResourceComponent,
    TrainingCalendarComponent,
    ResourceDialogComponent,
    DepartmentDialogComponent,
    UnitDialogComponent,
    PositionComponent,
    SkillComponent,
    SkillDialogComponent,
    PositionDialogComponent,
    CourseDialogComponent,
    CourseComponent,
    ProficiencyDialogComponent,
    TrainingDialogComponent,
    BaseDialogComponent,
    ProficiencyComponent,
    BaseRegistrationComponent,
    PositionHistoryDialogComponent,
    ResourceCompetenceComponent,
    InputComponent,
    ResourceSkillComponent,
    SkillMatrixComponent,
    SkillMissingMatrixComponent,
    SkillAnalysisComponent,
    BaseReportComponent,
    DefaultFilterComponent,
    CourseMasterListComponent,
    TrainingDashboardComponent,
    AccessHistoryComponent,
    CourseMatrixComponent,
    TrainingPlanComponent,
    FulfilledCourseMatrixComponent,
    ExpiredCourseListComponent,
    PendingCourseListComponent,
    CompletedCourseListComponent,
    TrainingMatrixComponent,
    CompareSkillsComponent,
    GeneralRadarComponent,
    CompareGeneralRadarComponent,
    PositionSkillSiteComponent,
    ReportsPanelComponent,
    HelpComponent,
    Tooltip,
    RegistrationComponent,
    SiteComponent,
    SiteFormComponent,
    CursoComponent,
    CursoFormComponent,
    GeneralInfoCourseComponent,
    SkillCourseComponent,
    PositionCourseComponent,
    DepartamentoComponent,
    DepartamentoFormComponent,
    ConhecimentoComponent,
    ConhecimentoFormComponent,
    RecursoComponent,
    CargoComponent,
    CargoFormComponent,
    ProcedimentoComponent,
    ProcedimentoFormComponent,
    QrCodeComponent,
    QrCodeAttendanceComponent,
    AttendanceListComponent,
    PresenceConfirmComponent,
    UserComponent,
    UserRegisterDialogComponent,
    TrainingInfoComponent,
    AddResourcesTrainingComponent,
    SendListComponent,
    MarkPresenceComponent,
    NavigationHeaderComponent,
    TrainingGradesComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    NavigationBarComponent,
    GeneralInfoComponent,
    PositionHistoryComponent,
    CompetenceComponent,
    ProficienciesComponent,
    AddSkillComponent,
    AlertSkillComponent,
    CustomHeaderComponent,
    BreadcrumbComponent,
    LoaderDialogComponent,
    SendDailyControlComponent,
    ScheduleDialogComponent,
  ],

  providers: [
    AuthService,
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpRequestInterceptor,
      multi: true,
    },
    { provide: MatDialogTitle, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],

  entryComponents: [
    ConfirmComponent,
    AlertComponent,
    InformationComponent,
    ConfirmInnerHTMLComponent,
    ResourceDialogComponent,
    DepartmentDialogComponent,
    UnitDialogComponent,
    SkillDialogComponent,
    PositionDialogComponent,
    CourseDialogComponent,
    ProficiencyDialogComponent,
    TrainingDialogComponent,
    PositionHistoryDialogComponent,
    InputComponent,
    QrCodeAttendanceComponent,
    ZXingScannerComponent,
    UserRegisterDialogComponent,
    TrainingInfoComponent,
    AddResourcesTrainingComponent,
    SendListComponent,
    MarkPresenceComponent,
    NavigationHeaderComponent,
    TrainingGradesComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AddSkillComponent,
    AlertSkillComponent,
    CustomHeaderComponent,
    BreadcrumbComponent,
    LoaderDialogComponent,
    SendDailyControlComponent,
    ScheduleDialogComponent,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
