import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DialogService } from 'src/app/shared/dialog.service';
import { TrainingPresenceService } from 'src/app/shared/training-presence.service';

@Component({
  selector: 'app-qr-code-attendance',
  templateUrl: './qr-code-attendance.component.html',
  styleUrls: ['./qr-code-attendance.component.css']
})
export class QrCodeAttendanceComponent implements OnInit {

  constructor(
    private dialogService: DialogService,
    private trainingPresenceService: TrainingPresenceService,
    private route: ActivatedRoute,
    private router: Router
  ){}
  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  hasCameras = false;
  hasPermission: boolean;
  qrResultString: string;
  scannerEnabled = true;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo;

  qrResult;
  hasDevices;
  currentDevice;

  token = '';
  id = '';
  day = '';


  ngOnInit(): void {

    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;

      console.log('Devices: ', devices);
      this.availableDevices = devices;

      // selects the devices's back camera by default
      // for (const device of devices) {
      //     if (/back|rear|environment/gi.test(device.label)) {
      //         this.scanner.changeDevice(device);
      //         this.selectedDevice = device;
      //         break;
      //     }
      // }
   });

    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
        console.error('An error has occurred when trying to enumerate your video-stream-enabled devices.');
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });

    this.token = this.route.snapshot.queryParamMap.get('token');
    this.day = this.route.snapshot.queryParamMap.get('day');
    this.trainingPresenceService.getTrainingByToken(this.token).subscribe((resp: any )=> {
      this.id = resp.id;
    })
  }
    handleQrCodeResult(resultString: string) {
      if(this.scannerEnabled){
        this.scannerEnabled = false;
        this.qrResultString = resultString;
        const [registry,cpf] = resultString.split(';');
        this.trainingPresenceService.findNameByRegistryAndCPF(cpf,registry).subscribe(
          (resp: any) => {
            this.markPresence(resp.id).subscribe( _ => {
              this.router.navigate(['/presence-confirm'],{queryParams: {token: this.token, day: this.day, name: resp.name}})
              // this.dialogService.openConfirmDialog('message.presenceMarked',resp.name)
              // .afterClosed().subscribe( confirm => {
              //   if(!confirm){
              //     this.router.navigate(['/qr-code',{queryParams: {token: this.token}}])
              //   }else{

              //   }
              // }, error => {
              //   this.scannerEnabled = true;
              // });
            },error => {
              this.dialogService.openAlertDialog("error.presenceFail");
            })
          }
        );
      }
    }

    onDeviceSelectChange(selectedValue: string) {
      console.log('Selection changed: ', selectedValue);
  }

    markPresence(resourceId){
      return this.trainingPresenceService.markPresence(resourceId,this.id,this.day);
    }

}
