import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material";
import { forkJoin, merge } from "rxjs";
import {
  DEPARTMENT_API,
  GENERAL_RADAR_REPORT,
  POSITION_SITE_API,
} from "src/app/api-urls";
import { BreadcrumbAction } from "src/app/components/breadcrumb/breadcrumb-action";
import { CompanyUnit } from "src/app/models/company-unit";
import { Department } from "src/app/models/department";
import { PositionSite } from "src/app/models/position-site";
import { Resource } from "src/app/models/resource";
import { SearchField } from "src/app/models/search-field.";
import { TalentApiService } from "src/app/shared/api.service";
import { DialogService } from "src/app/shared/dialog.service";

import { COMPANY_API } from "./../../api-urls";

@Component({
  selector: "app-general-radar",
  templateUrl: "./general-radar.component.html",
  styleUrls: ["./general-radar.component.css"],
})
export class GeneralRadarComponent implements OnInit {
  paths: string[] = ["menu.reports", "/", "reportMenu.generalRadar"];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: "label.back",
      router: "..",
      style: "generic-button",
    },
  ];
  companyUnit: FormControl = new FormControl();
  companyUnits: CompanyUnit[];
  selectedCompanyUnit: FormControl = new FormControl();
  filteredCompanyUnits: CompanyUnit[] = [];

  department: FormControl = new FormControl();
  departments: Department[];

  freePosition: FormControl = new FormControl();
  positions: PositionSite[];
  filteredPositions: PositionSite[] = [];

  radarPositions: FormControl = new FormControl();

  selectedPositions;
  selectedFreePosition: any;
  selectedRadarResources: Resource[];
  showInfos: boolean = false;

  constructor(
    private resourcesGeneralRadarApiService: TalentApiService<Resource>,
    private companyUnitApiService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private positionSiteApiService: TalentApiService<PositionSite>,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    forkJoin(
      this.companyUnitApiService.listAllObjects(COMPANY_API),
      this.departmentApiService.listAllObjects(DEPARTMENT_API)
    ).subscribe(([companyUnits, departments]) => {
      this.companyUnits = companyUnits;
      this.filteredCompanyUnits = this.companyUnits;
      this.departments = departments;
    });

    merge(
      this.selectedCompanyUnit.valueChanges,
      this.department.valueChanges
    ).subscribe(() => {
      if (this.selectedCompanyUnit.value && this.department.value) {
        let fields: SearchField[] = [
          { name: "companyUnit", value: this.selectedCompanyUnit.value.id },
          { name: "department", value: this.department.value.id },
        ];
        this.positionSiteApiService
          .searchObjects(POSITION_SITE_API + "/search", fields)
          .subscribe((response) => {
            this.positions = response;
            this.filteredPositions = this.positions;
          });
      }
    });

    this.companyUnit.valueChanges.subscribe((companyUnit) => {
      if (companyUnit && companyUnit.id == undefined) {
        this.filteredCompanyUnits = this.filterCompanyUnit(companyUnit);
      } else if (companyUnit && companyUnit.id != undefined) {
        this.selectedCompanyUnit.setValue(companyUnit);
      }
    });

    this.freePosition.valueChanges.subscribe((positionSite) => {
      if (positionSite && positionSite.id == undefined) {
        this.filteredPositions = this.filterPosition(positionSite);
      }
    });
  }

  compare() {
    if (this.freePosition.value) {
      this.selectedFreePosition = this.freePosition.value;
    }

    let positionIds = this.radarPositions.value.map((s) => +s.id);
    let fields: SearchField[] = [
      {
        name: "companyUnit",
        value: +this.selectedCompanyUnit.value.id,
      },
      {
        name: "freePosition",
        value: +this.freePosition.value.position.id,
      },
      {
        name: "positions",
        value: positionIds,
      },
    ];
    this.resourcesGeneralRadarApiService
      .searchObjects(GENERAL_RADAR_REPORT, fields)
      .subscribe({
        next: (response) => {
          this.selectedRadarResources = response;
          this.showInfos = true;
        },
        error: (err) => {
          if (err.error.message == "freePositionWithoutSkills") {
            this.dialogService.openAlertDialog(
              "error.freePositionWithoutSkills"
            );
          }
          if (err.error.message == "noResourcesForRadarPosition") {
            this.dialogService.openAlertDialog(
              "error.noResourcesForRadarPosition"
            );
          }
          if (err.error.message == "noResourcesWithSkillProficiencies") {
            this.dialogService.openAlertDialog(
              "error.noResourcesWithSkillProficiencies"
            );
          }
        },
      });
  }

  clear() {
    this.companyUnit.setValue(undefined);
    this.department.setValue(undefined);
    this.freePosition.setValue(undefined);
    this.positions = [];
    this.radarPositions.setValue(undefined);
    this.selectedFreePosition = "";
    this.selectedRadarResources = [];
    this.showInfos = false;
  }

  generateExcel() {
    //
  }

  @ViewChild(MatAutocompleteTrigger) trigger;

  clickAuto() {
    this.trigger._onChange("");
    this.trigger.openPanel();
  }

  filterCompanyUnit(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : "";
    return this.companyUnits.filter((companyUnit) =>
      companyUnit.name.toLowerCase().includes(filterValue)
    );
  }

  filterPosition(value: string): PositionSite[] {
    const filterValue = value ? value.toLowerCase() : "";
    return this.positions.filter((positionSite) =>
      positionSite.position.name.toLowerCase().includes(filterValue)
    );
  }

  displayCompanyUnit(value: any) {
    return value ? value.name : "";
  }

  displayPosition(value: any) {
    return value ? value.position.name : "";
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput) {
      this.filteredPositions = this.filterPosition(searchInput);
    } else {
      this.filteredPositions = this.positions;
    }
  }

  onOpenChange(searchInput: any) {
    searchInput.value = "";
    this.filteredPositions = this.positions;
  }

  onSelectEvent($event: any, position: PositionSite) {
    let selectedPositions = [];
    selectedPositions = this.radarPositions.value;
    if (selectedPositions && selectedPositions != undefined) {
      const positionFound = selectedPositions.find(
        (selectedPosition) =>
          selectedPosition.position.id == position.position.id
      );
      if (positionFound == undefined) {
        selectedPositions.push(position);
        this.radarPositions.patchValue(selectedPositions);
      }
    }
  }

  resetFields(
    control1: FormControl,
    control2?: FormControl,
    control3?: FormControl
  ) {
    control1.setValue(undefined);
    control2 ? control2.setValue(undefined) : null;
    control3 ? control3.setValue(undefined) : null;
  }

  onDepartmentChange() {
    this.resetFields(this.companyUnit, this.freePosition, this.radarPositions);
  }

  onCompanyUnitChange(event: MatAutocompleteSelectedEvent) {
    this.resetFields(this.freePosition, this.radarPositions);
  }

  onFreePositionChange(event: MatAutocompleteSelectedEvent) {
    this.resetFields(this.radarPositions);
  }
}
