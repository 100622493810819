import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  extraMessage: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmComponent>) {
      this.extraMessage = { value: data.extraMessage };
  }

  ngOnInit() {
  }

  accept(){
    this.dialogRef.close(true);
  }

  deny(){
    this.dialogRef.close(false);
  }

}
