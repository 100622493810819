import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PRINT_QR_CODE, QR_CODE_API } from 'src/app/api-urls';
import { BaseRegistrationComponent } from 'src/app/components/base-registration/base-registration.component';
import { ResourceDialogComponent } from 'src/app/dialogs/resource-dialog/resource-dialog.component';
import { QrCode } from 'src/app/models/qr-code';
import { TalentApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { jsPDF } from "jspdf";
import { SearchField } from 'src/app/models/search-field.';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  columns: string[] = ['registry','name','document'];
  qrActions: string[] = [];
  filterFields = ['companyUnitRequired']
  searchUrl = QR_CODE_API
  filter: SearchField[];
  resources: QrCode[];
  paths: string[] = ['title.mainRegistration','/','title.bulkQrCode'];
  showTable:boolean=false;
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
    {
      label: 'label.qrCodeGenerate',
      icon: 'qr_code_2',
      disabled: true,
      style: 'btn-add button-size',
      do: () => {
				this.generatePDF();
			}
    }
  ]
  constructor(
    private dialogService: DialogService,
    private apiService: TalentApiService<QrCode>,
    private authService: AuthService,
    private http: HttpClient
  ) {
  }
  ngOnInit(): void {
    this.apiService.listAllObjects(QR_CODE_API).subscribe(response => {
      this.resources = response;
    });
  }

  results(response) {
    let result = response.result;
    this.filter = response.filter;

    this.resources = [];
    if(this.filter[0].name != "companyUnitId"){
      this.dialogService.openAlertDialog(`Please select Site`);
    }else {
      if(Array.isArray(result) && result.length) {
        this.showTable = true;
        this.resources = result;
        this.setDisabledButton(1,false);
      }
    }
  }

  generatePDF(){
    const doc = new jsPDF({unit: 'px'});

    //A4 size  - 3508 x 2480

    doc.setFontSize(8);

    const array = this.resources;
    let linha = 0;
    let coluna = 0;
    let iterador = 0;
    while(iterador < array.length){
      const dado = array[iterador];
      if(!dado){
        continue;
      }
      let image = new Image();
      image.src = 'data:image/png;base64,'+dado.base64QRCodeImage;
      doc.addImage(image,'png',(80 * coluna) + 20,(80 * linha) + 20,75,75);
      doc.text(dado.registry,(coluna  * 80) + 50,(linha * 80) + 85);
      doc.text(dado.name.substr(0,14),(coluna  * 80) + 35, (linha * 80) + 95);
      iterador++;
      coluna++;
      if(coluna==5){
        linha++;
        coluna=0;
      }
      if(linha==7){
        doc.addPage();
        linha=0;
        coluna=0;
      }
    }

    this.apiService.listAllObjects(`${PRINT_QR_CODE}?companyUnitId=${this.filter[0].value}`).toPromise().then( response => {
        doc.save(`qr-code-unit-${new Date().toLocaleDateString()}.pdf`);
    });

  }

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent | undefined
  setDisabledButton(indice: number, disabled: boolean){
    this.breadcrumb.changeDisabledButton({indice,disabled});
  }
}
