import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RadialChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Resource } from 'src/app/models/resource';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';

@Component({
  selector: 'app-resource-competence',
  templateUrl: './resource-competence.component.html',
  styleUrls: ['./resource-competence.component.css']
})
export class ResourceCompetenceComponent implements OnInit {

  @Input() resource;
  @Input() resourceFilter;
  @Input() skillCompanyUnits;
  @Input() skillProficiencies;
  @Input() performanceGeneral;
  @Input() performancePartial;

  type: FormControl = new FormControl('all');
  labels: string[] = [];

  constructor(private translator: TranslateService) { }

  ngOnInit() {
    forkJoin(
      this.translator.get('label.desiredKnowledge'),
      this.translator.get('label.actualKnowledge')
    ).subscribe(translation => this.labels = translation);

    this.type.valueChanges.subscribe(value => {
      this.refreshData(value);
    });

    this.refreshData('all');
  }

  ngOnChanges(changes: SimpleChanges){
    this.refreshData('all');
  }

  getIcon(resource) {
    let icon = {
      'fas fa-angry': resource.performance < 45,
      'fas fa-smile': resource.performance > 85,
      'fas fa-meh': resource.performance > 45 && resource.performance < 85
    };

    return icon;
  }

  getColor(resource) {
    if(resource.performance < 50) {
      return '#d9534f';
    } else if(resource.performance > 85) {
      return '#5cb85c';
    } else {
      return '#f0ad4e';
    }
  }

  refreshData(skillType: string) {
    if(this.skillCompanyUnits) {
      let skillsPosition = this.skillCompanyUnits;
      let skillsResource = this.skillProficiencies ? this.skillProficiencies : [];

      if(skillType !== 'all') {
        skillsResource = skillsResource.filter(s => s.skill.type === skillType.toUpperCase());
        skillsPosition = skillsPosition.filter(skill => skill['skillType'] === skillType.toUpperCase());
      }

      this.chartLabels = skillsPosition.map(skill => skill['skillAcronym']);

      let positionData: SkillCompanyUnit[] = skillsPosition;
      let resourceSkills = skillsResource.map(skillProficiency => skillProficiency.proficiency && skillProficiency.skill ? {skillId: skillProficiency.skill.id, skillLevel: skillProficiency.proficiency.level} : {});

      this.chartData = [];

      let skillPositionData = positionData.map(positionSite => positionSite.proficiency.level);
      if(skillPositionData.length) {
        this.chartData.push({
          backgroundColor: this.colors[1],
          borderColor: this.colors[1],
          pointBackgroundColor: 'gray',
          pointHoverBackgroundColor: this.colors[1],
          pointHoverBorderWidth: 0,
          borderWidth: 0,
          data: skillPositionData,
          label: this.labels[0]
        });
      }

      let resourceData = positionData.map(positionSkill => {
        let matchedSkill = resourceSkills.find(resourceSkill => resourceSkill.skillId == positionSkill['skillId']);
        if(matchedSkill) {
          return matchedSkill.skillLevel;
        } else {
          return 0;
        }
      });
      if(resourceData.length) {
        this.chartData.push({
          backgroundColor: this.colors[0],
          borderColor: this.colors[0],
          pointBackgroundColor: 'gray',
          pointHoverBackgroundColor: this.colors[0],
          pointHoverBorderWidth: 0,
          borderWidth: 0,
          data: resourceData,
          label: this.labels[1]
        });
      }

      this.pointer = {
        position: + this.performanceGeneral - 0.75 + "%"
      };
    }
  }

  chartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: true,
    },
    tooltips: {
      mode: 'point',
      intersect: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = 'Nível ';
          if (label) {
              label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
          return label;
        }
      }
    },
    scale: {
      pointLabels: {
        fontSize: 16
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        max: 5
      }
    }
  };

  chartLabels: Label[] = [];

  colors: string[] = ['rgb(142,180,227, 0.5)', 'rgb(0,176,240, 0.5)'];

  chartData: ChartDataSets[] = [];

  chartType: ChartType = 'radar';

  bars: any[] = [{
    backgroundColor: "#d9534f",
    fill: "50%",
  }, {
    backgroundColor: "#f0ad4e",
    fill: "35%"
  }, {
    backgroundColor: "#5cb85c",
    fill: "15%"
  }];

  bottomLabels: any[] = [{
    text: "0",
    color: "grey",
    position: "0%"
  }, {
    text: "49",
    color: "grey",
    position: "50%"
  }, {
    text: "85",
    color: "grey",
    position: "85%"
  }, {
    text: "100",
    color: "grey",
    position: "100%"
  }];

  pointer: any;
}
