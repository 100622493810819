import { Injectable} from "@angular/core";
import { MatPaginatorIntl } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl{
  unsubscribe: Subject<void> = new Subject<void>();
  OF_LABEL = 'of ';
  PAGE_LABEL = 'Page';
  UNIQUE_PAGE_LABEL ='Page 1 of 1';
  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate
      .get([
        'paginator.firstPage',
        'paginator.itemsPerPage',
        'paginator.lastPage',
        'paginator.nextPage',
        'paginator.previousPage',
        'paginator.uniquePage',
        'paginator.page',
        'paginator.of'
      ])
      .subscribe(translation => {
        this.firstPageLabel = translation['paginator.firstPage'];
        this.itemsPerPageLabel =
          translation['paginator.itemsPerPage'];
        this.nextPageLabel = translation['paginator.nextPage'];
        this.lastPageLabel = translation['paginator.lastPage'];
        this.previousPageLabel =
          translation['paginator.previousPage'];
        this.UNIQUE_PAGE_LABEL = translation['paginator.uniquePage'];
        this.OF_LABEL = translation['paginator.of'];
        this.PAGE_LABEL = translation['paginator.page'];
        this.changes.next();
      });
  }

  getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ): string => {
    const lastIndice = page*pageSize+pageSize;
    if (length === 0 || pageSize === 0) {
      return `${this.UNIQUE_PAGE_LABEL}`;
    }
    const amountPages = Math.ceil(length / pageSize);
    if(lastIndice >= length){
      return `${page*pageSize+1}-${length} ${
        this.OF_LABEL
      } ${length}`;
    }

    if(page == 0){
      return `${page+1}-${page+1*pageSize} ${
        this.OF_LABEL
      } ${length}`;
    }

    return `${page*pageSize+1}-${page*pageSize+pageSize} ${
      this.OF_LABEL
    } ${length}`;
  };
}
