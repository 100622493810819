import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TRAINING_API } from '../api-urls';
import { Resource } from '../models/resource';
import { ResourcesGradesResponse } from '../views/training/training-grades/training-grades.component';

@Injectable({
  providedIn: 'root'
})
export class TrainingApiService {

  constructor(private http: HttpClient) { }

  getFromSpecificInstructor(instructorUsername: string, date: string){
    return this.http.get<any>(`${TRAINING_API}?username=${instructorUsername}&date=${date}`);
  }

  checkFinish(trainingId, trainingGrades){
    return this.http.put<any>(`${TRAINING_API}/${trainingId}/check-finish`, trainingGrades);
  }

  getAllFromMonthOfDate(date: string){
    return this.http.get<any>(`${TRAINING_API}?date=${date}`);

  }

  saveTrainingInfo(trainingInfo) {
    return this.http.post<any>(`${TRAINING_API}/info`,trainingInfo);
  }

  updateTrainingInfo(trainingInfo,trainingId){
    return this.http.put<any>(`${TRAINING_API}/${trainingId}/info`,trainingInfo);
  }

  getTrainingInfo(routeTrainingId: number) {
    return this.http.get<any>(`${TRAINING_API}/${routeTrainingId}/info`);
  }

  saveResourcesInTraining(routeTrainingId: number, resourcesIdList: number[]) {
    return this.http.post(`${TRAINING_API}/${routeTrainingId}/resources`,resourcesIdList);
  }

  getResourcesOfTraining(trainingId){
    return this.http.get<Resource[]>(`${TRAINING_API}/${trainingId}/resources`);
  }
  getAllResourcesOfCompanyByTraining(trainingId){
    return this.http.get<Resource[]>(`${TRAINING_API}/${trainingId}/allResources`);
  }
  hasResources(trainingId: number): Observable<boolean>{
    return this
      .getResourcesOfTraining(trainingId)
      .pipe(
        map(response => response.length > 0)
      )
  }

  getAttachments(trainingId: number){
    return this.http.get<any[]>(`${TRAINING_API}/${trainingId}/attachments`)
  }

  getResourcesGrades(trainingId: number) {
    return this.http.get<ResourcesGradesResponse>(`${TRAINING_API}/${trainingId}/grades`)
  }

  saveResourcesGrades(trainingId: number | string, trainingGrades){
    return this.http.post<any>(`${TRAINING_API}/${trainingId}/grades`,trainingGrades);
  }

  finishTraining(trainingId: number | string){
    return this.http.delete<any>(`${TRAINING_API}/${trainingId}/finish-training`);
  }
}
