import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.css']
})
export class NavigationHeaderComponent implements OnInit {

  @Input() trainingId: number | string;
  @Input() route: string;
  @Input() isUpdating = false;
  disabled = true;
  disabledCanceled = null;
  @Output() click = new EventEmitter<void>();
  @Input() abaSelected:string="0";
  data: any;
  constructor(private router: Router) { }
  urls = []
  @Input() selectedTab = new FormControl(this.abaSelected);
  ngOnInit() {
    this.selectedTab.patchValue(this.abaSelected)
    this.selectedTab.valueChanges.subscribe(abaSelected => {
      this.next(abaSelected);
    })
  }

  next(selectedAba: number){
    if(this.isUpdating && selectedAba  != 0){
      this.router.navigateByUrl(this.urls[selectedAba]);
    }
    if(selectedAba == 0){
      if(this.data){
        this.router.navigate(['/main','registration','training-info'], {state: {data: this.data}});
      } else if(this.isUpdating){
        this.router.navigateByUrl(this.urls[0]);
      }
    }
  }

  changetoUpdate(idTraining: any, canceledDisabled?: boolean) {
    this.isUpdating = true;
    this.trainingId = idTraining;
    if (canceledDisabled != null && canceledDisabled == true) {
      this.disabledCanceled = true;
    }
    this.disabled = false;
    this.urls = [
      '/main/registration/training-info/'+this.trainingId,
      '/main/registration/training-info/'+this.trainingId+'/add-resources',
      '/main/registration/training-info/'+this.trainingId+'/mark-presence',
      '/main/registration/training-info/'+this.trainingId+'/grades',
      '/main/registration/training-info/'+this.trainingId+'/send-list',
    ]
  }

  insertData(data:any){
    this.data = data;
  }
}
