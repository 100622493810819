import { Component, OnInit, ViewChild, Input, AfterViewInit, EventEmitter, SimpleChanges, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, PageEvent, MatPaginatorIntl } from '@angular/material';
import { TableAction } from './table-action';
import { TalentApiService } from 'src/app/shared/api.service';
import { DataStyle } from 'src/app/models/data-style';
import { AuthService } from 'src/app/shared/auth.service';
import { User } from 'src/app/models/user';

@Component({
	selector: 'default-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.css']
})
export class TableComponent implements OnChanges {

	@Input() columns: string[];
	translatedColumns: string[];
	@Input() rowActions: TableAction[] = [];
	@Input() data: any;
	@Input() dataStyling: DataStyle[] = [];
	@Input() rowCheck: any;
	@Input() pageSize: number = 5;
	@Input() pageNumber = 0;
	@Input() totalElements: number = 20;
	@Input() showFilters: boolean = true;
	@Input() useLabel: boolean = true;
	@Input() defaultCellText: string = '';
	@Input() onOverflow: string = '';
	@Input() showPaginator = true;
	@Input() databasePagination = false;
	@Input() totalLength = 0;
	@Input() disablePagination = false;
  @Input() filterFooter: boolean;
  @Input() showFilter: boolean = true;
	@Output() query = new EventEmitter();
	@Output() page = new EventEmitter();
  @Input() completedDate: boolean = false;
  @Input() withDotLabel: boolean = true;
  @Input() includeDatePipe: boolean = true;

	dataSource: MatTableDataSource<any>;
	dataColumns: string[];
	user: User;

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(authService: AuthService,private cdr: ChangeDetectorRef) { this.user = authService.getUser(); }
	ngOnChanges(_changes: SimpleChanges) {
		this.refresh();
	}

	refresh() {
		this.dataColumns = this.columns.filter(c => (c !== 'actions' && c !== 'check'));
		this.dataSource = new MatTableDataSource();
    this.cdr.detectChanges();
		this.dataSource.data = this.data;
		if(!this.databasePagination && this.paginator != undefined){
			this.dataSource.paginator = this.paginator;
		}
		this.dataSource.filterPredicate = (data, filter) => {
			return this.columns.some(col => {
				let column: string = col;
				if(!this.useLabel) column = col.split('.')[1];
				return (column != 'actions' && data[column]) && data[column].toString().toLowerCase().indexOf(filter) != -1;
			});
		};
		this.dataSource.sort = this.sort;
	}

	applyFilter(filter: string) {
		if(this.databasePagination){
			this.query.emit(filter);
		}else{
			this.dataSource.filter = filter.trim().toLowerCase();
		}
	}

	getRowStyle(row) {
		if (row.style) {
			return row.style;
		}
	}

	check(row) {
		row[this.rowCheck.name] = !row[this.rowCheck.name];
	}

	getCheckColor(row) {
		if (row[this.rowCheck.name]) {
			return { 'color': 'rgb(26, 196, 0)' };
		}

		return { 'color': '#F44336' };
	}

	getCheckIcon(row) {
		if (row[this.rowCheck.name]) {
			return 'check_circle';
		}

		return 'cancel';
	}

	getClass(element: Object, col: string): string {
		let cellClass: string = '';
		this.dataStyling.forEach(dataStyle => {
			if(element[col.replace('style.', '')] == dataStyle.level) cellClass = dataStyle.class;
		});

		return !cellClass && col.includes('style.') ? 'centered-cell' : cellClass ;
	}
  getDate() : string {
    if(this.completedDate){
      return 'dd/MM/yyyy - HH:mm'
    }
    return 'dd/MM/yyyy';
  }

	header(col: string): string {
		let headerText: string;
		headerText = this.useLabel ? ('label.' + col).replace('course_matrix.', '') : col.replace('course_matrix.', 'label.');
		return headerText.replace('style.', this.withDotLabel ? 'label.' : '');
		// return (this.useLabel) ? ('label.' + col).replace('skill.', '').replace('course_matrix.', '') : (col).replace('skill.', '').replace('course_matrix.', 'label.');
	}

	text(element: Object, col: string): string {
		let text: string = '';
		let qualifiers: string[] = ['label.', 'style.', 'course_matrix.', 'site.'];
		col.replace('obligingness', 'mandatory');
    col.replace('obligingnessPosition', 'mandatory');
		qualifiers.forEach(qualifier => {
			if(element[col.replace(qualifier, '')]) text = element[col.replace(qualifier, '')];
		});
		return text ? text : (this.defaultCellText ? this.defaultCellText : '-');
		// return (!text && this.defaultCellText) ? this.defaultCellText : text;
		// return (element[col.replace('label.', '')]) ? (element[col.replace('label.', '')]) : (element[col.replace('skill.', '')] ? element[col.replace('skill.', '')] : (element[col.replace('course_matrix.', '')] ? element[col.replace('course_matrix.', '')] : (element[col.replace('site.', '')] ? element[col.replace('site.', '')] : (element[col.replace('obligingness', 'mandatory')] ? element[col.replace('obligingness', 'mandatory')] : (this.defaultCellText ? this.defaultCellText : '-')))));
	}

  getText(element: Object, col: string): string {
    let text: string = '-';
    switch (col) {
      case 'obligingnessPosition':
        element['mandatory'] == true ? text = 'label.yes':  text ='label.no';
        break;
      case 'proceedingName':
        text = element['name'];
        break;
      case 'fileDocument':
        text = element['document']
      default:
        break;
    }
    return text;
  }
  getColType(col){
    return col != 'mandatory' && col != 'obligingness' && col != 'obligingnessPosition'
    && col != 'proceedingName' && col != 'fileDocument';
  }

	actionPermission(actionRoles: string[]): string {
		if(!actionRoles) return '';
		let display = 'none';
		actionRoles.forEach(role => {
			if(this.user.roles.includes(role)) display = '';
		});
		return display;
	}

	pageEvent(event: PageEvent){
		this.page.emit(event);
	}

  isSticky(col: string): boolean {
    if (this.onOverflow) {
      switch (col) {
        case 'label.resourceName':
          return true;
        case 'label.positionName':
          return true;
        case 'label.modality':
          return true;
        default:
          break;
      }
    }
    return false;
  }
}
