import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProficiencyService } from 'src/app/shared/proficiency.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import {ResourceRegisterHelpService} from '../../../../shared/resource-register-help.service';

interface Skill {
  skillId: number;
  skillName: string;
  gradeControl: FormControl;
  expireDateControl: FormControl;
}

@Component({
  selector: 'app-proficiencies',
  templateUrl: './proficiencies.component.html',
  styleUrls: ['./proficiencies.component.css']
})

export class ProficienciesComponent implements OnInit, OnDestroy {

  skillsData: any[]

  proficiencies = [
		{id: 1,description: '1 - Não sabe'},
		{id: 2,description: '2 - Acompanhar'},
		{id: 3,description: '3 - Executa'},
		{id: 4,description: '4 - Executa e multiplica'},
		{id: 5,description: '5 - Apto para sinergia'},
  ]

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceApiService,
    private proficiencyService: ProficiencyService,
    private resourceRegisterHelpService: ResourceRegisterHelpService,
    private fb: FormBuilder
    ) { }

  ngOnDestroy() {
    this.resourceRegisterHelpService.disable();
  }

  ngOnInit() {
    this.resourceRegisterHelpService.enable('KNOWLEDGE');
    this.route.parent.params.subscribe( params => {
      this.proficiencyService.getProficienciesById(params['id']).subscribe( response => {
        this.skillsData = [
          {
            title: "label.positionSkills",
            skills: this.fillSkills(response.positionSkills),
            stepHelp: 'step1'
          },
          {
            title: "label.generalSkills",
            skills: this.fillSkills(response.generalSkills),
            stepHelp: 'step2'
          }
        ];
      });
    });

  }

  fillSkills(skillsData: any[]): Skill[] {
    if(skillsData){
      return skillsData.map((skill) => ({
        skillId: skill.skillId,
        skillName: skill.skillName,
        gradeControl: this.fb.control(skill.proficiencyLevel, Validators.required),
        expireDateControl: this.fb.control(
          { value: skill.expireDate, disabled: true },
          Validators.required
        ),
      }));
    }else{
      return []
    }
  }

  compareById = (a,b) => a && b && a === b;

}
