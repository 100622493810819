import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { forkJoin } from 'rxjs';
import { COMPANY_API, DEPARTMENT_API, SKILL_API } from 'src/app/api-urls';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Department } from 'src/app/models/department';
import { PositionSite } from 'src/app/models/position-site';
import { Skill } from 'src/app/models/skill';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-general-info-course',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
export class GeneralInfoCourseComponent implements OnInit {
  formGroup: FormGroup;

  departments: Department[] = [];
  companyUnits: CompanyUnit[] = [];
  filteredCompanyUnits: CompanyUnit[] = [];
  companyUnit: FormControl = new FormControl('', Validators.required);
  type: FormControl = new FormControl('', Validators.required);
  department: FormControl = new FormControl('', Validators.required);
  frequencies = [
    'Semestral',
    'Anual',
    'Bienal',
    'Trienal',
    'Único'
  ];

  types = [
    'Geral',
    'Específico'
  ];
  isEdit = false;
  generalInfoData: any;

  countCallCompanyUnit = 0;
  countCallDepartment = 0;
  countCallType =0;
  hasChange: boolean = false;
  @Output() sucessfullFilled: EventEmitter<any> = new EventEmitter();
  constructor(private fb: FormBuilder,
    private companyUnitService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    public dialogService: DialogService) {
      this.formGroup = this.fb.group({
        id: null,
        reference: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        department: new FormControl('', Validators.required),
        companyUnit: new FormControl(''),
        frequency: new FormControl('', Validators.required),
        duration: new FormControl(0, Validators.required),
        active: true,
        description: new FormControl('', Validators.maxLength(3000)),
      });
    }
  ngOnInit() {
    forkJoin(
      this.departmentApiService.listAllObjects(DEPARTMENT_API),
      this.companyUnitService.listAllObjects(COMPANY_API),
    ).subscribe(([departments,companyUnits]) => {
      this.departments = departments;
      this.companyUnits = companyUnits;
      this.filteredCompanyUnits = this.companyUnits;
    });
    this.companyUnit.valueChanges.subscribe(companyUnit => {
      if(companyUnit && companyUnit.id == undefined){
        this.filteredCompanyUnits = this.filterCompanyUnit(companyUnit);
      } else if (companyUnit && companyUnit.id != undefined) {
          this.formGroup.controls['companyUnit'].setValue(companyUnit);
          this.countCallCompanyUnit++;
      }
    });

    this.type.valueChanges.subscribe(type => {
      if(this.isEdit && this.countCallType > 0 && !this.hasChange &&
        type === 'Específico'){
        this.confirmChangeDialog('type',type);
        this.countCallCompanyUnit++;
      } else {
        this.formGroup.controls['type'].setValue(type);
      }
      this.countCallType++;
    });

    this.department.valueChanges.subscribe(department => {
        this.formGroup.controls['department'].setValue(department);
        this.countCallDepartment++;
    });

    this.formGroup.controls["type"].valueChanges.subscribe(value => {
      if(value === 'Geral'){
        this.companyUnit.setValue('');
        this.companyUnit.disable();
        this.formGroup.controls['companyUnit'].clearValidators();
        this.formGroup.controls['companyUnit'].setValue(null);
      }else if(value === 'Específico'){
        this.sucessfullFilled.emit(null);
        this.companyUnit.enable();
        this.companyUnit.setValue('');
        this.formGroup.controls['companyUnit'].setValidators(Validators.required);
        this.formGroup.controls['companyUnit'].setValue('');
      }
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.validForm();
    })
  }

  filterCompanyUnit(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.companyUnits.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
  }
  confirmChangeDialog(field:string,object:any){
    this.dialogService.openConfirmDialog(`message.${field}Changed`).afterClosed().subscribe(response => {
      response ?
      (this.formGroup.controls[field].setValue(object), this.hasChange = true):
      this.fillFormGoup();
    })
  }
  sort(a: PositionSite | Skill, b: PositionSite | Skill): -1 | 0 | 1 {
    const valueA: string = a['position'] ? a['position'].name : a['name'];
    const valueB: string = b['position'] ? b['position'].name : b['name'];
    if (valueA.trim().toLowerCase() < valueB.trim().toLowerCase())
      return -1;
    if (valueA.trim().toLowerCase() > valueB.trim().toLowerCase())
      return 1;
    return 0;
  }

  fillFormGoup(){
    this.countCallCompanyUnit = 0;
    this.countCallDepartment = 0;
    this.countCallType = 0;
    this.hasChange = false;
    this.formGroup.patchValue(this.generalInfoData);
    this.formGroup.disable({emitEvent: false});
    this.type.patchValue(this.generalInfoData.type);
    this.type.disable({emitEvent: false});
    this.department.patchValue(this.generalInfoData.department);
    this.department.disable({emitEvent: false});
    this.companyUnit.patchValue(this.generalInfoData.companyUnit);
    this.companyUnit.disable({emitEvent: false});
  }
  displayCompanyUnit(value: any){
    return value? value.name : '';
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  @ViewChild(MatAutocompleteTrigger) trigger;

  clickAuto() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  validForm(){
    if(this.formGroup.valid){
      this.sucessfullFilled.emit(this.formGroup.value);
    } else {
      this.sucessfullFilled.emit(null)
    }
  }

  fillForm(row) {
    this.isEdit = true;
    this.generalInfoData = row.course;
    this.fillFormGoup();
  }
}
