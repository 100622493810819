import { FULFILLED_COURSE_MATRIX_REPORT, PRINT_FULFILLED_COURSE_MATRIX } from './../../api-urls';
import { Component, OnInit } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputComponent } from 'src/app/components/dialog/input/input.component';

@Component({
  selector: 'app-fulfilled-course-matrix',
  templateUrl: './fulfilled-course-matrix.component.html',
  styleUrls: ['./fulfilled-course-matrix.component.css']
})
export class FulfilledCourseMatrixComponent implements OnInit {

  searchUrl = FULFILLED_COURSE_MATRIX_REPORT;

  resources: any = [];
  columns: any[] = [];
  filter: SearchField[];
  filterFields = [
    'companyUnit',
    'department',
    'position',
    'resource'
  ];

  constructor(private apiService: TalentApiService<void>,
    private dialogService: DialogService) { }

  ngOnInit() {
  }

  results(response) {
    let result = response.result;
    this.filter = response.filter;

    this.columns = [];
    this.resources = [];
    if(Array.isArray(result) && result.length) {
      this.columns.push('label.positionName');
      this.columns.push('label.id');
      this.columns.push('label.resourceName');

      this.resources = result;

      for(let resource of result) {
        if(resource.courseInformation) {
          for(let course of Object.keys(resource.courseInformation)) {
            resource[course] = (resource['courseInformation'][course]) ? 'V' : 'X';
            if(!this.columns.includes(course)) {
              this.columns.push(course);
            }
          }
        }
      }
    }
  }

  generateExcel() {
    this.dialogService.openCustomDialog(InputComponent, true, { inputName: 'label.fileName' }).afterClosed().subscribe(fileName => {
      const reportUrl = this.apiService.buildUrl(PRINT_FULFILLED_COURSE_MATRIX + "/" + fileName, this.filter,fileName,'xls');
    });
  }

}
