import {AuthService} from '../../shared/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/user';
import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/store/app.states';
import {LogIn, LogInFailure, LogInSuccess} from 'src/app/store/actions/user.actions';
import {DialogService} from 'src/app/shared/dialog.service';

// import { UserControllerService } from '@betait/api-users';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  loginForm: FormGroup;
  processingRequest = false;
  error = '';

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogService: DialogService) {

    // if (authService.isTokenExpired()) {
    //   this.router.navigate(
    //     [this.route.snapshot.queryParamMap.get('returnUrl') || '/main']
    //   );
    // }
    this.loginForm = this.fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

  }

  ngOnInit(): void {

    this.hidePassword = true;
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      const errorCode = params['errorCode'];
      if (errorCode) {
        const parsedErrorCode = Number.parseInt(errorCode);
        const isFirstAccess = params['first-access'] === 'true';
        let errorMessage;
        switch (parsedErrorCode) {
          case 404:
            errorMessage = 'error.userNotFound';
            break;
          case 412:
            errorMessage = isFirstAccess ? 'error.expiredLinkFirstAccess' : 'error.expiredLinkResetPassword';
            break;
        }

        this.dialogService.openAlertDialog(errorMessage);
      }
    });
  }

  login() {
    this.processingRequest = true;
    const user = new User(
      this.loginForm.get('email').value,
      this.loginForm.get('password').value,
      null,
      null);

    this.store.dispatch(new LogIn(user));
    this.authService.login(user.username, user.password).subscribe({
      next: (result) => {
        if (!result.errorMessage) {
          this.authService.setIsAuthenticated(true);
          this.authService.setUser(result.user);
          this.store.dispatch(new LogInSuccess(result));
          if (result.user.roles.includes('ROLE_SITE_MANAGER')) {
            this.router.navigate(
              [this.route.snapshot.queryParamMap.get('returnUrl') || '/main/reports']
            );
          } else {
            this.router.navigate(
              [this.route.snapshot.queryParamMap.get('returnUrl') || '/main/calendar']
            );
          }
        } else {
          this.store.dispatch(new LogInFailure(result.errorMessage));
          this.error = 'Login inválido!';
        }
      }, error: (err) => {
        if (err.error.message === 'userNotFound') {
          this.dialogService.showCustomPositionAlertDialog(
            '500px',
            '250px',
            'error.userNotFound',
            undefined,
            'error.userNotFoundSubtitle');
        } else if (err.error.message === 'wrongPassword') {
          this.dialogService.showCustomPositionAlertDialog(
            '500px',
            '250px',
            'error.wrongPassword',
            undefined,
            'error.wrongPasswordSubtitle');
        } else {
          this.dialogService.showCustomPositionAlertDialog(
            '500px',
            '250px',
            'error.serverInaccessible',
            undefined,
            'error.contactManagers');
        }
        this.processingRequest = false;
      }
    });
  }

  enterSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
}
