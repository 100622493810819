import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMPANY_API } from 'src/app/api-urls';
import { CompanyUnit } from 'src/app/models/company-unit';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.css']
})
export class SiteFormComponent implements OnInit {

  formGroup: FormGroup;
  active: FormControl = new FormControl(true);
  name= new FormControl('');
  description= new FormControl('');
  reference= new FormControl('');
  costCenter= new FormControl('');

  isEdit: boolean = false;
  @Output() sucessfullySaved: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder,
    private dialogService: DialogService,
    private companyUnitApiService: TalentApiService<CompanyUnit>,) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      description: new FormControl(''),
      reference: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      costCenter: new FormControl('',Validators.required),
      active: this.active
    });
    this.costCenter.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['costCenter'].patchValue(value);
      }
    });
    this.reference.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['reference'].patchValue(value);
      }
    });
    this.name.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['name'].patchValue(value);
      }
    });
    this.description.valueChanges.subscribe(value => {
      if (value!=null) {
        this.formGroup.controls['description'].patchValue(value);
      }
    });
  }

  save() {
    this.companyUnitApiService.saveObject(COMPANY_API, this.formGroup.value).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedCompanyUnit',this.formGroup.get('reference').value);
      this.clear();
      this.sucessfullySaved.emit(true);
    });
  }

  clear(){
    this.isEdit = false;
    this.formGroup.reset();
    this.formGroup.controls['active'].patchValue(true);
    this.costCenter.patchValue('',{emitEvent:false});
    this.name.patchValue('',{emitEvent:false});
    this.description.patchValue('',{emitEvent:false});
    this.reference.patchValue('',{emitEvent:false});
  }

  fillForm(row:any){
    this.isEdit = true;
    this.formGroup.patchValue(row);
    this.costCenter.patchValue(row.costCenter);
    this.name.patchValue(row.name);
    this.reference.patchValue(row.reference);
    this.description.patchValue(row.description);
  }
}
