import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { DialogService } from './../../shared/dialog.service';
import { AuthService } from '../../shared/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { RadialChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { TableAction } from '../../components/table/table-action';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [TranslatePipe]
})
export class ProfileComponent implements OnInit {

  availableColors = [
    'rgb(142,180,227, 0.5)',
    'rgb(0,176,240, 0.5)',
    'rgb(23,55,94, 0.5)',
    'rgb(141, 181, 77, 0.5)'
  ];

  selectedResources = [];

  currentColor: number = 0;

  data = [
    {
      name: 'Luke Skywalker',
      email: 'teste@betait.com.br',
      position: 'Chief Executive Officer',
      department: 'All',
      unit: 'Indaiatuba',
      skills: [
        {
          name: 'Handling Machine',
          weight: 5
        },
        {
          name: 'Technical Support',
          weight: 2
        },
        {
          name: 'Leadership',
          weight: 2
        },
        {
          name: 'Communication',
          weight: 2
        },
        {
          name: 'Speech',
          weight: 5
        }
      ]
    },
    {
      name: 'Anakin Skywalker',
      email: 'teste2@betait.com.br',
      position: 'Chief Technology Officer',
      department: 'All',
      unit: 'Indaiatuba',
      skills: [
        {
          name: 'Handling Machine',
          weight: 2
        },
        {
          name: 'Technical Support',
          weight: 2
        },
        {
          name: 'Leadership',
          weight: 5
        },
        {
          name: 'Communication',
          weight: 5
        },
        {
          name: 'Speech',
          weight: 1
        }
      ]
    },
    {
      name: 'Leia Skywalker',
      email: 'teste3@betait.com.br',
      position: 'Rebellion President',
      department: 'All',
      unit: 'Indaiatuba',
      skills: [
        {
          name: 'Handling Machine',
          weight: 2
        },
        {
          name: 'Technical Support',
          weight: 2
        },
        {
          name: 'Leadership',
          weight: 5
        },
        {
          name: 'Communication',
          weight: 5
        },
        {
          name: 'Speech',
          weight: 5
        }
      ]
    },
    {
      name: 'Han Solo',
      email: 'teste4@betait.com.br',
      position: 'Pilot',
      department: 'Warehouse',
      unit: 'Indaiatuba',
      skills: [
        {
          name: 'Handling Machine',
          weight: 5
        },
        {
          name: 'Technical Support',
          weight: 5
        },
        {
          name: 'Leadership',
          weight: 2
        },
        {
          name: 'Communication',
          weight: 2
        },
        {
          name: 'Speech',
          weight: 5
        }
      ]
    },
    {
      name: 'Kylo Ren',
      email: 'teste4@betait.com.br',
      position: 'Intern',
      department: 'Finnance',
      unit: 'Indaiatuba',
      skills: [
        {
          name: 'Handling Machine',
          weight: 1
        },
        {
          name: 'Technical Support',
          weight: 1
        },
        {
          name: 'Leadership',
          weight: 1
        },
        {
          name: 'Communication',
          weight: 1
        },
        {
          name: 'Speech',
          weight: 1
        }
      ]
    }
  ];

  columns: string[] = ['name', 'email', 'position', 'actions'];
  actionText: string = '';
  actions: TableAction[] = [
    {
      name: 'label.compare',
      icon: 'person_add',
      color: '',
      text: 'label.addToView',
      do: row => this.compareResource(row)
    }
  ];

  constructor(private dialogService: DialogService, private translatePipe: TranslatePipe) {
  }

  ngOnInit() {
  }

  compareResource(resource) {
    let found = this.radarChartData.filter(r => r.label === resource.name)[0];

    if(found) {
      this.radarChartData = this.radarChartData.filter(f => f !== found);

      resource['style'] = null;

      this.availableColors.push(<string>found.backgroundColor);
      this.selectedResources.splice(this.selectedResources.indexOf(resource), 1);

      return;
    }

    if(this.selectedResources.length === 4) {
      this.dialogService.openAlertDialog(`You can't compare more than 4 resources!`);

      return;
    }

    this.selectedResources.push(resource);
    const backColor = this.availableColors[0];
    this.availableColors = this.availableColors.filter(c => c !== backColor);

    resource['style'] = {
      'background-color': backColor
    };

    let dataSet: ChartDataSets = {
      backgroundColor: backColor,
      borderColor: backColor,
      pointBackgroundColor: 'gray',
      pointHoverBackgroundColor: backColor,
      pointHoverBorderWidth: 0,
      borderWidth: 0,
      data: resource.skills.map(s => s.weight),
      label: resource.name
    };

    this.radarChartData.push(dataSet);
  }

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        fontSize: 30
      }
    },
    scale: {
      pointLabels: {
        fontSize: 16
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1
      }
    }
  };

  public radarChartLabels: Label[] = [
    'Handling',
    'Machines',
    'Communication',
    'Leadership',
    'Techincal Support'
  ];

  public radarChartData: ChartDataSets[] = [
    {
      data: []
    }
  ];
  public radarChartType: ChartType = 'radar';

  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

}
