import { Component, OnInit } from '@angular/core';
import { BaseRegistrationComponent } from 'src/app/components/base-registration/base-registration.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { TalentApiService } from 'src/app/shared/api.service';
import { ProficiencyDialogComponent } from 'src/app/dialogs/proficiency-dialog/proficiency-dialog.component';
import { PROFICIENCY_API } from 'src/app/api-urls';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Proficiency } from 'src/app/models/proficiency';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-conhecimento',
  templateUrl: './conhecimento.component.html',
  styleUrls: ['./conhecimento.component.css']
})
export class ConhecimentoComponent extends BaseRegistrationComponent<Proficiency> {

  columns: string[] = ['level','proficiencyName', 'actions'];
  paths: string[] = ['title.mainRegistration','/','title.proficiency'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]
  constructor(private route: ActivatedRoute, dialogService: DialogService,
    apiService: TalentApiService<Proficiency>, authService: AuthService, router: Router)
  {
    super(dialogService, apiService);
    this.editComponent = null;
    this.objectApi = PROFICIENCY_API;
    this.objectType = 'Proficiency';
    this.user = authService.getUser();
    this.router = router;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      const expectedRole: string = data.role;
      if(expectedRole && !this.user.roles.includes(expectedRole)) {
        this.router.navigate(['/main']);
      }
    });
    super.ngOnInit();
  }
}
