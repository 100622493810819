import { Component } from '@angular/core';
import { DEPARTMENT_API } from '../../api-urls';
import { BaseRegistrationComponent } from '../../components/base-registration/base-registration.component';
import { DepartmentDialogComponent } from '../../dialogs/department-dialog/department-dialog.component';
import { TalentApiService } from '../../shared/api.service';
import { DialogService } from '../../shared/dialog.service';
import { Department } from '../../models/department';


@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent extends BaseRegistrationComponent<Department> {

  columns: string[] = ['departmentName', 'description', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Department>) {
    super(dialogService, apiService);
    this.editComponent = DepartmentDialogComponent;
    this.objectApi = DEPARTMENT_API;
    this.objectType = 'Department';
  }

}
