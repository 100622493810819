import { User } from 'src/app/models/user';
import { All, AuthActionTypes } from '../actions/user.actions';

export interface State {
    isAuthenticated: boolean;
    user: User | null;
    errorMessage: string | null;
}

export const InitialState: State = {
    isAuthenticated: false,
    user: null,
    errorMessage: null
};

export function reducer(state = InitialState, action: All): State {
  switch (action.type) {

    case AuthActionTypes.LOGIN_SUCCESS: {
      let returnObject = {
        ...state,
        isAuthenticated: true,
        user: {
          username: action.payload.user.username,
          password: null,
          token: action.payload.user.token,
          roles: action.payload.user.roles
        },
        errorMessage: null
      };
      return returnObject;
    }

    case AuthActionTypes.LOGIN_FAILURE: {
        return {
            ...state,
            errorMessage: 'invalidCredentials'
        }
    }

    case AuthActionTypes.LOGOUT: {
        return InitialState;
    }

    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user || null;
export const isAuthenticated = (state: State) => state.isAuthenticated;