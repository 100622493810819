import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { Skill } from 'src/app/models/skill';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import {ResourceRegisterHelpService} from '../../../../shared/resource-register-help.service';

@Component({
  selector: 'app-competence',
  templateUrl: './competence.component.html',
  styleUrls: ['./competence.component.css']
})
export class CompetenceComponent implements OnInit, OnDestroy {
  competencies: FormControl = new FormControl();
  selectedCompetencies;
  length:number=0;

  skillCompanyUnits;
  filteredCompanyUnits;

  skillProficiencies;
  performanceGeneral;
  performancePartial;

  quantitySearch: boolean = false;
  showSearch: boolean = false;
  resourceId:number;
  resource;

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent | undefined
  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceApiService,
    private fb: FormBuilder,
    private resourceRegisterHelpService: ResourceRegisterHelpService,
    private router: Router) { }

  ngOnDestroy() {
    this.resourceRegisterHelpService.disable();
  }

  ngOnInit() {
    this.resourceRegisterHelpService.enable('COMPETENCES');
    this.route.parent.params.subscribe( params => {
      this.resourceId = params['id'];
      this.resourceService.findResourceCompetenceById(params['id']).subscribe(({
        resource,skillCompanyUnits,skillProficiencies,selectedCompanyUnits,performanceGeneral,performancePartial})=>{
        this.resource = resource;
        this.skillCompanyUnits = skillCompanyUnits;
        this.filteredCompanyUnits = this.skillCompanyUnits;
        this.skillProficiencies = skillProficiencies;
        this.performanceGeneral = performanceGeneral;
        this.competencies.patchValue(selectedCompanyUnits);
        this.selectedCompetencies = selectedCompanyUnits;

        if(selectedCompanyUnits){
          this.performancePartial = performancePartial;
          this.showSearch = true;
        }
      });
    });
    this.competencies.valueChanges.subscribe(value => {
      this.selectedCompetencies = [];
      if(value && value.length>0){
        this.length = value.length;
        if(value.length>=3){
          this.quantitySearch = true;
        }
      }
    });
  }

  filterSkill(value: string): SkillCompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.skillCompanyUnits.filter(skillCompanyUnit => skillCompanyUnit.skill.name.toLowerCase().includes(filterValue));
  }

  displaySkill(value: any){
    return value? value.acronym : '';
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if(searchInput){
      this.filteredCompanyUnits = this.filterSkill(searchInput);
    }else {
      this.filteredCompanyUnits = this.skillCompanyUnits;
    }
  }

  onOpenChange(searchInput: any) {
    searchInput.value = '';
    this.filteredCompanyUnits = this.skillCompanyUnits;
  }

  onSelectEvent($event:any, skill:SkillCompanyUnit){
    let selectedSkills = [];
    selectedSkills=this.competencies.value;
    if(selectedSkills && selectedSkills != undefined){
      const skillFound = selectedSkills.find(selectedSkill => selectedSkill.id == skill.id);
      if(skillFound==undefined && selectedSkills.length <=10){
        selectedSkills.push(skill);
        this.competencies.patchValue(selectedSkills);
      }
    }
  }

  search(){
    this.selectedCompetencies = [];
    this.selectedCompetencies = this.competencies.value;
    const formGroup = this.fb.group({
      resource: this.resource,
      skillCompanyUnits: this.fb.array(this.selectedCompetencies)
    });
    this.resourceService.createSearchCompetence(formGroup.value).subscribe(({
      performancePartial})=>{
        this.performancePartial = performancePartial;
        this.showSearch = true;
      });
  }

  clear(){
    this.selectedCompetencies = [];
    this.competencies.patchValue(null);
    this.quantitySearch = false;
    this.length=0;
    this.resourceService.clearSearchCompetence(this.resourceId).subscribe( response => {
      this.showSearch = false;
    });
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.id===i2.id;
  }
}
