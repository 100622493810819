import { Component, OnInit } from '@angular/core';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

import { CourseListService } from '../reports-services/course-list.service';
import { PENDING_COURSE_REPORT } from './../../api-urls';

@Component({
  selector: "app-pending-course-list",
  templateUrl: "./pending-course-list.component.html",
  styleUrls: ["./pending-course-list.component.css"],
})
export class PendingCourseListComponent implements OnInit {
  public charts: any;
  public pieChart: Chart;
  public doughnutChart: Chart;

  paths: string[] = ["menu.reports", "/", "reportMenu.pendingCourses"];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: "label.exportExcel",
      style: "generic-button",
      do: () => {
        this.generateExcel();
      },
    },
    {
      label: "label.exportPdf",
      style: "generic-button",
      do: () => {
        this.generatePdf();
      },
    },
    {
      label: "label.back",
      router: "..",
      style: "generic-button",
    },
  ];
  searchUrl = PENDING_COURSE_REPORT;

  resources: any = [];
  columns: any[] = [];
  filter: SearchField[];
  filterFields = ["companyUnitRequired", "departmentRequired", "position", "resource"];
  showTable = false;
  constructor(
    private apiService: TalentApiService<void>,
    private dialogService: DialogService,
    private courseListService: CourseListService
  ) {}

  ngOnInit() {
    //ngoninit
  }

  results(response) {
    if(response.result.length>0){
      let result = response.result;
      this.filter = response.filter;

      this.columns = [];
      this.resources = [];
      if (Array.isArray(result) && result.length) {
        this.showTable = true;
        this.columns.push('label.refTraining');
          this.columns.push('label.training');
          this.columns.push('label.registry');
          this.columns.push('label.resource');
          this.columns.push('label.codPosition');
          this.columns.push('label.position');
        this.resources = result;
        let pieChartTitle= "Ranking de treinamentos pendentes";
        let doughnutChartTitle = "Total de treinamentos pendentes";
        if (localStorage.getItem("lang") == "pt-br") {
          pieChartTitle = "Ranking de treinamentos pendentes";
          doughnutChartTitle = "Total de treinamentos pendentes";
        } else if (localStorage.getItem("lang") == "en") {
          pieChartTitle = "Pending trainings ranking";
          doughnutChartTitle = "Total pending trainings";
        } else if (localStorage.getItem("lang") == "es-es") {
          pieChartTitle = "Ranking de entrenamientos pendientes";
          doughnutChartTitle = "Total de entrenamientos pendientes";
        }
        this.charts = this.courseListService.plotCharts(
          this.resources,
          "pieChart",
          pieChartTitle,
          "doughnutChart",
          doughnutChartTitle
        );
        this.pieChart != null ? this.pieChart.destroy() : null;
        this.doughnutChart != null ? this.doughnutChart.destroy() : null;
        this.pieChart = this.charts.pieChart;
        this.doughnutChart = this.charts.doughnutChart;
      }
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generateExcel",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            PENDING_COURSE_REPORT + "/xlsx/" + fileName,
            this.filter,
            fileName,
            "xlsx"
          );
      });
  }

  generatePdf() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generatePdf",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            PENDING_COURSE_REPORT + "/pdf/" + fileName,
            this.filter,
            fileName,
            "pdf"
          );
      });
  }

  clear() {
    this.showTable = false;
    if(this.pieChart != undefined)
      this.pieChart.destroy();
    if(this.doughnutChart != undefined)
      this.doughnutChart.destroy();
    this.resources = [];
    this.columns = [];
  }
}
