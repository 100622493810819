import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SKILL_API, COMPANY_API } from 'src/app/api-urls';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { Skill } from 'src/app/models/skill';
import { CompanyUnit } from 'src/app/models/company-unit';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';

@Component({
  selector: 'app-skill-dialog',
  templateUrl: './skill-dialog.component.html',
  styleUrls: ['./skill-dialog.component.css']
})
export class SkillDialogComponent implements OnInit {

  title: string = 'title.addProceeding';
  extraTitle: string = '';

  formGroup: FormGroup;

  name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  companyUnit: FormControl = new FormControl('', [Validators.required]);
  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('');
  acronym: FormControl = new FormControl('', [Validators.required, Validators.maxLength(20)]);
  document: FormControl = new FormControl('', [Validators.maxLength(255)]);
  type: FormControl = new FormControl('', Validators.required);

  existing: Skill;
  companyUnits: CompanyUnit[];

  constructor(private fb: FormBuilder,
    private skillApiService: TalentApiService<Skill>,
    private companyUnitApiService: TalentApiService<CompanyUnit>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SkillDialogComponent>,
    private dialogService: DialogService) {
      this.existing = data;
    }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: this.name,
      active: this.active,
      description: this.description,
      acronym: this.acronym,
      type: this.type,
      document: this.document,
      skillCompanyUnits: this.companyUnit
    });

    this.companyUnitApiService.listAllObjects(COMPANY_API).subscribe(response => this.companyUnits = response);

    if(this.existing) {
      this.formGroup.patchValue(this.existing);
      this.title = 'title.editSkill'
      this.extraTitle = 'ID #' + this.existing.id;
    }

  }

  save() {
    if(this.existing) {
      this.skillApiService.updateObject(SKILL_API, this.existing.id,  this.formGroup.value).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedSkill',this.formGroup.get('acronym').value);
        this.close();
      });
    } else {
      this.skillApiService.saveObject(SKILL_API, this.formGroup.value).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedSkill',this.formGroup.get('acronym').value);
        this.close();
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  compareSkillCompanyUnits(option: SkillCompanyUnit, selected: SkillCompanyUnit): boolean {
    return option.companyUnit.id == (selected.companyUnit && selected.companyUnit.id);
  }
}
