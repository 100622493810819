import { DataStyle } from './models/data-style';

export const statusTypes: DataStyle[] = [
	{ name: 'Programado', color: '#13b5f3' },
	{ name: 'Realizado', color: '#2ed365' },
	{ name: 'Cancelado', color: '#f44336' }
];
// export const statusTypes: DataStyle[] = [
// 	{ name: 'label.scheduled', color: '#13b5f3' },
// 	{ name: 'label.completed', color: '#2ed365' },
// 	{ name: 'label.canceled', color: '#f44336' }
// ];

// TODO: Make it become a dinamic level of skill
export const skillLevelTypes: DataStyle[] = [
	{ name: 'label.skill-level-1', color: 'rgb(244, 67, 54)', level: 1, class: 'red-cell' },
	{ name: 'label.skill-level-2', color: 'gold', level: 2, class: 'yellow-cell' },
	{ name: 'label.skill-level-3', color: 'rgb(46, 211, 101)', level: 3, class: 'green-cell' },
	{ name: 'label.skill-level-4', color: 'rgb(19, 181, 243)', level: 4, class: 'light-blue-cell' },
	{ name: 'label.skill-level-5', color: 'rgb(0, 68, 132)', level: 5, class: 'blue-cell' }
];

export const traningPlan: DataStyle[] = [
	{ name: 'Padrão-Programado', color: 'rgb(19, 181, 243)', level: 'P-PROG', class: 'programado' },
	{ name: 'Padrão-Realizado', color: 'rgb(46, 211, 101)', level: 'P-REAL', class: 'realizado' },
	{ name: 'Padrão-Cancelado', color: 'rgb(244, 67, 54)', level: 'P-CANC', class: 'cancelado' },
  { name: 'Reciclagem-Programado', color: 'rgb(19, 181, 243)', level: 'R-PROG', class: 'programado' },
	{ name: 'Reciclagem-Realizado', color: 'rgb(46, 211, 101)', level: 'R-REAL', class: 'realizado' },
	{ name: 'Reciclagem-Cancelado', color: 'rgb(244, 67, 54)', level: 'R-CANC', class: 'cancelado' }
]

export const traningPlanPattern: DataStyle[] = [
	{ name: 'Padrão-Programado', color: 'rgb(19, 181, 243)', level: 'P-PROG', class: 'programado' },
	{ name: 'Padrão-Realizado', color: 'rgb(0, 68, 132)', level: 'P-REAL', class: 'realizado' },
	{ name: 'Padrão-Cancelado', color: 'rgb(244, 67, 54)', level: 'P-CANC', class: 'cancelado' }
];

export const traningPlanRecycling: DataStyle[] = [
  { name: 'Reciclagem-Programado', color: 'rgb(19, 181, 243)', level: 'R-PROG', class: 'programado' },
	{ name: 'Reciclagem-Realizado', color: 'rgb(0, 68, 132)', level: 'R-REAL', class: 'realizado' },
	{ name: 'Reciclagem-Cancelado', color: 'rgb(244, 67, 54)', level: 'R-CANC', class: 'cancelado' },
];

export const trainingMatrixTypes: DataStyle[] = [
	{ name: 'Obrigatório', color: 'rgb(0, 68, 132)', level: 'O', class: 'mandatory' },
	{ name: 'Desejado', color: 'rgb(19, 181, 243)', level: 'D', class: 'desired' },
	{ name: 'Realizado', color: 'rgb(46, 211, 101)', level: 'R', class: 'accomplished' },
	{ name: 'Não realizado', color: 'rgb(244, 67, 54)',	 level: 'NR', class: 'not-accomplished' }
];

export const trainingPresence: DataStyle[] = [
	{ name: 'Presente', color: 'rgb(60, 179, 113)', level: 'P', class: 'present' },
	{ name: 'Ausente', color: 'rgb(244, 67, 54)', level: 'A', class: 'absent' }
];
