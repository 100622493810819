import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-send-daily-control',
  templateUrl: './send-daily-control.component.html',
  styleUrls: ['./send-daily-control.component.css']
})
export class SendDailyControlComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SendDailyControlComponent>) { }

  ngOnInit() {
  }

  accept(){
    this.dialogRef.close(true);
  }

  deny(){
    this.dialogRef.close(false);
  }
}
