import { Injectable } from '@angular/core';
import {driver} from 'driver.js';

type ResourcePage = 'GENERAL_INFO' | 'POSITION_HISTORY' | 'COMPETENCES' | 'KNOWLEDGE' | 'TABLE';

@Injectable({
  providedIn: 'root'
})
export class ResourceRegisterHelpService {
  public isEnabled = false;

  page!: ResourcePage;

  enable(page: ResourcePage){
    this.page = page;
    this.isEnabled = true;
  }

  disable(){
    this.isEnabled = false;
  }

  constructor() { }

  openCurrentHelp() {
    if(this.isEnabled) {
      switch (this.page){
        case 'GENERAL_INFO':
          this.generalInfoHelp();
          break;
        case 'POSITION_HISTORY':
          this.positionHistoryHelp();
          break;
        case 'COMPETENCES':
          this.competencesHelp();
          break;
        case 'KNOWLEDGE':
          this.knowledgeHelp();
          break;
        case 'TABLE':
          this.tableHelp();
          break;
      }
    }
  }

  private generalInfoHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.step1', popover: { title: 'Informações Gerais', description: 'Inicialmente, você deverá selecionar a modalidade e informar a matrícula do recurso. Caso o recurso seja "temporário", a matrícula deverá seguir o padrão desta categoria.'}},
        { element: '.step2', popover: { title: 'Informações Gerais', description: 'Em seguida, você deverá informar o nome completo do recurso.'}},
        { element: '.step3', popover: { title: 'Informações Gerais', description: 'O número do CPF é obrigário, assim como a data de admissão.'}},
        { element: '.step4', popover: { title: 'Informações Gerais', description: 'O e-mail informado deverá, obrigatoriamente, ser da Kuehne Nagel.'}},
        { element: '.step5', popover: { title: 'Informações Gerais', description: 'Você deverá informar o departamento do recurso, o site onde está alocado, e o seu respectivo cargo.\n' +
              'Caso você não encontre o cargo do recurso, vá até o Painel de Vínculos e proceda com a configuração necessária.'}},
      ]
    });

    driverObj.drive();
  }

  private positionHistoryHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.table-step', popover: { title: 'Histórico de Cargos', description: 'Quando o recurso muda de cargo, o seu histórico é armazenado. Você poderá editar as datas do histórico.'}},
      ]
    });

    driverObj.drive();
  }

  private competencesHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.step1', popover: { title: 'Competência', description: 'Você poderá visualizar a performance do recurso a partir da seleção de um conjunto de competências inerentes ao seu cargo atual.'}},
        { element: '.step2', popover: { title: 'Competência', description: 'A perfomance geral do recurso compreende todas as competências inerentes ao seu cargo atual, e exibe a relação entre o perfil desejado versus o perfil real.'}},
        { element: '.step3', popover: { title: 'Competência', description: 'A perfomance parcial do recurso compreende somente as competências pré-selecionadas inerentes ao seu cargo atual, e exibe a relação entre o perfil desejado versus o perfil real.'}},
        { element: '.step4', popover: { title: 'Competência', description: 'O gráfico mostra a relação entre o perfil desejado versus o perfil real do recurso.'}},
      ]
    });

    driverObj.drive();
  }

  private knowledgeHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.step1', popover: { title: 'Conhecimento', description: 'Nesta seção, são exibidos os conhecimentos obtidos nas competências relacionadas ao cargo atual do recurso.'}},
        { element: '.step2', popover: { title: 'Conhecimento', description: 'Nesta seção, são exibidos os conhecimentos gerais obtidos pelo recurso.'}},
      ]
    });

    driverObj.drive();
  }

  private tableHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.filter-step', popover: { title: 'Cadastro de Recursos', description: 'Através do filtro, você poderá buscar qualquer informação acerca dos recursos cadastrados.'}},
        { element: '.step1', popover: { title: 'Cadastro de Recursos', description: 'Através do ícone "Editar", você poderá visualizar e alterar informações de um recurso específico.'}},
        { element: '.step2', popover: { title: 'Cadastro de Recursos', description: 'Através do ícone "QR-Code" você poderá baixar o código de um recurso específico.'}},
        { element: '.step3', popover: { title: 'Cadastro de Recursos', description: 'Ao clicar no botão "Adicionar" você poderá inserir um novo recurso.\n'}},
      ]
    });

    driverObj.drive();
  }
}
