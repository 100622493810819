import { Component, OnInit } from '@angular/core';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { traningPlan, traningPlanPattern, traningPlanRecycling } from 'src/app/util-json.data';

import { ACCESS_HISTORY_REPORT, BASE_URL } from './../../api-urls';

@Component({
  selector: "app-access-history",
  templateUrl: "./access-history.component.html",
  styleUrls: ["./access-history.component.css"],
})
export class AccessHistoryComponent implements OnInit {
  paths: string[] = ["menu.reports", "/", "reportMenu.accessHistory"];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: "label.exportExcel",
      style: "generic-button",
      do: () => {
        this.generateExcel();
      },
    },
    {
      label: "label.exportPdf",
      style: "generic-button",
      do: () => {
        this.generatePdf();
      },
    },
    {
      label: "label.back",
      router: "..",
      style: "generic-button",
    },
  ];
  searchUrl = BASE_URL + "logs/accessHistory";

  history: any = [];
  columns: any[] = [];
  filter: SearchField[];
  types = traningPlan;
  typesPattern = traningPlanPattern;
  typesRecycling = traningPlanRecycling;
  filterFields = ["companyUnit", "startFromDate", "startToDate"];
  showTable = false;
  constructor(
    private apiService: TalentApiService<void>,
    private dialogService: DialogService
  ) {}

  results(response) {
    if(response.result.length>0){
      let result = response.result;
      this.filter = response.filter;
      this.history = result;
      this.showTable = true;
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generateExcel",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            ACCESS_HISTORY_REPORT + "/xlsx/" + fileName,
            this.filter,
            fileName,
            "xlsx"
          );
      });
  }

  generatePdf() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generatePdf",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            ACCESS_HISTORY_REPORT + "/pdf/" + fileName,
            this.filter,
            fileName,
            "pdf"
          );
      });
  }

  ngOnInit() {
    this.columns = [
      "label.username",
      "label.profile",
      "label.companyUnit",
      "label.totalAccess",
      "label.totalTrainings",
    ];
  }

  clear() {
    this.showTable = false;
    this.history = [];
  }
}
