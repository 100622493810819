import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { RootComponent } from './components/root/root.component';
import { CompletedCourseListComponent } from './reports/completed-course-list/completed-course-list.component';
import { CourseMasterListComponent } from './reports/course-master-list/course-master-list.component';
import { ExpiredCourseListComponent } from './reports/expired-course-list/expired-course-list.component';
import { GeneralRadarComponent } from './reports/general-radar/general-radar.component';
import { PendingCourseListComponent } from './reports/pending-course-list/pending-course-list.component';
import { SkillAnalysisComponent } from './reports/skill-analysis/skill-analysis.component';
import { SkillMatrixComponent } from './reports/skill-matrix/skill-matrix.component';
import { SkillMissingMatrixComponent } from './reports/skill-missing-matrix/skill-missing-matrix.component';
import { TrainingMatrixComponent } from './reports/training-matrix/training-matrix.component';
import { TrainingPlanComponent } from './reports/training-plan/training-plan.component';
import { AuthGuard } from './shared/auth-guard.service';
import { CanOpenTrainingGuard } from './shared/can-open-training.guard';
import { DefinePasswordGuard } from './shared/define-password.guard';
import { InstructorGuard } from './shared/instructor.guard';
import { NeedResourcesAddedGuard } from './shared/need-resources-added.guard';
import { PresenceGuard } from './shared/presence.guard';
import { AttendanceListComponent } from './views/attendance-list/attendance-list.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { HelpComponent } from './views/help/help.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { PositionSkillSiteComponent } from './views/position-skill-site/position-skill-site.component';
import { PresenceConfirmComponent } from './views/presence-confirm/presence-confirm.component';
import { QrCodeAttendanceComponent } from './views/qr-code-attendance/qr-code-attendance.component';
import { CargoComponent } from './views/registration/cargo/cargo.component';
import { ConhecimentoComponent } from './views/registration/conhecimento/conhecimento.component';
import { CursoFormComponent } from './views/registration/curso/curso-form/curso-form.component';
import { CursoComponent } from './views/registration/curso/curso.component';
import { DepartamentoComponent } from './views/registration/departamento/departamento.component';
import { ProcedimentoComponent } from './views/registration/procedimento/procedimento.component';
import { QrCodeComponent } from './views/registration/qr-code/qr-code.component';
import { CompetenceComponent } from './views/registration/recurso/competence/competence.component';
import { GeneralInfoComponent } from './views/registration/recurso/general-info/general-info.component';
import { NavigationBarComponent } from './views/registration/recurso/navigation-bar/navigation-bar.component';
import { PositionHistoryComponent } from './views/registration/recurso/position-history/position-history.component';
import { ProficienciesComponent } from './views/registration/recurso/proficiencies/proficiencies.component';
import { RecursoComponent } from './views/registration/recurso/recurso.component';
import { RegistrationComponent } from './views/registration/registration.component';
import { SiteComponent } from './views/registration/site/site.component';
import { UserComponent } from './views/registration/user/user.component';
import { ReportsPanelComponent } from './views/reports-panel/reports-panel.component';
import { TrainingCalendarComponent } from './views/training-calendar/training-calendar.component';
import { AddResourcesTrainingComponent } from './views/training/add-resources-training/add-resources-training.component';
import { MarkPresenceComponent } from './views/training/mark-presence/mark-presence.component';
import { SendListComponent } from './views/training/send-list/send-list.component';
import { TrainingGradesComponent } from './views/training/training-grades/training-grades.component';
import { TrainingInfoComponent } from './views/training/training-info/training-info.component';
import { TrainingDashboardComponent } from './reports/training-dashboard/training-dashboard.component';
import { AccessHistoryComponent } from './reports/access-history/access-history.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'define-password',
    component: ChangePasswordComponent,
    canActivate: [DefinePasswordGuard]
  },
  {
    path: 'qr-code',
    component: AttendanceListComponent,
    canActivate: [PresenceGuard]
  },
  {
    path: 'mark-presence',
    component: QrCodeAttendanceComponent,
    canActivate: [PresenceGuard]
  },
  {
    path: 'presence-confirm',
    component: PresenceConfirmComponent,
    canActivate: [PresenceGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'main',
    component: RootComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'profile',
      //   component: ProfileComponent,
      //   canActivate: [AuthGuard],
      //   data: {
      //     role: 'ROLE_ADMIN ROLE_MANAGER'
      //   }
      // },
      {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/site',
        component: SiteComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/curso',
        component: CursoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/curso/create',
        component: CursoFormComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/curso/create/:id',
        component: CursoFormComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/departamento',
        component: DepartamentoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/recurso',
        component: RecursoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/recurso/create/:id',
        component: NavigationBarComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        },
        children:[
          {
            path: 'general-info',
            component: GeneralInfoComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'position-history',
            component: PositionHistoryComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'competence',
            component: CompetenceComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'proficiencies',
            component: ProficienciesComponent,
            canActivate: [AuthGuard],
          },
        ]
      },
      {
        path: 'registration/procedimento',
        component: ProcedimentoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/conhecimento',
        component: ConhecimentoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN'
        }
      },
      {
        path: 'registration/cargo',
        component: CargoComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info',
        component: TrainingInfoComponent,
        resolve: {instructor: InstructorGuard},
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/add-resources',
        component: AddResourcesTrainingComponent,
        resolve: {instructor: InstructorGuard},
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/:id',
        component: TrainingInfoComponent,
        resolve: {instructor: InstructorGuard},
        canActivate: [AuthGuard, CanOpenTrainingGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/:id/add-resources',
        component: AddResourcesTrainingComponent,
        canActivate: [AuthGuard, CanOpenTrainingGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/:id/send-list',
        component: SendListComponent,
        canActivate: [AuthGuard,NeedResourcesAddedGuard, CanOpenTrainingGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/:id/mark-presence',
        component: MarkPresenceComponent,
        canActivate: [AuthGuard,NeedResourcesAddedGuard, CanOpenTrainingGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/training-info/:id/grades',
        component: TrainingGradesComponent,
        canActivate: [AuthGuard,NeedResourcesAddedGuard, CanOpenTrainingGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'registration/qr-code',
        component: QrCodeComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'calendar',
        component: TrainingCalendarComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER ROLE_INSTRUCTOR'
        }
      },
      {
        path: 'linkingPanel',
        component: PositionSkillSiteComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },

      {
        path: 'reports',
        component: ReportsPanelComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/skillMatrix',
        component: SkillMatrixComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/skillMissingMatrix',
        component: SkillMissingMatrixComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/skillAnalysis',
        component: SkillAnalysisComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/generalRadar',
        component: GeneralRadarComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/courseList',
        component: CourseMasterListComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/trainingDashboard',
        component: TrainingDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/accessHistory',
        component: AccessHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/courseMatrix',
        component: TrainingMatrixComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/trainingPlan',
        component: TrainingPlanComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/expiredCourseList',
        component: ExpiredCourseListComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/pendingCourseList',
        component: PendingCourseListComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'reports/completedCourseList',
        component: CompletedCourseListComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: {
          role: 'ROLE_ADMIN ROLE_MANAGER'
        }
      },

      // Reports
      // { path: 'skillMatrix', component: SkillMatrixComponent },
      // { path: 'skillMissingMatrix', component: SkillMissingMatrixComponent },
      // { path: 'skillAnalysis', component: SkillAnalysisComponent },
      // { path: 'generalRadar', component: GeneralRadarComponent },
      // { path: 'courseList', component: CourseMasterListComponent },
      // { path: 'courseMatrix', component: TrainingMatrixComponent },
      // { path: 'trainingPlan', component: TrainingPlanComponent },
      // { path: 'expiredCourseList', component: ExpiredCourseListComponent },
      // { path: 'pendingCourseList', component: PendingCourseListComponent },

      // Default
      { path: '**', component: NotFoundComponent },
    ]
  },


  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
