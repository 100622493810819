import { COMPANY_API, DEPARTMENT_API, POSITION_API, POSITION_SITE_API } from './../../api-urls';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TalentApiService } from 'src/app/shared/api.service';
import { SkillDialogComponent } from '../skill-dialog/skill-dialog.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { SKILL_API, RESOURCE_API } from 'src/app/api-urls';
import { forkJoin } from 'rxjs';
import { cpfValidator } from 'src/app/shared/input.validators';
import { TableAction } from 'src/app/components/table/table-action';
import { PositionHistoryDialogComponent } from '../position-history-dialog/position-history-dialog.component';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Department } from 'src/app/models/department';
import { Resource } from 'src/app/models/resource';
import { PositionSite } from 'src/app/models/position-site';
import { PositionHistory } from 'src/app/models/position-history';
import { ProficiencyService } from 'src/app/shared/proficiency.service';

@Component({
	selector: 'app-resource-dialog',
	templateUrl: './resource-dialog.component.html',
	styleUrls: ['./resource-dialog.component.css']
})
export class ResourceDialogComponent implements OnInit {

	title: string = 'title.addResource';
	extraTitle: string = '';

	formGroup: FormGroup;

	name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
	document: FormControl = new FormControl('', cpfValidator);
	registry: FormControl = new FormControl('');
	admissionDate: FormControl = new FormControl('', Validators.required);
	resignationDate: FormControl = new FormControl('');
	email: FormControl = new FormControl('');
	phone: FormControl = new FormControl('');
	position: FormControl = new FormControl('', Validators.required);

	modality: FormControl = new FormControl('', [Validators.required]);
	companyUnit: FormControl = new FormControl('', [Validators.required]);
	department: FormControl = new FormControl('');

	companyUnits: CompanyUnit[] = [];
	departments: Department[] = [];

	positionSites: PositionSite[] = [];
	positionsFilter: PositionSite[] = [];

	positionHistory: PositionHistory[] = []
	positionHistoryColumns = ['fromDate', 'toDate', 'positionId', 'positionName', 'departmentName', 'companyUnitName', 'actions'];

	positionHistoryActions: TableAction[] = [
		{
			name: 'label.edit',
			icon: 'create',
			color: '',
			do: (row) => {
				this.editPositionHistory(row)
			}
		}
	];

	existing: Resource;

	skillsControls;

	proficiencies = [
		{id: 1,description: '1 - Não sabe'},
		{id: 2,description: '2 - Acompanhar'},
		{id: 3,description: '3 - Executa'},
		{id: 4,description: '4 - Executa e multiplica'},
		{id: 5,description: '5 - Apto para sinergia'},
	  ]

	constructor(private fb: FormBuilder,
		private companyUnitApiService: TalentApiService<CompanyUnit>,
		private departmentApiService: TalentApiService<Department>,
		private resourceApiService: TalentApiService<Resource>,
		private positionSiteApiService: TalentApiService<PositionSite>,
		private proficiencyService: ProficiencyService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ResourceDialogComponent>,
		private dialogService: DialogService) {
		this.existing = data;
	}

	ngOnInit() {
		this.formGroup = this.fb.group({
			id: null,
			name: this.name,
			registry: this.registry,
			document: this.document,
			resignationDate: this.resignationDate,
			admissionDate: this.admissionDate,
			modality: this.modality,
			phone: this.phone,
			email: this.email,
			position: this.position,
			positionHistory: null,
			skillProficiencies: []
		});



		forkJoin(
			this.positionSiteApiService.listAllObjects(POSITION_SITE_API),
			this.companyUnitApiService.listAllObjects(COMPANY_API),
			this.departmentApiService.listAllObjects(DEPARTMENT_API),
		).subscribe(([positionSites, companyUnits, departments]) => {
			this.positionSites = positionSites;
			this.companyUnits = companyUnits;
			this.departments = departments;

			if(this.existing) this.resourceApiService.getObjectById(RESOURCE_API, this.existing.id).subscribe(data => {
				this.existing = data;
				this.proficiencyService.getProficienciesById(this.existing.id).subscribe( response => {
					this.skillsControls = response.map( skill => {
						return {
						  skillId: skill.skillId,
						  skillName: skill.skillName,
						  gradeControl: this.fb.control(skill.proficiencyLevel,Validators.required),
						  expireDateControl: this.fb.control(skill.expireDate,Validators.required)
						}
					  })
				});
				this.department.setValue(this.existing.position.position.department);
				this.companyUnit.setValue(this.existing.position.companyUnit);

				// if(this.existing.companyUnit) {
				// 	let positionIds: number[] = [];
				// 	this.positionsFilter = this.positionSites.filter(positionSite => {
				// 	  if(positionSite.companyUnit.id == this.existing.companyUnit.id && !positionIds.includes(+positionSite.position.id)) {
				// 		positionIds.push(positionSite.position.id);
				// 		return true;
				// 	  } else return false;
				// 	}).map(positionSite => positionSite.position);
				//   }

				this.positionsFilter = this.positionSites.filter(positionSite => positionSite.companyUnit.id === this.existing.position.companyUnit.id
					&& positionSite.position.department.id === this.existing.position.position.department.id);

				this.formGroup.patchValue(this.existing);
				this.title = 'title.editResource';
				this.extraTitle = 'ID #' + this.existing.id;
				this.position.setValue(this.positionsFilter.find(positionSite => positionSite.id == this.existing.position.id));
				this.positionHistory = this.existing.positionHistory;
			});

			this.companyUnit.valueChanges.subscribe(value => {
				let departmentId: number;
				if (this.department.value) {
					departmentId = this.department.value.id;
				}

				if (value) {
					// let positionIds: number[] = [];
					// this.positionsFilter = this.positionSites.filter(positionSite => {
					// 	if(positionSite.companyUnit.id == value.id && !positionIds.includes(+positionSite.position.id)) {
					// 	  positionIds.push(positionSite.position.id);
					// 	  return true;
					// 	} else return false;
					//   }).map(positionSite => positionSite.position);
					this.positionsFilter = this.positionSites.filter(positionSite => departmentId ?
						positionSite.position.department.id == departmentId && positionSite.companyUnit.id == value.id : positionSite.companyUnit.id == value.id);
				}
			});

			this.department.valueChanges.subscribe(value => {
				let companyUnitId: number;
				if (this.companyUnit.value) {
					companyUnitId = this.companyUnit.value.id;
				}

				if (value) {
					// let positionIds: number[] = [];
					// this.positionsFilter = this.positionSites.filter(positionSite => {
					// 	if(companyUnitId ? (positionSite.position.department.id === value.id && positionSite.companyUnit.id === companyUnitId && !positionIds.includes(positionSite.position.id)) : positionSite.position.department.id === value.id && !positionIds.includes(positionSite.position.id)) {
					// 		positionIds.push(positionSite.position.id);
					// 		return true;
					// 	} else return false;
					// });
					this.positionsFilter = this.positionSites.filter(positionSite => companyUnitId ?
						positionSite.companyUnit.id == companyUnitId && positionSite.position.department.id == value.id : positionSite.position.department.id == value.id);
				}
			});

			this.position.valueChanges.subscribe(value => {
				if (value) {
					this.department.setValue(this.departments.find(department => department.id == value.position.department.id));
				}
			});
		});
	}

	changeSkillProficiency(skillProficiency) {
		let index = this.formGroup.get('skillProficiencies').value.map(s => s.id).indexOf(skillProficiency.id);

		if (index) {
			let sps = this.formGroup.get('skillProficiencies').value;
			sps[index] = skillProficiency;
			this.formGroup.get('skillProficiencies').setValue(sps);
		}

	}

	save() {
		this.resourceApiService.saveObject(RESOURCE_API, this.formGroup.value).subscribe(_response => {
			this.dialogService.openInformationDialog('message.savedResource', this.formGroup.get('name').value);
			this.close();
		});
	}

	close(): void {
		this.dialogRef.close();
	}

	getMask() {
		if (this.phone.value != null && this.phone.value.length > 10) {
			return '(00)00000-0000';
		}
		return '(00)0000-00009';
	}

	editPositionHistory(row) {
		this.dialogService.openCustomDialog(PositionHistoryDialogComponent, true, row)
			.afterClosed().subscribe(data => {
				this.positionHistory[this.positionHistory.map(p => p.id).indexOf(data.id)] = data;
				this.positionHistory = [...this.positionHistory];
				this.formGroup.get('positionHistory').setValue(this.positionHistory);
			});
	}

	compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

	compareByValue(f1: any, f2: any) {
		return f1 && f2 && f1.name === f2.name;
	}

	compareById = (a,b) => a && b && a === b;
}
