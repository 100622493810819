import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RESOURCE_API, TRAINING_API } from '../api-urls';

@Injectable({
  providedIn: 'root'
})
export class ProficiencyService {
  
  constructor(
    private http: HttpClient
  ) { }
  
  getProficienciesById(id: number) {
	  return this.http.get<any>(`${TRAINING_API}/resource/${id}/grades`).pipe(tap(console.log));
  }

}
