import { COMPANY_API } from './../../../api-urls';
import { BaseRegistrationComponent } from './../../../components/base-registration/base-registration.component';
import { UnitDialogComponent } from './../../../dialogs/unit-dialog/unit-dialog.component';
import { DialogService } from './../../../shared/dialog.service';
import { Component, ViewChild } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { CompanyUnit } from 'src/app/models/company-unit';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { SiteFormComponent } from './site-form/site-form.component';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent extends BaseRegistrationComponent<CompanyUnit> {

  columns: string[] = ['reference', 'costCenter', 'site', 'description', 'actions'];
  analystBasedColumns: string[] = ['reference', 'costCenter', 'site', 'description'];
  paths: string[] = ['title.mainRegistration','/','title.site'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]
  constructor(dialogService: DialogService, apiService: TalentApiService<CompanyUnit>, authService: AuthService) {
    super(dialogService, apiService);
    this.editComponent = null;
    this.objectApi = COMPANY_API;
    this.objectType = 'CompanyUnit';
    this.user = authService.getUser();
  }

}
