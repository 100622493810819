import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-missing-matrix',
  templateUrl: './skill-missing-matrix.component.html',
  styleUrls: ['./skill-missing-matrix.component.css']
})
export class SkillMissingMatrixComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
