import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TalentApiService } from 'src/app/shared/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-position-history-dialog',
  templateUrl: './position-history-dialog.component.html',
  styleUrls: ['./position-history-dialog.component.css']
})
export class PositionHistoryDialogComponent implements OnInit {


  title: string = 'title.editPositionHistory';
  extraTitle: string = '';

  formGroup: FormGroup;

  positionName: FormControl = new FormControl('');
  fromDate: FormControl = new FormControl('', Validators.required);
  toDate: FormControl = new FormControl('', Validators.required);

  existing;

  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PositionHistoryDialogComponent>) {
      this.existing = data;
    }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      position: null,
      positionName: this.positionName,
      companyUnitName: null,
      departmentName: null,
      resource: null,
      fromDate: this.fromDate,
      toDate: this.toDate
    });

    if(this.existing) {
      this.formGroup.patchValue(this.existing);
      this.extraTitle = ' - ' + this.existing.positionName;
    }

  }

  save() {
    this.dialogRef.close(this.formGroup.value);
  }

  close(): void {
    this.dialogRef.close(this.existing);
  }

}
