import { BreadcrumbAction } from './breadcrumb-action';
import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit{

  @Input() paths: string[];
  @Input() breadcrumbActions: BreadcrumbAction[] = [];
  constructor() { }

  ngOnInit() {
  }

  changeDisabledButton(value:any){
    this.breadcrumbActions[value.indice].disabled = value.disable;
  }

  changeButton(indice:number, disable: boolean,label?:string, style?:string, toDo?:any){
    this.breadcrumbActions[indice].disabled = disable;
    if(label){
      this.breadcrumbActions[indice].label=label;
    }
    if(style){
      this.breadcrumbActions[indice].style = style;
    }
    if(toDo){
      this.breadcrumbActions[indice].do = toDo;
    }
  }

}
