import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-inner-html",
  templateUrl: "./confirm-inner-html.component.html",
  styleUrls: ["./confirm-inner-html.component.scss"],
})
export class ConfirmInnerHTMLComponent {
  message: string;
  paramValue: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      param: string;
    },
    public dialogRef: MatDialogRef<ConfirmInnerHTMLComponent>
  ) {
    this.message = data.message;
    this.paramValue = data.param;
  }

  accept() {
    this.dialogRef.close(true);
  }

  deny() {
    this.dialogRef.close(false);
  }
}
