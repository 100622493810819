import { COMPANY_API } from './../../api-urls';
import { DialogService } from 'src/app/shared/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { CompanyUnit } from 'src/app/models/company-unit';

@Component({
  selector: 'app-unit-dialog',
  templateUrl: './unit-dialog.component.html',
  styleUrls: ['./unit-dialog.component.css']
})
export class UnitDialogComponent implements OnInit {

  title: string = 'title.addCompanyUnit';
  extraTitle: string = '';

  formGroup: FormGroup;

  name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  description: FormControl = new FormControl('');
  reference: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  active: FormControl = new FormControl(true);
  costCenter = new FormControl('',Validators.required);

  existing;

  constructor(private fb: FormBuilder,
    private companyUnitApiService: TalentApiService<CompanyUnit>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UnitDialogComponent>,
    private dialogService: DialogService) {
      this.existing = data;
    }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: this.name,
      description: this.description,
      reference: this.reference,
      costCenter: this.costCenter,
      active: this.active
    });

    if(this.existing) {
      this.formGroup.patchValue(this.existing);
      this.title = 'title.editCompanyUnit'
      this.extraTitle = 'ID #' + this.existing.id;
    }

  }

  save() {
    this.companyUnitApiService.saveObject(COMPANY_API, this.formGroup.value).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedCompanyUnit',this.formGroup.get('reference').value);
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
