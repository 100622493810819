import { Position } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DEPARTMENT_API, POSITION_API } from 'src/app/api-urls';
import { Department } from 'src/app/models/department';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-cargo-form',
  templateUrl: './cargo-form.component.html',
  styleUrls: ['./cargo-form.component.css']
})
export class CargoFormComponent implements OnInit {

  formGroup: FormGroup;
  name:FormControl= new FormControl('');
  description:FormControl= new FormControl('');
  department: FormControl= new FormControl('');
  departments: Department[];
  @Output() sucessfullySaved: EventEmitter<any> = new EventEmitter();
  isEdit: boolean = false;
  constructor(private fb: FormBuilder,
    private positionApiService: TalentApiService<Position>,
    private departmentApiService: TalentApiService<Department>,
    private dialogService: DialogService
    ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      active: true,
      description: new FormControl(''),
      department: new FormControl(null, [Validators.required])
    });
    this.departmentApiService.listAllObjects(DEPARTMENT_API).subscribe(response => {
      this.departments = response;
    });

    this.name.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['name'].patchValue(value);
      }
    });

    this.department.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['department'].patchValue(value);
      }
    });
  }

  save() {
    let position = this.formGroup.value;
    this.positionApiService.saveObject(POSITION_API, position).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedPosition',position.name);
        this.clear();
        this.sucessfullySaved.emit(true);
    });
  }

  clear(){
    this.isEdit = false;
    this.formGroup.reset();
    this.formGroup.controls['active'].patchValue(true);
    this.name.patchValue('',{emitEvent:false});
    this.description.patchValue('',{emitEvent:false});
    this.department.patchValue(null,{emitEvent:false});
  }

  fillForm(row:any){
    this.isEdit = true;
    this.formGroup.patchValue(row);
    this.name.patchValue(row.name);
    this.description.patchValue(row.description);
    this.department.patchValue(row.department);
  }


  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
}
