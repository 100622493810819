import { SkillCompanyUnit } from './../../models/skill-company-unit';
import { merge, forkJoin } from 'rxjs';
import { DEPARTMENT_API, RESOURCE_API, POSITION_SITE_API } from 'src/app/api-urls';
import { COMPANY_API, POSITION_API } from './../../api-urls';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { Department } from 'src/app/models/department';
import { PositionSite } from 'src/app/models/position-site';
import { Resource } from 'src/app/models/resource';
import { CompanyUnit } from 'src/app/models/company-unit';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { filter, switchMap, map  } from 'rxjs/operators';

@Component({
  selector: 'app-skill-analysis',
  templateUrl: './skill-analysis.component.html',
  styleUrls: ['./skill-analysis.component.css']
})
export class SkillAnalysisComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.skillAnalysis']
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]

  companyUnit: FormControl = new FormControl();
  companyUnit2: FormControl = new FormControl();
  selectedCompanyUnit: FormControl = new FormControl();
  selectedCompanyUnit2: FormControl = new FormControl();
  companyUnits: CompanyUnit[];
  filteredCompanyUnits: CompanyUnit[] = [];
  filteredCompanyUnits2: CompanyUnit[] = [];

  department: FormControl = new FormControl();
  department2: FormControl = new FormControl();
  departments: Department[];

  position: FormControl = new FormControl();
  positions: PositionSite[];
  filteredPositions: PositionSite[] = [];

  resource: FormControl = new FormControl();
  resources: Resource[];
  filteredResources : Resource[] =[];

  position2: FormControl = new FormControl();
  positions2: PositionSite[];
  filteredPositions2: PositionSite[] = [];

  selectedResource: any;
  targetPosition: any;

  constructor(private companyUnitApiService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private positionSiteApiService: TalentApiService<PositionSite>,
    private resourceApiService: TalentApiService<Resource>) {}

  ngOnInit() {
    forkJoin(
      this.companyUnitApiService.listAllObjects(COMPANY_API),
      this.departmentApiService.listAllObjects(DEPARTMENT_API)
    ).subscribe(([companyUnits, departments]) => {
      this.companyUnits = companyUnits;
      this.filteredCompanyUnits = this.companyUnits;
      this.filteredCompanyUnits2 = this.companyUnits;
      this.departments = departments;
    });

    merge(this.selectedCompanyUnit.valueChanges, this.department.valueChanges).subscribe(() => {
      if(this.selectedCompanyUnit.value && this.department.value) {
        let fields: SearchField[] = [
          {
            name: 'department',
            value: this.department.value.id
          },
          { name: 'companyUnit',value: this.selectedCompanyUnit.value.id}
        ];
        this.positionSiteApiService.searchObjects(POSITION_SITE_API+'/searchByCompanyUnitAndDepartment',fields).subscribe(response => {
          this.positions = response;
          this.filteredPositions = this.positions;
        });
      }
    });

    merge(this.selectedCompanyUnit2.valueChanges, this.department2.valueChanges).subscribe(() => {
      if(this.selectedCompanyUnit2.value && this.department2.value) {
        let fields: SearchField[] = [
          {
            name: 'department',
            value: this.department2.value.id
          },
          { name: 'companyUnit',value: this.selectedCompanyUnit2.value.id}
        ];
        this.positionSiteApiService.searchObjects(POSITION_SITE_API+'/skillsByCompanyAndDepartment',fields).subscribe(response => {
          this.positions2 = response;
          this.filteredPositions2 = this.positions2;
        });
      }
    });

    this.position.valueChanges.pipe(
      filter(position => position !== undefined && position.id === undefined),
      map(position => this.filterPosition(position))
    ).subscribe(filteredPositions => {
      this.filteredPositions = filteredPositions;
    });

    this.position.valueChanges.pipe(
      filter(position => position !== undefined && position.id !== undefined),
      switchMap(position => {
        const fields: SearchField[] = [
          {
            name: 'positionId',
            value: position.id
          }
        ];
        return this.resourceApiService.searchObjects(RESOURCE_API + "/search", fields);
      })
    ).subscribe(response => {
      this.resources = response;
      this.filteredResources = this.resources;
    });


    this.position2.valueChanges.subscribe(position => {
      if(position && position.id == undefined){
        this.filteredPositions2 = this.filterPosition2(position);
      }
    });

    this.companyUnit.valueChanges.subscribe(companyUnit => {
      if(companyUnit && companyUnit.id==undefined){
        this.filteredCompanyUnits = this.filterCompanyUnit(companyUnit);
      }else if(companyUnit != undefined){
        this.selectedCompanyUnit.setValue(companyUnit);
      }
    });

    this.companyUnit2.valueChanges.subscribe(companyUnit => {
      if(companyUnit && companyUnit.id==undefined){
        this.filteredCompanyUnits2 = this.filterCompanyUnit(companyUnit);
      }else if(companyUnit != undefined){
        this.selectedCompanyUnit2.setValue(companyUnit);
      }
    });

    this.resource.valueChanges.subscribe(resource => {
      if(resource && resource.id == undefined){
        this.filteredResources = this.filterResource(resource);
      }
    });
  }

  compare() {
    if (this.position2.value && this.resource.value) {
      let fields: SearchField[] = [
        {
          name: 'resourceId',
          value: this.resource.value.id
        },
        {
          name: 'targetPositionId',
          value: this.position2.value.id
        }
      ];
      this.resourceApiService.searchObjects(RESOURCE_API + "/performancePosition", fields).subscribe(response =>{
        this.selectedResource = response
      });
      this.targetPosition = {
        companyUnit:this.selectedCompanyUnit2.value,
        department:this.department2.value,
        positionSite:this.position2.value
      };
    }
  }

  clear() {
    this.companyUnit.setValue(undefined);
    this.selectedCompanyUnit.setValue(undefined);
    this.department.setValue(undefined);
    this.position.setValue(undefined);
    this.resource.setValue(undefined);
    this.positions = [];
    this.resources = [];
    this.companyUnit2.setValue(undefined);
    this.department2.setValue(undefined);
    this.position2.setValue(undefined);
    this.positions2 = [];
    this.selectedResource = '';
    this.targetPosition = '';
  }

  generateExcel() {}

  @ViewChild(MatAutocompleteTrigger) trigger;

    clickAuto() {
      this.trigger._onChange('');
      this.trigger.openPanel();
    }

    filterCompanyUnit(value: string): CompanyUnit[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.companyUnits.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
    }

    filterPosition(value: string): PositionSite[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.positions.filter(positionSite => positionSite.position.name.toLowerCase().includes(filterValue));
    }

    filterPosition2(value: string): PositionSite[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.positions2.filter(positionSite => positionSite.position.name.toLowerCase().includes(filterValue));
    }

    filterResource(value: string): Resource[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.resources.filter(resources => resources.name.toLowerCase().includes(filterValue));
    }

  displayCompanyUnit(value: any){
    return value? value.name : '';
  }

  displayPosition(value: any){
    return value? value.position.name : '';
  }

  displayResource(value: any){
    return value? value.name : '';
  }

  resetFields(control1: FormControl, control2?: FormControl, control3?: FormControl) {
    control1.setValue(undefined);
    control2?control2.setValue(undefined):null;
    control3?control3.setValue(undefined):null;
  }

  onDepartmentChange() {
    this.resetFields(this.companyUnit, this.position, this.resource);
  }

  onCompanyUnitChange(event: MatAutocompleteSelectedEvent){
    this.resetFields(this.position, this.resource);
  }

  onPositionChange(event: MatAutocompleteSelectedEvent){
    this.resetFields(this.resource);
  }

  onDepartment2Change(){
    this.resetFields(this.companyUnit2, this.position2);
  }

  onCompanyUnit2Change(event: MatAutocompleteSelectedEvent){
    this.resetFields(this.position2);
  }
}
