import { RESOURCE_API } from './../../api-urls';
import { DialogService } from 'src/app/shared/dialog.service';
import { TalentApiService } from './../../shared/api.service';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PROFICIENCY_API } from 'src/app/api-urls';
import { Resource } from 'src/app/models/resource';
import { Proficiency } from 'src/app/models/proficiency';
import { MatDatepickerInputEvent } from '@angular/material';
import { TrainingPresenceService } from 'src/app/shared/training-presence.service';

@Component({
  selector: 'app-resource-skill',
  templateUrl: './resource-skill.component.html',
  styleUrls: ['./resource-skill.component.css']
})
export class ResourceSkillComponent implements OnInit {

  @Input() resource: Resource;
  @Input() showButton: boolean = false;
  @Input() skillFilters: any[];
  @Input() courseId: number;
  @Input() course;
  @Input() trainingId;
  @Output() skillEvent: EventEmitter<any> = new EventEmitter();

  skills: FormArray;
  formGroup: FormGroup;
  proficiencies: Proficiency[];
  initialSkillProficiencies: any[];

  constructor(private fb: FormBuilder,
    private proficiencyApiService: TalentApiService<Proficiency>,
    private resourceApiService: TalentApiService<Resource>,
    private dialogService: DialogService,
    private trainingPresenceService: TrainingPresenceService
    ) {
  }

  ngOnInit() {
    this.skills = this.fb.array([]);

    this.formGroup = this.fb.group({
      courseSkill: {value: ''},
      skills: {value: this.skills}
    });

    this.proficiencyApiService.listAllObjects(PROFICIENCY_API).subscribe(
      result => this.proficiencies = result
    );

    this.getSkillProficiencies();

  }

  getSkillProficiencies() {
    this.resourceApiService.getObjectById(RESOURCE_API + '/proficiencies', this.resource.id).subscribe(response => {
      if (this.resource && this.resource.skillProficiencies) {
        this.resource.skillProficiencies = response;
        let filteredSkills = this.resource.skillProficiencies;
        this.initialSkillProficiencies = this.resource.skillProficiencies;
        if (this.skillFilters) {
          filteredSkills = filteredSkills
            .filter(s => this.skillFilters.map(s => s.id).includes(s.skill.id));
        }
        this.skills = this.fb.array(filteredSkills);
      }
    });
  }

  changed(formControl: FormControl) {
    let skillProficiency = formControl.value;

    this.skillEvent.emit(skillProficiency);

    let index = this.resource.skillProficiencies.map(s => s.id).indexOf(skillProficiency.id);

    if (index) {
      let expireDate = skillProficiency.expireDate ? new Date(skillProficiency.expireDate) : new Date();
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (expireDate && expireDate.getTime() < today.getTime()) {
        skillProficiency.proficiency = this.proficiencies.find(proficiency => proficiency.level == 1);
        skillProficiency.expireDate = null;
      } else skillProficiency.proficiency = this.proficiencies.find(proficiency => proficiency.id == formControl.value.proficiency.id);
      formControl.value.proficiency = skillProficiency.proficiency;
      this.resource.skillProficiencies[index] = skillProficiency;
    }
  }

  save() {
    if (this.courseId) {
      this.resource.tempCourseId = this.courseId;
    }

    this.resourceApiService.saveObject(RESOURCE_API, this.resource).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedResource');
    });
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  changeCourse(proficiency) {
    for (let control of this.skills.controls) {
      let obj = control.value;
      obj['proficiency'] = proficiency;
      obj['expireDate'] = null;
      control.setValue(obj);
    }
  }

  changeExpireDate(expireDate: MatDatepickerInputEvent<Date>) {
    let date = expireDate.value;
    let today = new Date();
    for (let control of this.skills.controls) {
      let obj = control.value;

      // if (date && date.getTime() < today.getTime()) {
      // 	obj['proficiency'] = this.proficiencies.find(proficiency => proficiency.level == 1);
      // 	obj['expireDate'] = null;
      // } else {
      obj['expireDate'] = date;
      // }
      // control.setValue(obj);
    }
  }
}
