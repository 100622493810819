import { DEPARTMENT_API, COMPANY_API, SKILL_API, PROFICIENCY_API, POSITION_SKILL_API } from './../../api-urls';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';
import { POSITION_API } from 'src/app/api-urls';
import { FormGroup, FormBuilder, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DialogService } from 'src/app/shared/dialog.service';
import { TalentApiService } from 'src/app/shared/api.service';
import { forkJoin, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TableAction } from 'src/app/components/table/table-action';
import { Proficiency } from 'src/app/models/proficiency';
import { Skill } from 'src/app/models/skill';
import { Department } from 'src/app/models/department';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Position } from 'src/app/models/position';

@Component({
  selector: 'app-position-dialog',
  templateUrl: './position-dialog.component.html',
  styleUrls: ['./position-dialog.component.css']
})
export class PositionDialogComponent implements OnInit {

  title: string = 'title.addPosition';
  extraTitle: string;

  formGroup: FormGroup;
  skillTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'highlight_off',
      color: 'warn',
      do: (row) => {
        // this.removeSkill(row)
      }
    },
  ];

  skillColumns: string[] = ['skillName', 'type', 'proficiencyLevel', 'mandatory', 'actions'];

  departments: Department[];
  companyUnits: CompanyUnit[];
  proficiencies: Proficiency[];

  skills: Skill[] = [];
  selectedSkillsProf: any[] = [];
  filteredSkills: Observable<Skill[]>;

  name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('');
  department: FormControl = new FormControl(null, [Validators.required]);
  companyUnit: FormControl = new FormControl(null);
  selectedSkill: FormControl = new FormControl('');
  proficiency: FormControl = new FormControl('');
  mandatory: FormControl = new FormControl(false);

  existing;

  constructor(private fb: FormBuilder,
    private companyUnitApiService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private proficiencyApiService: TalentApiService<Proficiency>,
    private skillApiService: TalentApiService<Skill>,
    private positionApiService: TalentApiService<Position>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PositionDialogComponent>,
    private dialogService: DialogService) {
      this.existing = data;
    }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: this.name,
      active: this.active,
      description: this.description,
      department: this.department,
      companyUnit: this.companyUnit,
      skillProficiencies: [],
    });

    forkJoin(
      this.companyUnitApiService.listAllObjects(COMPANY_API),
      this.departmentApiService.listAllObjects(DEPARTMENT_API),
      this.proficiencyApiService.listAllObjects(PROFICIENCY_API),
      this.skillApiService.listAllObjects(SKILL_API),
    ).subscribe(([companyUnits, departments, proficiencies, skills]) => {
      this.companyUnits = companyUnits;
      this.departments = departments;
      this.proficiencies = proficiencies;
      this.skills = skills;

      if(this.existing) {
        this.formGroup.patchValue(this.existing);
        this.title = 'title.editPosition'
        this.extraTitle = 'ID #' + this.existing.id;
        if(this.existing.skillProficiencies) {
          this.selectedSkillsProf = this.existing.skillProficiencies
        }
      }

      this.refreshFilter();
    });
  }

  refreshFilter() {
    this.skills = this.skills.filter(item => this.selectedSkillsProf.map(s => s.skill.name).indexOf(item.name) < 0);
    this.skills = [...this.skills];

    this.filteredSkills = this.selectedSkill.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  save() {
    let position = this.formGroup.value;
    console.log(position);
    position.skillProficiencies = this.selectedSkillsProf;
    this.positionApiService.saveObject(POSITION_API, position).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedPosition',position.name);
        this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  addSkill() {
    const skill = this.skills.find(s => s.name === this.selectedSkill.value);
    const prof = this.proficiency.value ? this.proficiency.value : undefined;

    if(prof) {
      const skillProf = {
        skill: skill,
        proficiency: prof,
        type: skill.type,
        skillName: skill.name,
        proficiencyName: prof.name,
        proficiencyLevel: prof.level,
        mandatory: this.mandatory.value,
        mandatoryString: this.mandatory.value ? 'X' : ''
      };

      this.selectedSkillsProf.push(skillProf);
      this.selectedSkillsProf = [...this.selectedSkillsProf];
    }
    this.refreshFilter();

    this.selectedSkill.setValue(null);
    this.proficiency.setValue(null);
    this.mandatory.setValue(false);
  }

  // removeSkill(skillProf) {
  //   if(skillProf.id) {
  //     this.apiService.deleteObject(POSITION_SKILL_API, skillProf.id).subscribe(
  //       () => {
  //         this.removeSkillProf(skillProf);
  //       }
  //     );
  //   } else {
  //     this.removeSkillProf(skillProf);
  //   }
  // }

  removeSkillProf(skillProf) {
    this.selectedSkillsProf.splice(this.selectedSkillsProf.indexOf(skillProf), 1);
    this.selectedSkillsProf = [...this.selectedSkillsProf];
    this.refreshFilter();
  }

  // TODO: Criar componente utilitario para workarounds
  @ViewChild(MatAutocompleteTrigger) trigger;

  clickAuto() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  filter(value: string): Skill[] {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.skills.filter(option => option.name.toLowerCase().includes(filterValue));
  }

}
