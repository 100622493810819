import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { COURSE_API } from '../api-urls';
import { Course } from '../models/course';

@Injectable({
  providedIn: 'root'
})
export class CourseApiService {

  constructor(private http: HttpClient) {}

  getCoursesByUnitIdAndDepartment(unitId: number,departmentId: number): Observable<Course[]> {
    return this.http.get<Course[]>(`${COURSE_API}/company-unit/${unitId}/department/${departmentId}`);
  }

  getCoursesByIdWithSkillsFetched(id){
    return this.http.get<Course>(`${COURSE_API}/${id}/skills`);
  }
}
